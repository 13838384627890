export const API_BASE_URL = process.env.REACT_APP_BASE_URL || '';

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY || '';
export const PUSH_NOTIFICATION_SERVER_KEY = process.env.REACT_APP_PUSH_NOTIFICATION_SERVER_KEY || '';
export const FB_VAPID_KEY = process.env.REACT_APP_FB_VAPID_KEY || '';

export const PASSWORD_REGEX =
	/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

export const FIREBASE_CONFIG = {
	apiKey: process.env.REACT_APP_FB_API_KEY as string,
	authDomain: `${process.env.REACT_APP_FB_PROJECT_ID}.firebaseapp.com`,
	projectId: process.env.REACT_APP_FB_PROJECT_ID as string,
	storageBucket: `${process.env.REACT_APP_FB_PROJECT_ID}.appspot.com`,
	messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID as string,
	appId: process.env.REACT_APP_FB_ID as string,
	measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID as string
};
