import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'shared/interface/state';

export const useGetUserLocation = () => {
	const { current_latitude, current_longitude } = useSelector((state: IState) => state.auth.userData.address);

	const userLocation = useMemo(
		() => ({
			lat: current_latitude ? parseFloat(current_latitude) : 23.022505,
			lng: current_longitude ? parseFloat(current_longitude) : 72.5713621
		}),
		[current_latitude, current_longitude]
	);

	return userLocation;
};
