import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SCALETECH_REDIRECTION_LINK } from 'shared/constants/constants';
import { FooterLogo } from '../icons/icons';

const ScaletechFooter: FC<{ className?: string }> = ({ className }) => {
	return (
		<div className={`scaletech-footer-container ${className || ''}`}>
			<p className='footer-title mr--10 mb--10'>Designed & developed by</p>
			<Link to={SCALETECH_REDIRECTION_LINK} target='blank'>
				<FooterLogo />
			</Link>
		</div>
	);
};

export default ScaletechFooter;
