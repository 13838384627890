import { ROLES } from 'features/auth/interface/auth';
import { IRedirectStep } from '../interface/profile';

const HeaderTitles = ['Personal', 'Address', 'Education', 'Profession', 'Family', 'Membership'];

const PersonalInfoDetails = [
	{
		label: 'First Name',
		name: 'first_name',
		placeHolder: 'Your first name',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{ label: 'Last Name', name: 'last_name', placeHolder: 'Your last name', isRequired: true, type: 'text', note: '' },
	{
		label: 'Date of Birth',
		name: 'birthdate',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: ''
	}
];

const ContactDetails = [
	{
		label: 'Contact',
		name: 'contact_number',
		placeHolder: '00000 00000',
		isRequired: true,
		type: 'number',
		note: 'You can add an alternate contact number by clicking on the plus button.'
	},
	{ label: 'Email Address', name: 'email', placeHolder: 'Your email', isRequired: false, type: 'email', note: '' },
	{
		label: 'Blood Group',
		name: 'blood_group',
		placeHolder: '',
		isRequired: false,
		type: '',
		note: 'You can select any one option.'
	}
];

const AdmissionDetails = [
	{
		label: 'Admission Year',
		name: 'admission_year',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type admission year or select it by clicking on the calendar.'
	},
	{
		label: 'Pass-Out Year',
		name: 'passing_year',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type pass-out year or select it by clicking on the calendar.'
	},
	{
		label: 'House',
		name: 'house',
		placeHolder: '',
		isRequired: true,
		type: '',
		note: 'You can select multiple options.'
	}
];

const JoiningDetails = [
	{
		label: 'Year of Joining',
		name: 'year_of_joining',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type the year you joined OBBSSA or select it by clicking on the calendar.'
	},
	{
		label: 'Year of Leaving',
		name: 'year_of_leaving',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type the year you left OBBSSA or select it by clicking on the calendar..'
	},
	{
		label: 'Profession',
		name: 'category',
		placeHolder: '',
		isRequired: true,
		type: '',
		note: 'You can select any one option.'
	}
];
const Profession = [ROLES.TEACHER, ROLES.STAFF];

const BloodGroup = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];
const HouseList = ['Tagore', 'Shivaji', 'Angre', 'Garuda', 'Pratap', 'Shashtri', 'Nehru', 'SP'];
const RelationList = [
	{ label: 'Child', value: 'child' },
	{ label: 'Spouse', value: 'spouse' }
];

const AddressInfoDetails = [
	{
		label: 'House, Street Name, Landmark',
		name: 'address',
		placeHolder: 'Your current house address',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{
		label: 'Zip Code',
		name: 'current_zipcode',
		placeHolder: '000-000',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{
		label: 'Country',
		name: 'current_country',
		placeHolder: 'Your country',
		isRequired: true,
		type: 'text',
		note: 'You can type or select country by clicking on the arrow.'
	},
	{
		label: 'State',
		name: 'current_state',
		placeHolder: 'Your state',
		isRequired: false,
		type: 'text',
		note: 'You can type or select state by clicking on the arrow.'
	},
	{
		label: 'City',
		name: 'current_city',
		placeHolder: 'Your city',
		isRequired: true,
		type: 'text',
		note: ''
	}
];

const professionInfoDetails = [
	{
		label: 'Work Type',
		name: 'work_type',
		placeHolder: 'Your work type',
		isRequired: true,
		type: 'text',
		note: 'You can type work type or select it by clicking on the calendar.'
	},
	{
		label: 'Name of Organization',
		name: 'organization',
		placeHolder: 'Name of your organization',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{
		label: 'Designation',
		name: 'designation',
		placeHolder: 'Your designation',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{
		label: 'Work Location City',
		name: 'location',
		placeHolder: 'Current city of profession',
		isRequired: false,
		type: 'text',
		note: ''
	}
];

const EducationInfoDetails: { [key: string]: any } = {
	institute_name: {
		label: 'Institute Name',
		name: 'institute_name',
		placeHolder: 'Institute you studied from',
		isRequired: true,
		type: 'text',
		note: ''
	},
	field: {
		label: 'Degree',
		name: 'field',
		placeHolder: 'Your degree',
		isRequired: true,
		type: 'text',
		note: ''
	},
	institute_passout_year: {
		label: 'Passing Year',
		name: 'institute_passout_year',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type passing year or select it by clicking on the calendar.'
	}
};

const FamilyInfoDetails: { [key: string]: any } = {
	name: {
		label: 'Name',
		name: 'name',
		placeHolder: 'Name of your family member',
		isRequired: true,
		type: 'text',
		note: ''
	},
	relation: {
		label: 'Relation',
		name: 'relation',
		placeHolder: 'Your relationship with them?',
		isRequired: true,
		type: 'text',
		note: ''
	},
	birthdate: {
		label: 'Date of Birth',
		name: 'birthdate',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: ''
	},
	blood_group: {
		label: 'Blood Group',
		name: 'blood_group',
		placeHolder: 'Your degree',
		isRequired: false,
		type: 'text',
		note: 'You can select any one option.'
	},
	occupation: {
		label: 'Occupation',
		name: 'occupation',
		placeHolder: 'What do they do?',
		isRequired: false,
		type: 'text',
		note: ''
	}
};

const WorkType = [
	{ label: 'Business', value: 'business' },
	{ label: 'Job', value: 'job' },
	{ label: 'Retired', value: 'retired' }
];

const RedirectionSteps: IRedirectStep = {
	Personal: 0,
	Address: 1,
	Education: 2,
	Profession: 3,
	Family: 4,
	Membership: 5
};

const PersonalInfo = {
	profile_key: '',
	profile_photo: '',
	first_name: '',
	last_name: '',
	birthdate: null,
	alternate_contact: [],
	contact_number: '',
	country_code: '',
	email: '',
	blood_group: '',
	admission_year: null,
	passing_year: null,
	house: []
};

export {
	HeaderTitles,
	PersonalInfoDetails,
	ContactDetails,
	AdmissionDetails,
	BloodGroup,
	HouseList,
	AddressInfoDetails,
	professionInfoDetails,
	EducationInfoDetails,
	FamilyInfoDetails,
	RelationList,
	WorkType,
	RedirectionSteps,
	JoiningDetails,
	Profession,
	PersonalInfo
};
