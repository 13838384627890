import { FC, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { BackIcon } from 'shared/components/icons/icons';
import { Loader } from 'shared/components/spinner/spinner';

import NotificationImg from 'assets/images/notification.png';
import { useQuery } from 'shared/hooks/useQuery';
import { UserDetailsContext } from 'store/context/userDetailStore';

const Notification: FC = () => {
	const params = useQuery();
	const showNotification = useMemo(() => Boolean(params.get('notification')), [params]);
	const navigate = useNavigate();
	const { fetchNotification, notificationList, notificationLoading } = useContext(UserDetailsContext);

	useEffect(() => {
		showNotification && fetchNotification(false);
	}, [showNotification]);

	if (!showNotification) return <></>;

	return (
		<div className={`notification-container ${notificationList.length === 0 ? 'no-notification-container' : ''}`}>
			<header className='header-wrapper'>
				<nav className='navbar flex align-items--center justify-content--between height--full width--full'>
					<div className='header-icon-wrapper' onClick={() => navigate(-1)}>
						<BackIcon />
					</div>
					<p className='font--bold font-size--xxl'>Notifications</p>
					<div className='width--5' />
				</nav>
			</header>
			{notificationLoading && <Loader />}
			{!notificationLoading && notificationList.length === 0 && (
				<>
					<img src={NotificationImg} alt='Notification' />
					<p className='no-notification-text'>No notifications currently. Check back later for updates!</p>
				</>
			)}
			{!notificationLoading && notificationList.length > 0 && (
				<div className='notification-box-wrapper'>
					{notificationList.map((notification, id) => (
						<div key={id} className='notification-box'>
							<p className='notification'>{notification.message}</p>
							<p className='notification-time'>{notification.created_at}</p>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Notification;
