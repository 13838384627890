import React from 'react';

interface IProps {
	title?: string;
	checked?: boolean;
	disabled?: boolean;
	isCaption?: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox: React.FC<IProps> = ({ title = '', checked, onChange, disabled, isCaption }) => (
	<label className={`checkbox-wrapper text--primary font--medium ${disabled ? 'disabled' : ''}`}>
		{title}
		<input disabled={disabled} onChange={onChange} checked={checked} type='checkbox' />
		<span className={`check-mark ${isCaption ? 'left-35' : ''}`} />
	</label>
);

export default CheckBox;
