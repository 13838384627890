import { IAction } from 'shared/interface/state';
import AuthService from 'shared/services/auth.service';
import { logEvent } from 'shared/util/utility';

import * as actionTypes from 'store/actionTypes';
import { IAuthState, IUserData } from '../interface/auth';

export const initialState: IAuthState = {
	isLogin: AuthService.isLogin(),
	isSignUpRoute: AuthService.isSignUpRoute(),
	userData: AuthService.getAuthData() || ({} as IUserData)
};

const reducer = (state: IAuthState = initialState, action: IAction) => {
	switch (action.type) {
		case actionTypes.AUTH_SUCCESS:
			const userData: IUserData = action.payload;
			const isProfileIncompleteOrTeacher = !userData.step || userData.step !== 'Membership';

			// set user data and role info in local storage
			AuthService.setAuthData(userData);
			AuthService.setSignUpRoute(isProfileIncompleteOrTeacher);
			return {
				...state,
				isLogin: true,
				isSignUpRoute: isProfileIncompleteOrTeacher,
				userData: userData
			};
		case actionTypes.AUTH_FAILED:
			return {
				...state,
				isLogin: false
			};
		case actionTypes.UPDATE_USER_DATA:
			return {
				...state,
				userData: action.payload
			};
		case actionTypes.UPDATE_USER_ROUTE:
			AuthService.DeletesSignUpRoute();
			return {
				...state,
				isSignUpRoute: false
			};
		case actionTypes.LOGOUT:
			AuthService.removeAuthData();
			AuthService.DeletesSignUpRoute();
			logEvent('LOGOUT', {});
			return {
				...state,
				isLogin: false,
				isSignUpRoute: false,
				userData: {}
			};
		default:
			return state;
	}
};

export default reducer;
