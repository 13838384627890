import { FC, useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import MemorySwiper from 'features/allMemories/component/memorySwiper';
import { DashboardDetailsContext } from 'store/context/userDashboardStore';

import { IFeaturedMemory } from '../interface/dashboard';

import 'swiper/css';
import 'swiper/css/pagination';

const FeaturedMemory: FC = () => {
	const { fetchFeaturedMemory, featuredMemoryList, featuredMemoryLoading } = useContext(DashboardDetailsContext);

	const [isShowPhotoSwiper, setIsShowPhotoSwiper] = useState(false);
	const [initialSlide, setInitialSlide] = useState(0);

	const getDetailMemory = () => {
		const photo: string[] = featuredMemoryList.map(({ photo }) => photo);
		const { created_at, title } = featuredMemoryList[0] as IFeaturedMemory;
		return {
			created_at,
			photo,
			title,
			total_photos: photo.length
		};
	};

	useEffect(() => {
		fetchFeaturedMemory(false);
	}, []);

	return (
		<div className={`${isShowPhotoSwiper ? 'position--relative z-index--7' : ''}`}>
			{!featuredMemoryLoading && featuredMemoryList.length > 0 && (
				<div className='home-memories-wrapper'>
					<p className='title'>Featured Memories</p>
					<div className='swiper-wrapper'>
						<Swiper
							cssMode={true}
							centeredSlides
							spaceBetween={10}
							direction={'horizontal'}
							mousewheel={true}
							freeMode={true}
							loop={true}
							modules={[Pagination]}
							pagination={{
								clickable: true
							}}
							speed={1000}
						>
							{featuredMemoryList.map(({ id, title, photo /* , created_at */ }, index) => (
								<SwiperSlide
									key={id}
									className='position--relative'
									onClick={() => {
										setIsShowPhotoSwiper(true);
										setInitialSlide(index);
									}}
								>
									<img className='featured-memory-img' src={photo} alt='profile' />
									<div className='memory-details'>
										{/* <p className='memory-date'>{formatDate(created_at, 'Do MMM')}</p> */}
										<p className='memory-name text--capitalize'>{title}</p>
										<p className='memory-name text--capitalize'>
											{index + 1}
											<span className='opacity--50'>/{featuredMemoryList.length}</span>
										</p>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			)}
			{isShowPhotoSwiper && (
				<MemorySwiper
					initialSlide={initialSlide}
					detailMemory={getDetailMemory()}
					handleCloseMemorySwiper={() => setIsShowPhotoSwiper(false)}
				/>
			)}
		</div>
	);
};

export default FeaturedMemory;
