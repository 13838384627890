import { FC } from 'react';
import ReactWhatsapp from 'react-whatsapp';

import { EmailIcon, MobileIcon, WPIcon } from 'shared/components/icons/icons';
import { capitalizeWords } from 'shared/util/utility';

import { IAlumniList } from '../interface/nearByMap';

const MarkerPopup: FC<IAlumniList> = ({ profile_photo, designation, organization, contact, email, name }) => {
	return (
		<div className='swiper-card'>
			<div>
				<div className='justify-align--center'>
					<img
						className='profile-img object-fit--cover object-position-top'
						src={profile_photo}
						alt='profile'
					/>
				</div>
				<p className='friend-name'>{capitalizeWords(name)}</p>
				<p className='friend-designation truncate-lines--2'>
					{designation || ''} {organization || ''}
				</p>
			</div>
			<div className='flex align-items--center justify-content--between width--full pl--15 pr--15 pb--5'>
				<a className='contact-wrapper' href={`tel:${contact}`} onClick={(e) => e.stopPropagation()}>
					<MobileIcon className='mobile-icon' />
				</a>
				<ReactWhatsapp
					number={contact}
					element='webview'
					className='contact-wrapper'
					onClick={(e) => e.stopPropagation()}
				>
					<WPIcon className='wp-icon' />
				</ReactWhatsapp>
				<a
					className={`contact-wrapper ${email === '-' ? 'disabled pointer-events--none' : ''}`}
					href={`mailto:${email}`}
					onClick={(e) => e.stopPropagation()}
				>
					<EmailIcon className='email-icon' />
				</a>
			</div>
			<div />
		</div>
	);
};

export default MarkerPopup;
