import { FC, useState } from 'react';
import { APIProvider, ControlPosition, Map, MapControl, Marker } from '@vis.gl/react-google-maps';

import { GOOGLE_MAP_API_KEY } from 'shared/constants';
import { PlaceAutocompleteClassic } from './autocomplete';
import MapHandler from './map-handler';

const defaultValue = { center: { lat: 28.6139, lng: 77.209 }, zoom: 4 };
interface IProps {
	place: string;
	latLag: { lat: number; lng: number };
	setFieldValue: (name: string, value: number | string) => void;
}
const GoogleMapModal: FC<IProps> = ({ place, latLag, setFieldValue }) => {
	const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
	const [latLng, setLatLng] = useState(latLag);

	const onPlaceSelect = (place: google.maps.places.PlaceResult | null) => {
		setSelectedPlace(place);

		const location = place?.geometry?.location;
		const params = {
			lat: location?.lat() || 0,
			lng: location?.lng() || 0
		};

		setLatLng(params);
		setFieldValue('current_latitude', String(params.lat));
		setFieldValue('current_longitude', String(params.lng));
		if (place?.formatted_address) {
			setFieldValue('location', place?.formatted_address);
		}
	};

	return (
		<div className='google-map-wrapper'>
			<APIProvider apiKey={`${GOOGLE_MAP_API_KEY}`} libraries={['places']}>
				<MapControl position={ControlPosition.TOP_LEFT}>
					<div className='autocomplete-control' style={{ width: '100%' }}>
						<PlaceAutocompleteClassic place={place} onPlaceSelect={onPlaceSelect} />
					</div>
				</MapControl>
				<Map
					defaultZoom={defaultValue.zoom}
					defaultCenter={defaultValue.center}
					gestureHandling={'greedy'}
					disableDefaultUI={true}
				>
					<Marker
						position={latLng}
						/* draggable
						onDragEnd={(e: google.maps.MapMouseEvent) => {
							console.log(e);
						}} */
					/>
				</Map>
				<MapHandler place={selectedPlace} />
			</APIProvider>
		</div>
	);
};

export { GoogleMapModal };
