import { FC } from 'react';
import { createPortal } from 'react-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import { BackIcon } from 'shared/components/icons/icons';
import { IBatchMemory } from 'features/dashboard/interface/dashboard';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

interface IMemorySwiper {
	initialSlide: number;
	detailMemory: IBatchMemory;
	handleCloseMemorySwiper: () => void;
}

const MemorySwiper: FC<IMemorySwiper> = ({ initialSlide, detailMemory, handleCloseMemorySwiper }) => {
	const container = document.getElementById('root') as HTMLElement;

	return (
		<div>
			{createPortal(
				<div className='memory-photo-swiper-wrapper'>
					<div className='flex align-items--center mt--20 ml--20'>
						<div className='header-icon-wrapper' onClick={handleCloseMemorySwiper}>
							<BackIcon />
						</div>
						<p className='details-memory--title text--capitalize'>{detailMemory.title}</p>
					</div>
					<div className='swiper-wrapper'>
						<Swiper
							initialSlide={initialSlide || 0}
							slidesPerView={1}
							cssMode={true}
							centeredSlides
							spaceBetween={10}
							className='alumni-update-swiper'
							direction={'horizontal'}
							mousewheel={true}
							freeMode={true}
							loop={false}
							modules={[Pagination, Navigation]}
							pagination={{
								type: 'fraction',
								clickable: true
							}}
							navigation={true}
							speed={1000}
						>
							{detailMemory &&
								detailMemory.photo.map((photoUrl, index: number) => (
									<SwiperSlide key={index} className='position--relative alumni-alumni-wrapper'>
										<img className='alumni-memory-img' src={photoUrl} alt='profile' />
									</SwiperSlide>
								))}
						</Swiper>
					</div>
				</div>,
				container
			)}
		</div>
	);
};

export default MemorySwiper;
