import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import OtpInput from 'react-otp-input';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import isNull from 'lodash/isNull';
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';
import { v4 as uuidv4 } from 'uuid';

import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import * as actionTypes from 'store/actionTypes';
import { BackIcon, NextIcon } from 'shared/components/icons/icons';
import { API_CONFIG } from 'shared/constants/api';
import { firebaseAuth } from 'shared/firebase/firebase';
import HttpService from 'shared/services/http.service';
import {
	convertToObjects,
	getMobileOperatingSystem,
	createAction,
	handleRollNumber,
	logEvent
} from 'shared/util/utility';
import { notify } from 'shared/components/notification/notification';
import CustomButton from 'shared/components/customButton/customButton';
import Spinner from 'shared/components/spinner/spinner';
import { studentLoginForm, teacherLoginForm } from 'shared/constants/validation-schema';
import ScaletechFooter from 'shared/components/footer/scaletechFooter';

import { LOGIN_INIT_VALUES, REACT_SELECT_STYLE } from '../constants/auth';
import { countryCodes } from '../constants/countryCodeConstants';
import { ILoginFormValues, ILoginParams, ILoginWithTokenParams, ROLES } from '../interface/auth';

import WelcomeImg from 'assets/images/welcome.png';

let DATE: number = Date.now() + 120000;
const Login: FC = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [countryCode, setCountryCode] = useState('+91');
	const [verificationCode, setVerificationCode] = useState('');
	const [confirmationResult, setConfirmationResult] = useState<any>(null);
	const [rollNo, setRollNo] = useState('');
	const [isTeacher, setTeacher] = useState(false);

	useEffect(() => {
		logEvent('LOGIN_PAGE', {});
		if (!window.recaptchaVerifier) {
			window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'recaptcha-container', {
				size: 'invisible'
			});
		}
	}, []);

	const Login = async (values: ILoginFormValues) => {
		try {
			const params: ILoginParams = {
				...values,
				role: isTeacher ? ROLES.TEACHER : ROLES.ALUMNI,
				roll_number: Number(values.roll_number)
			};
			if (isTeacher) {
				delete params.roll_number;
			}
			await HttpService.post(API_CONFIG.path.login, params);
			logEvent('LOGIN_BUTTON_CLICK', {
				rollNumber: values.roll_number || '-',
				mobileNo: `${values.country_code} ${values.contact_number}` || '-',
				role: isTeacher ? ROLES.TEACHER : ROLES.ALUMNI
			});
			handleSendCode();
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const handleSendCode = async () => {
		try {
			setVerificationCode('');
			setLoading(true);

			const userContactNumber = `${countryCode}${phoneNumber}`;
			const confirmationResult = await signInWithPhoneNumber(
				firebaseAuth,
				userContactNumber,
				window.recaptchaVerifier
			);
			logEvent('OTP_PAGE', {
				rollNumber: rollNo || '-',
				mobileNo: `${countryCode} ${phoneNumber}` || '-',
				role: isTeacher ? ROLES.TEACHER : ROLES.ALUMNI
			});
			setConfirmationResult(confirmationResult);
			setLoading(false);
			DATE = Date.now() + 120000;
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const handleVerifyCode = async () => {
		try {
			setLoading(true);
			await confirmationResult.confirm(verificationCode);
			LoginTokenAPI();
		} catch (error: any) {
			console.error(error);
			notify('Firebase authentication code is invalid', 'error');
			setLoading(false);
		}
	};

	const LoginTokenAPI = async () => {
		let deviceId = localStorage.getItem('device-id');

		if (!deviceId) {
			deviceId = uuidv4();
			localStorage.setItem('device-id', deviceId);
		}
		try {
			const params: ILoginWithTokenParams = {
				contact_number: phoneNumber,
				country_code: countryCode,
				device_token: (firebaseAuth.currentUser as any).uid,
				device_type: getMobileOperatingSystem(),
				roll_number: Number(rollNo),
				role: isTeacher ? ROLES.TEACHER : ROLES.ALUMNI,
				subscription_obj: window.subscription,
				device_id: deviceId
			};
			if (isTeacher) {
				delete params.roll_number;
			}
			const response = await HttpService.post(API_CONFIG.path.loginToken, params);

			dispatch(createAction(actionTypes.AUTH_SUCCESS, response));

			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const renderer = ({ minutes, seconds, completed }: CountdownRenderProps) => {
		return (
			<>
				{completed ? (
					<p className='resend-otp-text cursor--pointer' onClick={handleSendCode}>
						Resend
					</p>
				) : (
					<>
						<span className='resend-otp-timer'>{`${zeroPad(minutes)}:${zeroPad(seconds)}`}</span>
					</>
				)}
			</>
		);
	};

	return (
		<div className='login-wrapper'>
			<div className={`login-inner-wrapper ${isNull(confirmationResult) ? '' : 'otp-inner-wrapper'}`}>
				<div className='form auth-form'>
					<div className='content-wrapper'>
						{loading && <Spinner />}
						{isNull(confirmationResult) && (
							<>
								<p className='login-title'>Hello!</p>
								<p className='login-title pb--70'>Welcome.</p>
								<Formik
									initialValues={LOGIN_INIT_VALUES}
									onSubmit={Login}
									validationSchema={isTeacher ? teacherLoginForm : studentLoginForm}
									validateOnChange
									validateOnBlur
								>
									{({ handleSubmit, setFieldValue, errors }) => {
										return (
											<Form onSubmit={handleSubmit}>
												{!isTeacher && (
													<div className='field-wrapper'>
														<p className='mb--5'>Roll number</p>
														<div className='position--relative height--71'>
															<img
																src={WelcomeImg}
																className='welcome-img'
																alt='welcome-img'
															/>
															<Field
																name='roll_number'
																type='number'
																placeholder='Enter roll number'
																className={`input-field width--full border-radius--12 position--relative ${
																	errors.roll_number ? 'border--gradient-red' : ''
																}`}
																autoComplete='off'
																onKeyDown={handleRollNumber}
																onChange={(event: ChangeEvent<HTMLInputElement>) => {
																	setFieldValue('roll_number', event.target.value);
																	setRollNo(event.target.value);
																}}
															/>
															<ErrorMessage
																name='roll_number'
																component='p'
																className='error'
															/>
														</div>
													</div>
												)}
												<div className='field-wrapper height--71'>
													<p className='mb--5'>Mobile number</p>
													<div className='flex'>
														<ReactSelect
															name='country_code'
															className='mr--10'
															placeholder='+91'
															defaultValue={{ label: '+91', value: '+91' } as any}
															styles={{ ...REACT_SELECT_STYLE }}
															options={convertToObjects(countryCodes)}
															onChange={(event) => {
																setFieldValue('country_code', event?.value || '+91');
																setCountryCode(event?.value || '+91');
															}}
														/>
														<Field
															name='contact_number'
															type='number'
															placeholder='Enter mobile number'
															className={`input-field width--full border-radius--12 ${
																errors.contact_number ? 'border--gradient-red' : ''
															}`}
															autoComplete='off'
															onChange={(e: ChangeEvent<HTMLInputElement>) => {
																setFieldValue('contact_number', e.target.value.trim());
																setPhoneNumber(e.target.value.trim());
															}}
														/>
													</div>
													<ErrorMessage
														name='contact_number'
														component='p'
														className='error'
													/>
													<p className='text--gray font-size--sm mt--20'>
														We will send you a{' '}
														<span className='text--black font--semi-bold'>
															One Time Password (OTP)
														</span>{' '}
														on this mobile number
													</p>
												</div>
												<div className='text--center'>
													<CustomButton
														btnClassName='primary-button justify-align--center mt--65 login-btn'
														buttonTitle='Get an OTP'
														ButtonIcon={() => <NextIcon />}
														disabled={loading}
														isSubmitLoading={loading}
													/>
													<span className='mt--5 d--block'>or</span>

													<CustomButton
														btnClassName='primary-button justify-align--center mt--10 mb--20 login-btn'
														buttonTitle={
															isTeacher ? 'Login as Student' : 'Login as Teacher or Staff'
														}
														buttonType='button'
														onButtonClick={() => setTeacher(!isTeacher)}
														disabled={loading}
														isSubmitLoading={loading}
													/>
												</div>
											</Form>
										);
									}}
								</Formik>
							</>
						)}
						{!isNull(confirmationResult) && (
							<div>
								<CustomButton
									btnClassName='transparent-button back-button'
									buttonTitle=''
									ButtonIcon={() => <BackIcon />}
									disabled={loading}
									isSubmitLoading={loading}
									onButtonClick={() => setConfirmationResult(null)}
								/>
								<p className='login-title'>OTP Verification</p>
								<p className='mt--10 line-height--default login--sub-title'>
									We have sent you a One Time Password(OTP) on{' '}
									<span>
										{countryCode} {phoneNumber}
									</span>
								</p>
								<div className='justify-align--center flex--column mt--60'>
									<div className='otp-screen-wrapper'>
										<p className='text--center mb--10'>Enter OTP</p>
										<form className='width--full otp-form-wrapper'>
											<OtpInput
												value={verificationCode}
												onChange={(otp: string) => setVerificationCode(otp)}
												containerStyle={'width--full justify-align--center'}
												inputStyle={'otp-input-box justify-align--center width--full'}
												numInputs={6}
												shouldAutoFocus
												inputType='number'
												renderInput={(props) => (
													<input {...props} placeholder='0' autoComplete='off' />
												)}
											/>
											<p className='resend-otp-title'>Did not get an OTP?</p>
											<div className='otp-timer-wrapper'>
												<Countdown
													key={new Date().getSeconds().toLocaleString()}
													date={DATE}
													autoStart={true}
													renderer={renderer}
												/>
											</div>
											<div className='justify-align--center'>
												<CustomButton
													btnClassName='primary-button justify-align--center mt--65 mb--20 login-btn'
													buttonTitle='Verify & Login'
													onButtonClick={handleVerifyCode}
													ButtonIcon={() => <NextIcon />}
													disabled={loading || verificationCode.length !== 6}
													isSubmitLoading={loading}
												/>
											</div>
										</form>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<ScaletechFooter className={`${isNull(confirmationResult) ? 'login-scaletech-footer-wrapper' : ''}`} />
		</div>
	);
};

export default Login;
