import { ROLES } from 'features/auth/interface/auth';

const ADMISSION_DETAILS = [
	{
		label: 'Admission Year',
		name: 'admission_year',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type admission year or select it by clicking on the calendar.'
	},
	{
		label: 'Pass-Out Year',
		name: 'passing_year',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type pass-out year or select it by clicking on the calendar.'
	},
	{
		label: 'House',
		name: 'house',
		placeHolder: '',
		isRequired: true,
		type: '',
		note: 'You can select multiple options.'
	}
];

const ALLOW_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const BLOOD_GROUP = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];
const HOUSE_LIST = ['Tagore', 'Shivaji', 'Angre', 'Garuda', 'Pratap', 'Shashtri', 'Nehru', 'SP'];

const PERSONAL_INFO_DETAILS = [
	{
		label: 'First Name',
		name: 'first_name',
		placeHolder: 'Your first name',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{ label: 'Last Name', name: 'last_name', placeHolder: 'Your last name', isRequired: true, type: 'text', note: '' },
	{
		label: 'Date of Birth',
		name: 'birthdate',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: ''
	}
];

const CONTACT_INFO_DETAILS = [
	{
		label: 'Contact',
		name: 'contact_number',
		placeHolder: '00000 00000',
		isRequired: true,
		type: 'number',
		note: 'You can add an alternate contact number by clicking on the plus button.'
	},
	{ label: 'Email Address', name: 'email', placeHolder: 'Your email', isRequired: false, type: 'email', note: '' },
	{
		label: 'Blood Group',
		name: 'blood_group',
		placeHolder: '',
		isRequired: false,
		type: '',
		note: 'You can select any one option.'
	}
];

const ADMISSION_INFO_DETAILS = [
	{
		label: 'Admission Year',
		name: 'admission_year',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type admission year or select it by clicking on the calendar.'
	},
	{
		label: 'Pass-Out Year',
		name: 'passing_year',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type pass-out year or select it by clicking on the calendar.'
	},
	{
		label: 'House',
		name: 'house',
		placeHolder: '',
		isRequired: true,
		type: '',
		note: 'You can select multiple options.'
	}
];

const JOINING_INFO_DETAILS = [
	{
		label: 'Year of Joining',
		name: 'year_of_joining',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type the year you joined OBBSSA or select it by clicking on the calendar.'
	},
	{
		label: 'Year of Leaving',
		name: 'year_of_leaving',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type the year you left OBBSSA or select it by clicking on the calendar..'
	},
	{
		label: 'Profession',
		name: 'category',
		placeHolder: '',
		isRequired: true,
		type: '',
		note: 'You can select any one option.'
	}
];

const ADDRESS_INFO_DETAILS = [
	{
		label: 'House, Street Name, Landmark',
		name: 'address',
		placeHolder: 'Your current house address',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{
		label: 'Zip Code',
		name: 'current_zipcode',
		placeHolder: '000-000',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{
		label: 'Country',
		name: 'current_country',
		placeHolder: 'Your country',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{
		label: 'State',
		name: 'current_state',
		placeHolder: 'Your state',
		isRequired: false,
		type: 'text',
		note: ''
	},
	{
		label: 'City',
		name: 'current_city',
		placeHolder: 'Your city',
		isRequired: true,
		type: 'text',
		note: ''
	}
];

const DATE_FORMAT = ['admission_year', 'passing_year'];

const NO_INPUT_FIELD = ['admission_year', 'passing_year', 'birthdate', 'blood_group', 'house'];

const TODAY = new Date();

const PROFESSIONAL_INFO_DETAILS = [
	{
		label: 'Work Type',
		name: 'work_type',
		placeHolder: 'Your work type',
		isRequired: true,
		type: 'text',
		note: 'You can type work type or select it by clicking on the calendar.'
	},
	{
		label: 'Name of Organization',
		name: 'organization',
		placeHolder: 'Name of your organization',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{
		label: 'Designation',
		name: 'designation',
		placeHolder: 'Your designation',
		isRequired: true,
		type: 'text',
		note: ''
	},
	{
		label: 'Work Location City',
		name: 'location',
		placeHolder: 'Current city of profession',
		isRequired: false,
		type: 'text',
		note: ''
	}
];

const WORK_TYPE = [
	{ label: 'Business', value: 'business' },
	{ label: 'Job', value: 'job' },
	{ label: 'Retired', value: 'retired' }
];

const EDUCATION_INFO_DETAILS: { [key: string]: any } = {
	institute_name: {
		label: 'Institute Name',
		name: 'institute_name',
		placeHolder: 'Institute you studied from',
		isRequired: true,
		type: 'text',
		note: ''
	},
	field: {
		label: 'Degree',
		name: 'field',
		placeHolder: 'Your degree',
		isRequired: true,
		type: 'text',
		note: ''
	},
	institute_passout_year: {
		label: 'Passing Year',
		name: 'institute_passout_year',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: 'You can type passing year or select it by clicking on the calendar.'
	}
};

const FAMILY_INFO_DETAILS: { [key: string]: any } = {
	name: {
		label: 'Name',
		name: 'name',
		placeHolder: 'Name of your family member',
		isRequired: true,
		type: 'text',
		note: ''
	},
	relation: {
		label: 'Relation',
		name: 'relation',
		placeHolder: 'What is your relationship with them?',
		isRequired: true,
		type: 'text',
		note: ''
	},
	birthdate: {
		label: 'Date of Birth',
		name: 'birthdate',
		placeHolder: 'dd-mm-yyyy',
		isRequired: true,
		type: 'date',
		note: ''
	},
	blood_group: {
		label: 'Blood Group',
		name: 'blood_group',
		placeHolder: 'Your degree',
		isRequired: false,
		type: 'text',
		note: 'You can select any one option.'
	},
	occupation: {
		label: 'Occupation',
		name: 'occupation',
		placeHolder: 'What do they do?',
		isRequired: false,
		type: 'text',
		note: ''
	}
};

const RELATION_LIST = [
	{ label: 'Child', value: 'child' },
	{ label: 'Spouse', value: 'spouse' }
];

const PROFESSION = [ROLES.TEACHER, ROLES.STAFF];

export {
	ADMISSION_DETAILS,
	ALLOW_IMAGE_TYPES,
	MAX_FILE_SIZE,
	BLOOD_GROUP,
	HOUSE_LIST,
	PERSONAL_INFO_DETAILS,
	DATE_FORMAT,
	TODAY,
	NO_INPUT_FIELD,
	ADDRESS_INFO_DETAILS,
	PROFESSIONAL_INFO_DETAILS,
	WORK_TYPE,
	EDUCATION_INFO_DETAILS,
	FAMILY_INFO_DETAILS,
	RELATION_LIST,
	PROFESSION,
	CONTACT_INFO_DETAILS,
	ADMISSION_INFO_DETAILS,
	JOINING_INFO_DETAILS
};
