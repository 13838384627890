import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Form, Formik, FormikValues } from 'formik';

import Input from 'shared/form/input';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { CustomCalendarIcon } from 'shared/components/icons/icons';
import { TODAY } from 'shared/constants/constants';

import StepButtonComponent from './StepButtonComponent';
import { IMemberShipStatus } from '../interface/profile';

interface IMemberShipInfo {
	onPrevClick: () => void;
	memberShipStatus: IMemberShipStatus;
	isEdit: boolean;
	resetStepperBtnTrigger: () => void;
	isStepperBtnTrigger: boolean;
	handleNextClick: () => void;
}

const MemberShipStatus: FC<IMemberShipInfo> = ({
	onPrevClick,
	memberShipStatus,
	isEdit,
	isStepperBtnTrigger,
	resetStepperBtnTrigger,
	handleNextClick
}) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState({
		nextLoading: false,
		submitLoading: false
	});

	const formRef: any = useRef();

	const handleSubmitMemberShipStatus = async (values: FormikValues, isNext: boolean) => {
		try {
			setLoading({ nextLoading: isNext, submitLoading: !isNext });

			const params = {
				life_time_member: values.life_time_member,
				membership_date: values.life_time_member ? values.membership_date || null : null
			};

			await HttpService.post(API_CONFIG.path.membershipInfo, params);
			setLoading({ nextLoading: false, submitLoading: false });

			if (!isStepperBtnTrigger) {
				navigate(isEdit ? '/profile/details' : '/successful-completion');
			} else {
				handleNextClick();
			}
		} catch (err) {
			setLoading({ nextLoading: false, submitLoading: false });
			resetStepperBtnTrigger();
			console.error(err);
		}
	};

	useEffect(() => {
		if (formRef.current && isStepperBtnTrigger) {
			const formValues = formRef.current.values;
			handleSubmitMemberShipStatus(formValues, false);
		}
	}, [isStepperBtnTrigger]);

	return (
		<div className='details-wrapper'>
			<Formik innerRef={formRef} initialValues={memberShipStatus} onSubmit={() => {}}>
				{({ setFieldValue, values }: FormikValues) => (
					<Form className='form'>
						<div className='profile-container member-container'>
							<div
								className={`${
									values.life_time_member ? 'mb--20' : 'justify-content--between align-items--center'
								} flex`}
							>
								<p
									className={`${
										values.life_time_member ? 'mb--12' : ''
									} font--semi-bold font-size--sm text--black mr--20`}
								>
									Are you a Life-Long Member?
								</p>
								<Input
									name='life_time_member'
									type='checkbox'
									checked={values.life_time_member}
									onChange={() => {
										setFieldValue('life_time_member', !values.life_time_member);
										setFieldValue('membership_date', '');
									}}
									classes='toggle-input position--relative'
									placeholder={'Yes'}
								/>
							</div>

							{values.life_time_member && (
								<div className='membership-input'>
									<label className='font-size--sm font--semi-bold'>
										<p>
											Membership Year{' '}
											<span className='font-size--sm font--regular opacity--30 text--secondary'>
												(optional)
											</span>
										</p>
									</label>
									<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
										Please enter year of joining as life member
									</span>
									<div className='mt--12'>
										<DatePicker
											icon={CustomCalendarIcon}
											showIcon
											calendarIconClassname='calendar-icon cursor--pointer membership-calendar-icon'
											className='form-input calendar-input membership-calendar-input'
											placeholderText='YYYY'
											selected={
												values.membership_date ? new Date(values.membership_date, 0, 1) : null
											}
											dateFormat='yyyy'
											showYearDropdown
											showMonthDropdown
											scrollableYearDropdown
											maxDate={TODAY}
											minDate={new Date('01-01-1900')}
											onChange={(date: Date) =>
												setFieldValue('membership_date', moment(date).year())
											}
											showYearPicker
											disabledKeyboardNavigation
											shouldCloseOnSelect
										/>
									</div>
								</div>
							)}
						</div>

						<StepButtonComponent
							disabled={false}
							nextLoading={loading.nextLoading}
							submitLoading={loading.submitLoading}
							isPrevButton={true}
							buttonTitle={isEdit ? 'Update Profile' : 'Complete Registration'}
							onPrevClick={onPrevClick}
							isSave={true}
							nextButtonClick={(isNext: boolean) => handleSubmitMemberShipStatus(values, isNext)}
						/>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default MemberShipStatus;
