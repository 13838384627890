import { CSSProperties } from 'react';

export const reactSelectSignUpStyles: any = {
	option: (base: CSSProperties, state: any) => ({
		...base,
		width: '100%',
		padding: '15px',
		borderBottom: '1px solid #e7e7e7',
		color: '#000000',
		opacity: '0.5',
		fontSize: '12px',
		fontWeight: '400',
		textTransform: 'capitalize',
		backgroundColor: state.isSelected ? '#ffffff' : state.isFocused ? '#ffffff' : '',
		':active': {
			backgroundColor: '#F0F3F6'
		},
		':hover': {
			backgroundColor: '#F0F3F6'
		},
		':focus': {
			backgroundColor: '#ffffff',
			outline: 0
		}
	}),
	menu: (base: CSSProperties) => ({
		...base,
		zIndex: 3,
		marginTop: 10,
		width: '100%',
		position: 'absolute',
		borderRadius: '8px',
		border: '1px solid #D1DEE5',
		background: '#ffffff',
		boxShadow: '0px 4px 6px 0px rgba(12, 53, 106, 0.20)'
	}),
	menuList: (base: CSSProperties) => ({
		...base,
		padding: 0,
		maxHeight: '230px',
		backgroundColor: '#ffffff',
		borderRadius: '8px'
	}),
	clearIndicator: (base: CSSProperties) => ({
		...base,
		cursor: 'pointer'
	}),
	dropdownIndicator: (base: CSSProperties, state: any) => ({
		...base,
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none'
	}),
	control: () => ({
		// none of react-selects styles are passed to <View />
		display: 'flex',
		width: '100%',
		minHeight: '40px  ',
		color: '#0c356a',
		borderRadius: '8px',
		fontSize: '12px',
		background: '#ffffff',
		cursor: 'pointer',
		padding: '0',
		border: 'none'
	}),
	container: () => ({
		width: '100%',
		position: 'relative'
	}),
	singleValue: (base: CSSProperties) => {
		const transition = 'opacity 300ms';
		const color = '#0C356A';
		const fontWeight = '400';
		const fontSize = '12px';

		return { ...base, transition: transition, color: color, fontWeight: fontWeight, fontSize: fontSize };
	},
	valueContainer: (styles: any) => ({
		...styles,
		width: 'calc(100% - 60px)',
		border: '1px solid #d1dee5',
		borderRadius: '8px',
		padding: '0 15px',
		marginRight: '10px',
		textTransform: 'capitalize'
	}),
	placeholder: (styles: any) => ({
		...styles,
		color: '#0c356a',
		opacity: '0.5',
		fontWeight: 400,
		fontSize: '12px'
	}),
	indicatorsContainer: (styles: any) => ({
		...styles,
		width: '50px',
		height: '40px',
		borderRadius: '8px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#F0F3F6'
	}),
	indicatorSeparator: () => ({
		display: 'none'
	}),
	input: (styles: any) => ({
		...styles,
		color: '#0C356A',
		fontWeight: 400,
		fontSize: '12px'
	})
};

export const CountyCodeStyle: any = {
	control: () => ({
		width: '40px',
		minHeight: '40px  ',
		color: '#0c356a',
		borderRadius: '8px',
		fontSize: '12px',
		background: '#ffffff',
		cursor: 'pointer',
		padding: '0',
		border: '1px solid #d1dee5'
	}),
	container: (styles: any) => ({
		...styles,
		width: '40px',
		height: '40px',
		marginRight: '10px'
	}),
	valueContainer: (styles: any) => ({
		...styles,
		width: '40px',
		height: '40px',
		marginRight: '10px',
		padding: '5px',
		fontSize: '12px'
	}),
	indicatorsContainer: (styles: any) => ({
		...styles,
		display: 'none'
	}),
	menu: (base: CSSProperties) => ({
		...base,
		width: 'fit-content'
	}),
	singleValue: (base: CSSProperties, state: any) => ({
		...base,
		transition: 'opacity 300ms',
		color: '#0C356A',
		fontWeight: '400',
		fontSize: state.selectProps.value?.label.length > 3 ? '10px' : '12px'
	})
};
