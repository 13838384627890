import { FC, useEffect, useState } from 'react';
import isNull from 'lodash/isNull';
import { isIOSDevice, isMobile } from 'shared/util/utility';
import { CloseIcon } from '../icons/icons';
import logo from 'assets/images/logo.png';

const InstallationPrompt: FC = () => {
	const [isShowInstall, setIsShowInstall] = useState(false);
	const [isShowIOSInstall, setIsShowIOSInstall] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

	useEffect(() => {
		if (isIOSDevice()) {
			if (isNull(localStorage.getItem('isIOSLogin'))) {
				setIsShowIOSInstall(true);
				localStorage.setItem('isIOSLogin', 'true');
			}
		}

		const handleBeforeInstallPrompt = (e: Event) => {
			e.preventDefault();
			setDeferredPrompt(e);

			setTimeout(() => {
				setIsShowInstall(true);
			}, 10);
		};

		const handleAppInstalled = () => {
			setIsShowInstall(false);
		};

		window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
		window.addEventListener('appinstalled', handleAppInstalled);

		return () => {
			window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
			window.removeEventListener('appinstalled', handleAppInstalled);
		};
	}, []);

	const handleInstallClick = () => {
		if (deferredPrompt) {
			(deferredPrompt as any).prompt(); // Cast to any due to non-standard prompt method
			deferredPrompt.userChoice.then((choiceResult: any) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the installation');
				} else {
					console.log('User dismissed the installation');
				}
				setDeferredPrompt(null);
				setIsShowInstall(false);
			});
		}
	};

	return (
		<>
			{((isShowInstall && isMobile()) || (isShowIOSInstall && isIOSDevice())) && (
				<div className='install-prompt-wrapper'>
					<div className='install-prompt'>
						{isShowIOSInstall && (
							<div
								className='close-icon-wrapper'
								onClick={() => {
									setIsShowInstall(false);
									setIsShowIOSInstall(false);
								}}
							>
								<CloseIcon className='close-icon' />
							</div>
						)}
						<div className='justify-align--center'>
							<img src={logo} alt='OBSSA Logo' className='prompt-logo' />
							{isShowInstall && (
								<p className='font-size--sm font--medium'>
									Old Boys of Balachadi Sainik School Association
								</p>
							)}
							{isShowIOSInstall && (
								<p className='font-size--sm font--medium'>
									Add OBSSA to Home Screen in order to use Web App view!
								</p>
							)}
						</div>
						{isShowInstall && (
							<p
								className='justify-align--center font-size--sm font--bold mr--10'
								onClick={handleInstallClick}
							>
								Install
							</p>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default InstallationPrompt;
