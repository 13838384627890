import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loader } from 'shared/components/spinner/spinner';
import { RightArrowIcon } from 'shared/components/icons/icons';
import { DashboardDetailsContext } from 'store/context/userDashboardStore';

const BatchMemory: FC = () => {
	const navigate = useNavigate();
	const { fetchBatchMemory, batchMemoryLoading, batchMemoryList } = useContext(DashboardDetailsContext);

	useEffect(() => {
		fetchBatchMemory(false);
	}, []);

	return (
		<>
			<div className='memory-wrapper'>
				{batchMemoryLoading && (
					<div className='justify-align--center width--full pt--20 pb--20'>
						<Loader />
					</div>
				)}
				{!batchMemoryLoading && batchMemoryList.length > 0 && (
					<>
						<div className='flex justify-content--between align-items--center width--full'>
							<p className='title'>All Memories</p>
							<p className='see-all' onClick={() => navigate('/all-memories')}>
								See all
								<RightArrowIcon className='ml--5' />
							</p>
						</div>
						<div className='flex flex--wrap'>
							{batchMemoryList.map((batchMemory, index) => {
								const { /* created_at, */ photo, title } = batchMemory;
								return (
									<div
										className={`memory-box-wrapper ${index % 2 !== 0 ? 'ml--20' : ''}`}
										key={index}
										onClick={() => {
											navigate(`/all-memories?memoryId=${batchMemory.created_at}&fromHome=true`);
										}}
									>
										<img src={photo[0]} alt='memory' className='memory-img' />
										{/* <p className='memory-date'>{formatDate(created_at, 'Do MMM')}</p> */}
										<div className='memory-details'>
											<p className='memory-name truncate-lines--2 text--capitalize'>{title}</p>
											<RightArrowIcon className='memory-icon' />
										</div>
									</div>
								);
							})}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default BatchMemory;
