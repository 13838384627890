import { FC, useRef, useEffect } from 'react';
import { ErrorMessage, FieldArray, Form, Formik, FormikValues } from 'formik';
import DatePicker from 'react-datepicker';
import isEmpty from 'lodash/isEmpty';

import { notify } from 'shared/components/notification/notification';
import { familyInfoValidationSchema } from 'shared/constants/validation-schema';
import { CustomCalendarIcon, DeleteIcon, PlusIcon } from 'shared/components/icons/icons';
import CustomButton from 'shared/components/customButton/customButton';
import Input from 'shared/form/input';
import ReactSelect from 'shared/form/reactSelect';

import OptionCard from './optionCard';
import { IClassObj, IFamilyDetails } from '../interface/profile';
import { BLOOD_GROUP, FAMILY_INFO_DETAILS, RELATION_LIST, TODAY } from '../constants/profile';

import 'react-datepicker/dist/react-datepicker.css';

interface IFamilyDetailsProps {
	isCurrentStepChange: boolean;
	isSaveDetails: boolean;
	familyDetails: IFamilyDetails[];
	handleSaveClick: (familyDetails: IFamilyValues) => void;
	setUpdatedAlumniDetails: (familyDetails: IFamilyValues) => void;
	handleResetSaveClick: () => void;
}

interface IFamilyValues {
	family: IFamilyDetails[];
}

const FamilyDetails: FC<IFamilyDetailsProps> = ({
	isCurrentStepChange,
	familyDetails,
	isSaveDetails,
	handleSaveClick,
	setUpdatedAlumniDetails,
	handleResetSaveClick
}) => {
	const formRef: any = useRef();

	const isEmptyFamilyDetails = () => {
		const otherFamilyMembers = formRef.current?.values?.family || [];

		// Check if any family member has empty fields other than 'blood_group and occupation'
		const isEmptyStringPresent = otherFamilyMembers.some((familyDetails: IClassObj) =>
			Object.keys(familyDetails).some(
				(key) => key !== 'blood_group' && key !== 'occupation' && familyDetails[key] === ''
			)
		);

		return isEmptyStringPresent;
	};

	const handleSaveFamilyDetails = (isSaveDetail: boolean) => {
		if (formRef.current) {
			const errorObj = formRef.current.errors;
			if (isEmpty(errorObj)) {
				isSaveDetail
					? handleSaveClick({ family: formRef.current.values.family })
					: setUpdatedAlumniDetails({ family: formRef?.current?.values.family });
			} else {
				notify(Object.values(errorObj?.family.filter(Boolean)[0])[0] as string, 'error');
				handleResetSaveClick();
			}
		}
	};

	useEffect(() => {
		isSaveDetails && handleSaveFamilyDetails(true);
	}, [isSaveDetails]);

	useEffect(() => {
		isCurrentStepChange && handleSaveFamilyDetails(false);
	}, [isCurrentStepChange]);

	return (
		<div className='edit-profile-container'>
			<Formik
				innerRef={formRef}
				initialValues={{
					family: familyDetails.map(({ name, birthdate, blood_group, occupation, relation }) => ({
						name,
						relation,
						birthdate,
						blood_group,
						occupation
					}))
				}}
				onSubmit={() => {}}
				validationSchema={familyInfoValidationSchema}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ errors, setFieldValue, values, touched }: FormikValues) => (
					<Form className='form'>
						<div className='profile-container'>
							<FieldArray name='family'>
								{({ push, remove }) => (
									<div>
										{values.family.length > 0 &&
											values.family.map((instituteInfo: string, index: number) => (
												<div
													className={`${index > 0 ? 'family-details-wrapper' : ''}`}
													key={index}
												>
													<div className='institute-container'>
														<div className='flex justify-content--between align-content--center pb--20'>
															<p className='font-size--lg font--semi-bold'>
																Family Member {index + 1}{' '}
																<span className='font-size--sm font--regular opacity--30 text--secondary'>
																	(Optional)
																</span>
															</p>

															<div
																className='delete-icon-wrapper cursor--pointer'
																onClick={() => remove(index)}
															>
																<DeleteIcon />
															</div>
														</div>
														{Object.entries(instituteInfo).map(
															([info, _], fieldIndex: number) => {
																const {
																	label,
																	name,
																	placeHolder,
																	isRequired,
																	type,
																	note
																} = FAMILY_INFO_DETAILS[info];
																const fieldError =
																	errors.family &&
																	errors.family[index] &&
																	errors.family[index][name];
																const fieldTouched =
																	touched.family &&
																	touched.family[index] &&
																	touched.family[index][name];

																const excludedNames = [
																	'relation',
																	'blood_group',
																	'birthdate'
																];
																return (
																	<div
																		key={fieldIndex}
																		className='details-input-wrapper mb--15'
																	>
																		<label className='font-size--sm opacity--50'>
																			<p>
																				{label}
																				{isRequired ? (
																					<span className='text--red'>
																						{' '}
																						*
																					</span>
																				) : (
																					<span className='font-size--sm font--regular opacity--30 text--secondary'>
																						(optional)
																					</span>
																				)}
																			</p>
																		</label>
																		<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
																			{note}
																		</span>
																		<div className='mt--12'>
																			{!excludedNames.includes(name) && (
																				<Input
																					name={`family[${index}].${name}`}
																					type={type}
																					data-testid={name}
																					placeholder={placeHolder}
																					value={values.family[index][name]}
																					classes={`${
																						name === 'birthdate'
																							? 'calendar-input'
																							: ''
																					} ${
																						fieldError && fieldTouched
																							? 'form-input--error'
																							: ''
																					}`}
																					onChange={(e) =>
																						setFieldValue(
																							`family[${index}].${name}`,
																							e.target.value
																						)
																					}
																				/>
																			)}
																			{name === 'relation' && (
																				<ReactSelect
																					name={`family[${index}].${name}`}
																					placeholder={placeHolder}
																					options={RELATION_LIST}
																					onChange={(selectedValue: any) => {
																						setFieldValue(
																							`family[${index}].${name}`,
																							selectedValue.value
																						);
																					}}
																					defaultValue={
																						values.family[index][name]
																							? {
																									label: values
																										.family[index][
																										name
																									],
																									value: values
																										.family[index][
																										name
																									]
																							  }
																							: null
																					}
																				/>
																			)}
																			{name === 'blood_group' && (
																				<OptionCard
																					optionList={BLOOD_GROUP}
																					selectedOptions={
																						values.family[index].blood_group
																					}
																					name={`family[${index}].${name}`}
																					setFieldValue={setFieldValue}
																					isMultiple={false}
																				/>
																			)}
																			{name === 'birthdate' && (
																				<DatePicker
																					icon={CustomCalendarIcon}
																					showIcon
																					calendarIconClassname='calendar-icon cursor--pointer'
																					className='form-input calendar-input'
																					placeholderText='dd-mm-yyyy'
																					selected={
																						values.family[index].birthdate
																							? new Date(
																									values.family[
																										index
																									].birthdate
																							  )
																							: null
																					}
																					dateFormat='dd-MM-yyyy'
																					showYearDropdown
																					showMonthDropdown
																					scrollableYearDropdown
																					yearDropdownItemNumber={100}
																					maxDate={TODAY}
																					onChange={(date: Date) =>
																						setFieldValue(
																							`family[${index}].${name}`,
																							date
																						)
																					}
																					disabledKeyboardNavigation
																					shouldCloseOnSelect
																					onFocus={(e) => e.target.blur()}
																				/>
																			)}
																		</div>
																		<ErrorMessage
																			name={`family[${index}].${name}`}
																			component='p'
																			className='error'
																		/>
																	</div>
																);
															}
														)}
													</div>
												</div>
											))}
										<div className='justify-align--center mb--30 width--full'>
											<CustomButton
												disabled={isEmptyFamilyDetails()}
												buttonType='button'
												buttonTitle='Add a Family Member'
												btnClassName='secondary-button add-education-button'
												ButtonIcon={PlusIcon}
												onButtonClick={() =>
													push({
														name: '',
														relation: '',
														birthdate: '',
														blood_group: '',
														occupation: ''
													})
												}
											/>
										</div>
									</div>
								)}
							</FieldArray>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default FamilyDetails;
