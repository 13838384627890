import { FC, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { IGoogleMapProps, ILatLng, IMapProps, IPlace } from '../interface/profile';

interface IProps extends IMapProps, IGoogleMapProps {}

const MapComponent: FC<IProps> = (props) => {
	const {
		onLocationChange,
		initialLat,
		initialLng,
		setIsMapOpen,
		setFieldValue,
		searchableAddress,
		setSearchableAddress
	} = props;
	const [position, setPosition] = useState<ILatLng>({ lat: initialLat, lng: initialLng });
	const [map, setMap] = useState<Record<string, any>>();
	const [places, setPlaces] = useState({} as IPlace);
	const [address, setAddress] = useState(searchableAddress || '');

	const handleMarkerDragEnd = (event: google.maps.MapMouseEvent) => {
		const lat = event?.latLng?.lat();
		const lng = event?.latLng?.lng();

		if (lat && lng) {
			setPlaces({ ...places, geometry: { location: { lat, lng } } });
			setPosition({ lat, lng });
			onLocationChange(lat, lng);
		}
	};

	const handleSelect = async (selectedAddress: string) => {
		try {
			const results: Record<string, any> = await geocodeByAddress(selectedAddress);

			if (results.length === 0) {
				console.error('ZERO_RESULTS: No results found for the selected address');
				return;
			}
			const latLng = await getLatLng(results[0]);
			setAddress(selectedAddress);
			setSearchableAddress(selectedAddress);
			setPosition({ lat: latLng.lat, lng: latLng.lng });
			setMap(map);
			setPlaces({ geometry: { location: { lat: latLng.lat, lng: latLng.lng } }, name: address });
			onLocationChange(latLng.lat, latLng.lng);
			handleSubmitPlace();
		} catch (error) {
			console.error(error);
		}
	};

	const handleSubmitPlace = () => {
		setFieldValue('current_latitude', String(position.lat) || '0');
		setFieldValue('current_longitude', String(position.lng) || '0');
		setIsMapOpen({ open: false, name: '' });
	};

	return (
		<>
			<div className='map-container width--full'>
				<PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
					{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
						<div className='position--relative'>
							<span className='desktop-note font-size--sm font--regular opacity--30 text--secondary mt--3'>
								You can type and/or select Location using map.
							</span>
							<input
								{...getInputProps({
									placeholder: 'Search by Geo Location'
								})}
								value={address}
								className='map-input form-input mt--12'
							/>
							<div className='dropdown-wrapper'>
								{loading && <div style={{ padding: '10px' }}>Loading...</div>}
								{suggestions.map((suggestion) => (
									<div
										{...getSuggestionItemProps(suggestion)}
										key={suggestion.placeId}
										className='dropdown-option'
									>
										{suggestion.description}
									</div>
								))}
							</div>
						</div>
					)}
				</PlacesAutocomplete>
			</div>
			<GoogleMap
				center={{ lat: position.lat, lng: position.lng }}
				defaultZoom={14}
				// disableDefaultUI={false}
				clickableIcons={false}
			></GoogleMap>
			{(!isEmpty(places) || address) && (
				<Marker
					position={{
						lat: places.geometry?.location.lat || initialLat,
						lng: places.geometry?.location.lng || initialLng
					}}
					title={places.name}
					draggable={true}
					onDragEnd={handleMarkerDragEnd}
				/>
			)}
		</>
	);
};

const WrappedMap = withScriptjs(withGoogleMap<IProps>(MapComponent));

export { MapComponent, WrappedMap };
