import { FC, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { APIProvider, InfoWindow, Map, MapEvent } from '@vis.gl/react-google-maps';

import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_KEY } from 'shared/constants';
import MarkerPopup from 'features/nearByMap/component/markerPopup';
import { IAlumniList } from 'features/nearByMap/interface/nearByMap';

export type MapProps = {
	markers: JSX.Element;
	infoWindow: IAlumniList;
	defaultLocation: { lat: number; lng: number };
	onCenterChange: (center: { lat: number; lng: number }) => void;
	onOutsideClick: () => void;
};
const defaultValue = { zoom: 12 };

const GoogleMap: FC<MapProps> = ({ markers, defaultLocation, infoWindow, onCenterChange, onOutsideClick }) => {
	const onDragend = useCallback(
		(e: MapEvent) => {
			const center = e.map.getCenter();
			let location = defaultLocation;
			if (center) {
				location = {
					lat: center.lat(),
					lng: center.lng()
				};
			}
			onCenterChange(location);
		},
		[defaultLocation, onCenterChange]
	);

	return (
		<div style={{ height: 'calc(100vh - 180px)', width: '100%' }}>
			<APIProvider apiKey={GOOGLE_MAP_API_KEY}>
				<Map
					onDragstart={onOutsideClick}
					onClick={onOutsideClick}
					defaultCenter={defaultLocation}
					defaultZoom={defaultValue.zoom}
					onDragend={onDragend}
					mapId={GOOGLE_MAP_KEY}
				>
					{markers}
					{!isEmpty(infoWindow) && (
						<InfoWindow
							pixelOffset={new google.maps.Size(0, -50)}
							position={infoWindow}
							onCloseClick={onOutsideClick}
						>
							<MarkerPopup {...infoWindow} />
						</InfoWindow>
					)}
				</Map>
			</APIProvider>
		</div>
	);
};

export default GoogleMap;
