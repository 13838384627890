import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { useDispatch } from 'react-redux';

import { IState } from 'shared/interface/state';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import Spinner from 'shared/components/spinner/spinner';
import { scrollToTop } from 'shared/util/utility';
import { ROLES } from 'features/auth/interface/auth';
import { createAction } from 'shared/util/utility';
import * as actionTypes from 'store/actionTypes';

import ProfileHeader from '../component/ProfileHeader';
import ProfileStepper from '../component/ProfileStepper';
import PersonalInfo from '../component/PersonalInfo';
import AddressInfo from '../component/AddressInfo';
import EducationInfo from '../component/EducationInfo';
import ProfessionInfo from '../component/ProfessionInfo';
import FamilyInfo from '../component/FamilyInfo';
import MemberShipStatus from '../component/MemberShipStatus';
import { HeaderTitles, RedirectionSteps } from '../constants/profile';
import { IEducationInfoData, IFamilyInfoData, IMemberShipStatus, IPersonalInfoData } from '../interface/profile';

const Profile: FC = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const alumniId: string = useSelector((state: IState) => state.auth?.userData?.id);

	const [goSteps, setGoSteps] = useState<number>(Number(state?.step) || 0);
	const [loading, setLoading] = useState(true);
	const [isStepperBtnTrigger, setIsStepperBtnTrigger] = useState(false);
	const [role, setRole] = useState<ROLES | string>('');
	const [personalInfoData, setPersonalInfoData] = useState<IPersonalInfoData>({
		profile_key: '',
		profile_photo: '',
		first_name: '',
		last_name: '',
		birthdate: null,
		alternate_contact: [],
		contact_number: '',
		country_code: '',
		email: '',
		blood_group: '',
		admission_year: null,
		passing_year: null,
		house: [],
		year_of_joining: null,
		year_of_leaving: null,
		category: ''
	});
	const [addressInfoData, setAddressInfoData] = useState({
		address: '',
		current_zipcode: '',
		current_city: '',
		current_state: 'Gujarat',
		current_country: 'India',
		current_latitude: '',
		current_longitude: '',
		location: ''
	});

	const [educationInfoData, setEducationInfoData] = useState<IEducationInfoData[]>([
		{
			field: '',
			institute_name: '',
			institute_passout_year: null
		}
	]);

	const [professionInfoData, setProfessionInfoData] = useState({
		work_type: '',
		organization: '',
		designation: '',
		location: ''
	});

	const [familyInfoData, setFamilyInfoData] = useState<IFamilyInfoData[]>([]);

	const [memberShipStatus, setMemberShipStatus] = useState<IMemberShipStatus>({
		life_time_member: false,
		membership_date: null
	});

	const [filledSteps, setFilledSteps] = useState(0);
	const [nextStep, setNextStep] = useState(Number(state?.step) || 0);

	const handlePrevButtonClick = () => {
		setGoSteps(goSteps - 1);
		navigate('/profile', { state: { step: goSteps - 1, isEdit: state?.isEdit } });
		fetchAlumniDetails(true);
	};

	const fetchAlumniDetails = (isPrev = false, isRepeat = false) => {
		setLoading(true);
		HttpService.get(`${API_CONFIG.path.profile}/${alumniId}`)
			.then((response) => {
				const {
					address,
					education,
					profession,
					family,
					membership_date,
					life_time_member,
					profile_key,
					profile_photo,
					first_name,
					last_name,
					birthdate,
					country_code,
					contact_number,
					email,
					blood_group,
					admission_year,
					passing_year,
					house,
					alternate_contact,
					step,
					role,
					year_of_joining,
					year_of_leaving,
					category
				} = response;
				const personalData: IPersonalInfoData = {
					profile_key,
					profile_photo,
					first_name,
					last_name,
					birthdate: birthdate ? new Date(birthdate) : null,
					country_code,
					contact_number,
					email,
					blood_group,
					alternate_contact: alternate_contact || [],
					// Alumni
					admission_year,
					passing_year,
					house,
					// Teacher,
					year_of_joining,
					year_of_leaving,
					category
				};
				setPersonalInfoData(personalData);
				setRole(role);
				setAddressInfoData({
					address: address.address,
					current_zipcode: address.current_zipcode,
					current_city: address.current_city,
					current_state: address.current_state,
					current_country: address.current_country,
					current_latitude: address.current_latitude,
					current_longitude: address.current_longitude,
					location: address.location
				});
				const updatedEducation = education.map(({ id, ...rest }: IEducationInfoData) => ({ ...rest }));

				if (!isEmpty(updatedEducation)) {
					setEducationInfoData(updatedEducation);
				}
				setProfessionInfoData({
					work_type: profession.work_type,
					organization: profession.organization,
					designation: profession.designation,
					location: profession.location
				});
				const updatedFamilyInfo = family.map(({ id, ...rest }: IFamilyInfoData) => ({
					name: rest.name,
					relation: rest.relation,
					birthdate: rest.birthdate ? new Date(rest.birthdate as Date) : null,
					blood_group: rest.blood_group,
					occupation: rest.occupation
				}));
				if (!isEmpty(updatedFamilyInfo)) {
					setFamilyInfoData(updatedFamilyInfo);
				}
				setMemberShipStatus({
					membership_date: membership_date ?? null,
					life_time_member
				});

				!isNull(step) && setFilledSteps(RedirectionSteps[step as string] as number);

				if (!isNull(step) && state?.isEdit !== true && !isPrev && !isRepeat) {
					setGoSteps(RedirectionSteps[step as string] || 0);

					// RedirectionSteps[step as string] === 5 && navigate('/profile/details');
					RedirectionSteps[step as string] === 5 && dispatch(createAction(actionTypes.UPDATE_USER_ROUTE));
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			});
	};

	useEffect(() => {
		fetchAlumniDetails();
		scrollToTop();
	}, []);

	const onStepperClick = (step: number) => {
		setIsStepperBtnTrigger(true);
		setNextStep(step);
	};

	const getStepComponent = () => {
		const commonProps = {
			isEdit: state?.isEdit || false,
			role,
			onPrevClick: handlePrevButtonClick,
			handleNextClick: () => {
				const step = isStepperBtnTrigger ? nextStep : goSteps + 1;
				setGoSteps(step);
				fetchAlumniDetails(false, true);
				setIsStepperBtnTrigger(false);
				navigate('/profile', { state: { step, isEdit: state?.isEdit } });
			},
			isStepperBtnTrigger: isStepperBtnTrigger,
			resetStepperBtnTrigger: () => setIsStepperBtnTrigger(false)
		};

		if (!loading) {
			switch (goSteps) {
				case 0:
					return (
						<PersonalInfo
							{...commonProps}
							personalInfoData={personalInfoData}
							isEdit={state?.isEdit || false}
						/>
					);
				case 1:
					return (
						<AddressInfo
							{...commonProps}
							addressInfoData={addressInfoData}
							isEdit={state?.isEdit || false}
						/>
					);
				case 2:
					return (
						<EducationInfo
							{...commonProps}
							educationInfoData={educationInfoData}
							isEdit={state?.isEdit || false}
						/>
					);
				case 3:
					return (
						<ProfessionInfo
							{...commonProps}
							professionInfoData={professionInfoData}
							isEdit={state?.isEdit || false}
						/>
					);
				case 4:
					return (
						<FamilyInfo {...commonProps} familyInfoData={familyInfoData} isEdit={state?.isEdit || false} />
					);
				case 5:
					return (
						<MemberShipStatus
							onPrevClick={handlePrevButtonClick}
							handleNextClick={() => {
								setGoSteps(nextStep);
								setIsStepperBtnTrigger(false);
								fetchAlumniDetails(false, true);
							}}
							memberShipStatus={memberShipStatus}
							isEdit={state?.isEdit || false}
							isStepperBtnTrigger={isStepperBtnTrigger}
							resetStepperBtnTrigger={() => setIsStepperBtnTrigger(false)}
						/>
					);
				default:
					return null;
			}
		}
		return null;
	};

	return (
		<div className='form-profile-wrapper'>
			{loading && <Spinner />}
			<div className='profile-wrapper'>
				<ProfileHeader
					title={`${HeaderTitles[goSteps] as string} ${
						HeaderTitles[goSteps] === 'Membership' ? 'Status' : 'Information'
					}`}
					details={`Fill up all your ${(HeaderTitles[goSteps] as string).toLowerCase()} details`}
				/>
				<ProfileStepper goSteps={goSteps} filledSteps={filledSteps} onStepperClick={onStepperClick} />
				{getStepComponent()}
			</div>
		</div>
	);
};

export default Profile;
