import moment from 'moment';
import { HomeIcon, ProfileIcon, SearchIcon } from 'shared/components/icons/icons';

const SCALETECH_REDIRECTION_LINK =
	'https://www.scaletech.xyz/?utm_source=OBSSA&utm_medium=website&utm_campaign=obssa_redirect';

const PATHNAME = {
	'/home': 'OBSSA',
	'/home/friends-near-you': 'Friends Near You',
	'/home/all-memories': 'Friends Near You',
	'/search': 'Search Alumni',
	'/profile': 'Profile',
	'/settings': 'Settings',
	'/suggestion-corner': 'Suggestion Corner',
	'/notifications': 'Notifications',
	'/edit-profile': 'Edit Profile',
	'/all-memories': 'All Memories',
	'/maps': 'Friends Near You',
	'/constitution': 'Constitution'
};

const PROFILE_PAGE = 'Profile';
const MAIN_TABS = ['/home', '/search', '/profile'];

const ACTIVE_FOOTER_TAB = {
	home: ['/home', '/home/friends-near-you', '/home/all-memories', '/all-memories', '/maps'],
	alumni: ['/search'],
	profile: ['/profile', '/settings', '/suggestion-corner', '/edit-profile']
};

const FOOTER_TABS = [
	{ icon: HomeIcon, title: 'home', redirect: '/home' },
	{ icon: SearchIcon, title: 'alumni', redirect: '/search' },
	{ icon: ProfileIcon, title: 'profile', redirect: '/profile' }
];

const HIDE_FOOTER = ['/edit-profile', '/constitution'];

const SETTING_TABS = ['/settings', '/suggestion-corner'];

const SETTING_DETAILS = [
	{ title: 'Edit profile', href: '/edit-profile' },
	{ title: 'Notification Settings', isToggle: true },
	{ title: 'Suggestion corner', href: '/suggestion-corner' },
	{ title: 'Logout' }
];

const HIDE_NOTIFICATION_OPT = ['/notifications', '/edit-profile', '/constitution'];

const PROFILE_DETAILS = ['personal', 'address', 'professional', 'education', 'family'];

export const NUMBER_REGEX = /^[0-9]+(?:\.[0-9]+)?$/;
export const ALLOW_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
export const TODAY = new Date();
export const CURRENT_YEAR = moment(TODAY).year();

export {
	SCALETECH_REDIRECTION_LINK,
	PATHNAME,
	PROFILE_PAGE,
	MAIN_TABS,
	FOOTER_TABS,
	SETTING_TABS,
	SETTING_DETAILS,
	HIDE_NOTIFICATION_OPT,
	PROFILE_DETAILS,
	ACTIVE_FOOTER_TAB,
	HIDE_FOOTER
};
