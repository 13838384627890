import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { RightArrowIcon } from 'shared/components/icons/icons';
import { formatDate } from 'shared/util/utility';
import { useQuery } from 'shared/hooks/useQuery';
import { Loader } from 'shared/components/spinner/spinner';
import { DashboardDetailsContext } from 'store/context/userDashboardStore';
import { IBatchMemory } from 'features/dashboard/interface/dashboard';

import MemoryDetails from '../component/memoryDetails';

const AllMemories: FC = () => {
	const params = useQuery();
	const navigate = useNavigate();
	const { fetchBatchMemory, batchMemoryLoading, batchMemoryList } = useContext(DashboardDetailsContext);

	const memoryId = useMemo(() => params.get('memoryId'), [params]);

	const [detailMemory, setDetailMemory] = useState<IBatchMemory>({} as IBatchMemory);

	useEffect(() => {
		if (memoryId && batchMemoryList && batchMemoryList.length > 0) {
			const details = batchMemoryList.find((i) => i.created_at === Number(memoryId));
			if (details) {
				setDetailMemory(details);
			}
		}
	}, [memoryId, batchMemoryList]);

	useEffect(() => {
		fetchBatchMemory(false);
	}, []);

	return (
		<div className={`all-memory-wrapper position--relative memory-swiper-wrapper`}>
			<div className='memory-details-wrapper'>
				{batchMemoryLoading && (
					<div className='justify-align--center width--full pt--20 pb--20'>
						<Loader />
					</div>
				)}
				{!batchMemoryLoading &&
					!memoryId &&
					batchMemoryList.length > 0 &&
					batchMemoryList.map((batchMemory, index) => {
						const { created_at, photo, title, total_photos } = batchMemory;
						return (
							<div
								key={index}
								onClick={() => navigate(`/all-memories?memoryId=${batchMemory.created_at}`)}
							>
								<div className='flex align-items--center justify-content--between pl--20 pr--20 pb--10 cursor--pointer'>
									<div>
										<p className='font--semi-bold pb--5 text--capitalize'>{title}</p>
										<p className='font-size--10 opacity--50'>
											{formatDate(created_at, 'DD MMM, YYYY')}
										</p>
									</div>
									<RightArrowIcon />
								</div>
								<div className='pl--10 pr--10 flex mb--10 flex--wrap'>
									{photo.map((photoUrl, index) =>
										index < 6 ? (
											<div
												key={index}
												className={`memory-img ${index === 5 ? 'memory-img--last' : ''}`}
												data-content={`+ ${total_photos - 6} more`}
											>
												<img
													src={photoUrl}
													alt='memory'
													className='width--full height--full object-fit--cover'
												/>
											</div>
										) : (
											<React.Fragment key={index} />
										)
									)}
								</div>
							</div>
						);
					})}
				{memoryId && !isEmpty(detailMemory) && <MemoryDetails memoryDetails={detailMemory} />}
			</div>
		</div>
	);
};

export default AllMemories;
