import { FC } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import { IAlumniList } from '../interface/nearByMap';

export type MapProps = {
	name: string;
	lat: number;
	lng: number;
	key: string;
};

type Props = {
	points: IAlumniList[];
	setInfoWindow: React.Dispatch<React.SetStateAction<IAlumniList>>;
};

const AlumniMarkers: FC<Props> = ({ points, setInfoWindow }) => {
	return (
		<>
			{points.map((point) => (
				<AdvancedMarker position={point} key={point.key} onClick={() => setInfoWindow(point)}>
					<div
						style={{
							width: '50px',
							height: '50px',
							borderRadius: '5px',
							padding: '5px',
							backgroundColor: '#fff',
							position: 'relative',
							boxShadow: '0px 7px 13px 0px #0081c980'
						}}
						className='marker-wrapper'
					>
						<div
							style={{
								width: '9px',
								height: '9px',
								zIndex: '10',
								position: 'absolute',
								bottom: '-4.7px',
								left: 'calc(50% - 4.5px)',
								borderWidth: '18px 15px 0 15px',
								borderColor: '#498f9b transparent transparent transparent',
								backgroundColor: 'white',
								transform: 'rotate(45deg)',
								borderRadius: '2px'
							}}
						/>
						<img
							style={{ width: '40px', height: '40px', objectFit: 'cover', objectPosition: 'top' }}
							src={point.profile_photo}
							alt='image'
						/>
					</div>
				</AdvancedMarker>
			))}
		</>
	);
};

export default AlumniMarkers;
