import { Field } from 'formik';
import React, { FC } from 'react';

interface IProps {
	type: string;
	placeholder: string;
	name: string;
	classes?: string;
	disable?: boolean;
	readOnly?: boolean;
	onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	maxLength?: number;
	value?: string;
	autoComplete?: string;
	defaultValue?: string;
	checked?: boolean;
}
const Input: FC<IProps> = (props) => {
	return (
		<Field
			type={props.type}
			onBlur={props.onBlur}
			disabled={props.disable}
			placeholder={props.placeholder}
			value={props.value}
			defaultValue={props.defaultValue}
			className={`form-input ${props.classes}`}
			readOnly={props.readOnly}
			autoComplete={`${props.autoComplete || 'off'}`}
			data-lpignore='true'
			maxLength={props.maxLength}
			onChange={props.onChange}
			name={props.name}
			checked={props.checked || false}
		/>
	);
};

export default Input;
