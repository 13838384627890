import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPinIcon } from 'shared/components/icons/icons';

import { useGetUserLocation } from 'shared/hooks/useGetUserLocation';
import { DashboardDetailsContext } from 'store/context/userDashboardStore';

const NearByAlumni: FC = () => {
	const navigate = useNavigate();

	const userLocation = useGetUserLocation();

	const { nearByListLoading, nearByList, fetchNearByAlumni, totalAlumni } = useContext(DashboardDetailsContext);

	useEffect(() => {
		if (userLocation) {
			fetchNearByAlumni(false, userLocation);
		}
	}, [userLocation]);

	return (
		<>
			<div className='gmap-wrapper'>
				<div className='gmap-content-wrapper'>
					<div>
						<p className='gmap-title'>Discover where</p>
						<p className='gmap-title'> your friends are!</p>
					</div>
					{!nearByListLoading && nearByList.length > 0 && (
						<div className='flex justify-content--between align-items--end'>
							<div>
								<p className='gmap-users'>{totalAlumni} near you</p>
								<div className='flex'>
									<div className='flex'>
										{nearByList.map(
											({ image, name, key }, index) =>
												index <= 3 && (
													<img className='user-profile' src={image} alt={name} key={key} />
												)
										)}
										{nearByList.length > 3 && (
											<div className={`user-profile user-profile--other`}>
												<p>+{totalAlumni - 4}</p>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='map-pin-icon-wrapper' onClick={() => navigate('/maps')}>
								<MapPinIcon className='map-pin-icon' />
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default NearByAlumni;
