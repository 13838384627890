import { FC, useRef, useEffect } from 'react';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { notify } from 'shared/components/notification/notification';
import { addressInfoValidationSchema } from 'shared/constants/validation-schema';
import Input from 'shared/form/input';
import { GoogleMapModal } from './GoogleMapModal';

import { ADDRESS_INFO_DETAILS } from '../constants/profile';
import { IAddressDetails } from '../interface/profile';
import 'react-datepicker/dist/react-datepicker.css';

interface IAddressDetailsProps {
	isCurrentStepChange: boolean;
	isSaveDetails: boolean;
	addressDetails: IAddressDetails;
	handleSaveClick: (addressDetails: IAddressValues) => void;
	setUpdatedAlumniDetails: (addressDetails: IAddressValues) => void;
	handleResetSaveClick: () => void;
}

interface IAddressValues {
	address: IAddressDetails;
}

const AddressDetails: FC<IAddressDetailsProps> = ({
	isCurrentStepChange,
	addressDetails,
	isSaveDetails,
	handleSaveClick,
	setUpdatedAlumniDetails,
	handleResetSaveClick
}) => {
	const formRef: any = useRef();

	const handleSaveAddressDetails = (isSaveDetail: boolean) => {
		if (formRef.current) {
			const errorObj = formRef.current.errors;
			if (isEmpty(errorObj)) {
				isSaveDetail
					? handleSaveClick({ address: formRef.current.values })
					: setUpdatedAlumniDetails({ address: formRef?.current?.values });
			} else {
				notify(Object.values(errorObj)[0] as string, 'error');
				handleResetSaveClick();
			}
		}
	};

	useEffect(() => {
		isSaveDetails && handleSaveAddressDetails(true);
	}, [isSaveDetails]);

	useEffect(() => {
		isCurrentStepChange && handleSaveAddressDetails(false);
	}, [isCurrentStepChange]);

	return (
		<div className='edit-profile-container'>
			<Formik
				innerRef={formRef}
				initialValues={{
					...addressDetails
				}}
				onSubmit={() => {}}
				validationSchema={addressInfoValidationSchema}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ errors, setFieldValue, values, touched }: FormikValues) => (
					<Form className='form'>
						<div className='profile-container'>
							{ADDRESS_INFO_DETAILS.map(
								({ label, name, placeHolder, isRequired, type, note }, index: number) => (
									<div key={index} className='details-input-wrapper mb--15'>
										<label className='font-size--sm opacity--50'>
											{label}
											{isRequired ? (
												<span className='text--red'> *</span>
											) : (
												<span className='font-size--sm font--regular opacity--30 text--secondary'>
													(optional)
												</span>
											)}
										</label>
										<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
											{note}
										</span>
										<Input
											name={name}
											type={type}
											data-testid={name}
											placeholder={placeHolder}
											value={values[name]}
											classes={`${touched[name] && errors[name] ? 'form-input--error' : ''}`}
											onChange={({ target: { value } }) =>
												setFieldValue(
													name,
													value.indexOf(' ') === 0 || value.trim().length <= 0 ? '' : value
												)
											}
										/>
										<ErrorMessage name={name} component='p' className='error' />
									</div>
								)
							)}
							<div className='mb--30'>
								<label className='font-size--sm opacity--50'>
									<p>Location</p>
								</label>
								<GoogleMapModal
									setFieldValue={setFieldValue}
									latLag={{
										lat: Number(addressDetails.current_latitude) || 23.0225,
										lng: Number(addressDetails.current_longitude) || 72.5714
									}}
									place={addressDetails.location || ''}
								/>
								<ErrorMessage name='location' component='p' className='error' />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default AddressDetails;
