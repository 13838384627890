import React, { PropsWithChildren, createContext, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { IProfileData } from 'features/profile/interface/profile';

import { API_CONFIG } from 'shared/constants/api';
import { IState } from 'shared/interface/state';
import HttpService from 'shared/services/http.service';
import { IResponseObject } from 'shared/interface';
import { INotificationList, INotificationResponse } from 'features/setting/interface/setting';

const UserDetailsStore: React.FC<PropsWithChildren> = ({ children }) => {
	const [profileData, setProfileData] = useState({} as IProfileData);
	const [profileLoading, setProfileLoading] = useState(true);
	const [notificationLoading, setNotificationLoading] = useState(true);

	const [notificationList, setNotificationList] = useState<INotificationList[]>([]);

	const fetchNotification = async (showLoader = true) => {
		if (showLoader) {
			setNotificationLoading(true);
		}
		try {
			const list: IResponseObject<INotificationResponse[]> = await HttpService.get(API_CONFIG.path.notification);

			if (list && list.data && list.data.length > 0) {
				const data = list.data
					.sort((a, b) => b.created_at - a.created_at)
					.map((i) => ({ ...i, created_at: moment.unix(i.created_at).fromNow() }));
				setNotificationList(data);
			}
			setNotificationLoading(false);
		} catch (error) {
			console.error(error);
			setNotificationLoading(false);
		}
	};

	useEffect(() => {
		fetchNotification();
	}, []);

	const ownId = useSelector((state: IState) => state.auth.userData.id);

	const fetchProfile = async (id: string) => {
		setProfileLoading(true);
		try {
			const profile: IProfileData = await HttpService.get(`${API_CONFIG.path.profile}/${id}`);

			if (profile) {
				setProfileData(profile);
			}
			setProfileLoading(false);
		} catch (error) {
			console.error(error);
			setProfileLoading(false);
		}
	};

	useEffect(() => {
		fetchProfile(ownId);
	}, [ownId]);

	const contextData = useMemo(
		() => ({
			profileData,
			profileLoading,
			setProfileData,
			notificationList,
			notificationLoading,
			fetchNotification
		}),
		[profileData, profileLoading, setProfileData, notificationList, notificationLoading, fetchNotification]
	);

	return ownId ? <UserDetailsContext.Provider value={contextData}>{children}</UserDetailsContext.Provider> : null;
};

interface ContextType {
	profileData: IProfileData;
	profileLoading: boolean;
	setProfileData: React.Dispatch<React.SetStateAction<IProfileData>>;
	notificationLoading: boolean;
	notificationList: INotificationList[];
	fetchNotification: (showLoader: boolean) => void;
}

export const UserDetailsContext = createContext({} as ContextType);
export default UserDetailsStore;
