import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import CustomButton, { IClassObj } from 'shared/components/customButton/customButton';
import { EditIcon } from 'shared/components/icons/icons';
import { IState } from 'shared/interface/state';

import {
	IEducationInfoData,
	IFamilyInfoData,
	IMemberShipStatus,
	IPersonalInfoData,
	IPersonalInfoMapper
} from 'features/signUp/profile/interface/profile';
import ViewAlumniCard from '../components/ViewAlumniCard';
import {
	AddressInfo,
	EducationInfo,
	EducationLabelMapper,
	FamilyDetails,
	FamilyLabelMapper,
	MembershipStatus,
	PersonalInfo,
	ProfessionInfo
} from '../constants/alumniDetails';
import ScaletechFooter from 'shared/components/footer/scaletechFooter';
import { ROLES } from 'features/auth/interface/auth';

const AlumniDetails: FC = () => {
	const alumniId: string = useSelector((state: IState) => state.auth?.userData?.id);

	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [personalInfoData, setPersonalInfoData] = useState<IPersonalInfoData>({
		profile_photo: '',
		first_name: '',
		last_name: '',
		birthdate: null,
		alternate_contact: [],
		contact_number: '',
		country_code: '',
		email: '',
		blood_group: '',
		admission_year: null,
		passing_year: null,
		house: []
	});
	const [addressInfoData, setAddressInfoData] = useState({
		address: '',
		current_zipcode: '',
		current_city: '',
		current_state: '',
		current_country: '',
		current_latitude: '',
		current_longitude: '',
		location: ''
	});

	const [educationInfoData, setEducationInfoData] = useState<IEducationInfoData[]>([
		{
			field: '',
			institute_name: '',
			institute_passout_year: null
		}
	]);

	const [professionInfoData, setProfessionInfoData] = useState({
		work_type: '',
		organization: '',
		designation: '',
		location: ''
	});

	const [familyInfoData, setFamilyInfoData] = useState<IFamilyInfoData[]>([
		{ name: '', relation: '', birthdate: null, blood_group: '', occupation: '' }
	]);

	const [memberShipStatus, setMemberShipStatus] = useState<IMemberShipStatus>({
		life_time_member: false,
		membership_date: null
	});

	const [isFamilyDetailsShow, setIsFamilyDetailsShow] = useState(false);
	const [isMembershipStatusShow, setIsMembershipStatusShow] = useState(false);
	const [isTeacher, setTeacher] = useState(false);

	const fetchAlumniDetails = () => {
		setLoading(true);
		HttpService.get(`${API_CONFIG.path.profile}/${alumniId}`)
			.then((response) => {
				const {
					address,
					education,
					profession,
					family,
					membership_date,
					life_time_member,
					profile_photo,
					first_name,
					last_name,
					birthdate,
					country_code,
					contact_number,
					email,
					blood_group,
					admission_year,
					passing_year,
					house,
					alternate_contact,
					role
				} = response;
				setPersonalInfoData({
					profile_photo,
					first_name,
					last_name,
					birthdate,
					country_code,
					contact_number,
					email,
					blood_group,
					admission_year,
					passing_year,
					house,
					alternate_contact
				});
				setAddressInfoData({
					address: address.address,
					current_zipcode: address.current_zipcode,
					current_city: address.current_city,
					current_state: address.current_state,
					current_country: address.current_country,
					current_latitude: address.current_latitude,
					current_longitude: address.current_longitude,
					location: address.location
				});
				const updatedEducation = education.map(({ id, ...rest }: IEducationInfoData) => ({ ...rest }));
				setEducationInfoData(updatedEducation);
				setProfessionInfoData({
					work_type: profession.work_type,
					organization: profession.organization,
					designation: profession.designation,
					location: profession.location
				});
				const updatedFamilyInfo = family.map(({ id, ...rest }: IFamilyInfoData) => ({
					...rest,
					birthdate: moment(rest.birthdate).format('DD-MM-YYYY')
				}));
				setFamilyInfoData(updatedFamilyInfo);
				setMemberShipStatus({
					membership_date,
					life_time_member
				});
				setTeacher(ROLES.TEACHER === role);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			});
	};

	const profileDetails = {
		profilePic: personalInfoData.profile_photo,
		fullName: `${personalInfoData.first_name} ${personalInfoData.last_name ?? ''}`
	};

	let PersonalInfoMapper: IPersonalInfoMapper = {
		Mail: personalInfoData.email,
		Phone: `${personalInfoData.country_code} ${personalInfoData.contact_number}`,
		Birthdate: moment(personalInfoData.birthdate).format('DD-MM-YYYY'),
		'Blood Group': personalInfoData.blood_group
	};

	if (!isTeacher) {
		PersonalInfoMapper = {
			...PersonalInfoMapper,
			School: `${personalInfoData.admission_year} - ${personalInfoData.passing_year}` as string,
			House: personalInfoData.house as string[]
		};
	}

	const AddressInfoMapper = {
		Address: addressInfoData.address,
		'Zip Code': addressInfoData.current_zipcode,
		City: addressInfoData.current_city,
		State: addressInfoData.current_state,
		Country: addressInfoData.current_country,
		Location: addressInfoData.location
	};

	const ProfessionInfoMapper = {
		'Work Type': professionInfoData.work_type,
		Organization: professionInfoData.organization,
		Designation: professionInfoData.designation,
		'Work Location City': professionInfoData.location
	};

	let MemberShipInfoMapper: IClassObj = {
		Member: memberShipStatus.life_time_member ? 'Yes - Life Long member' : 'No'
	};

	if (memberShipStatus.membership_date) {
		MemberShipInfoMapper = {
			...MemberShipInfoMapper,
			Since: memberShipStatus.membership_date
		};
	}

	const viewAlumniMapper = [
		{
			title: PersonalInfo,
			infoMapper: PersonalInfoMapper,
			className: 'order--1'
		},
		{
			title: AddressInfo,
			infoMapper: AddressInfoMapper,
			className: 'order--2'
		},
		{
			title: ProfessionInfo,
			infoMapper: ProfessionInfoMapper,
			className: 'order--4'
		},
		{
			title: MembershipStatus,
			infoMapper: MemberShipInfoMapper,
			className: 'order--6'
		}
	];

	const changeLabels = (infoData: any, labelMapper: any) => {
		return infoData.map((obj: any) => {
			const newObj: any = {};
			for (const [key, value] of Object.entries(obj)) {
				const newLabel = labelMapper[key] || key;
				newObj[newLabel] = value;
			}
			return newObj;
		});
	};

	const modifiedEducationArray = changeLabels(educationInfoData, EducationLabelMapper);
	const modifiedFamilyArray = changeLabels(familyInfoData, FamilyLabelMapper);

	const viewAlumniArrayMapper = [
		{
			title: EducationInfo,
			infoMapper: modifiedEducationArray,
			className: 'order--3'
		},
		{
			title: FamilyDetails,
			infoMapper: modifiedFamilyArray,
			className: 'order--5'
		}
	];

	useEffect(() => {
		fetchAlumniDetails();
	}, []);

	return (
		<div>
			<div className='profile-details-header-wrapper'>
				<div className='details-header flex align-items--center justify-content--between bg--white'>
					<div>
						<p className='font-size--sm font--bold'>Profile Details</p>
						<p className='font-size--xxs font--regular'>
							You can edit your details in-case you missed out a field.
						</p>
					</div>
					<CustomButton
						buttonTitle='Edit'
						btnClassName='primary-button edit-button'
						ButtonIcon={EditIcon}
						onButtonClick={() => navigate('/profile', { state: { isEdit: true } })}
					/>
				</div>
			</div>
			<div className='profile-accordion-wrapper pl--15 pr--15'>
				<div className='accordion-container'>
					<div
						className='vertical-stepper-line'
						style={{
							height: `calc(100% - ${
								isFamilyDetailsShow ? '166px' : isMembershipStatusShow ? '130px' : '46px'
							})`
						}}
					/>
					{!loading && (
						<div className='flex flex--column'>
							{viewAlumniMapper.map(({ title, infoMapper, className }, index) => (
								<ViewAlumniCard
									title={title}
									infoMapper={infoMapper}
									key={index}
									isArr={false}
									profileDetails={profileDetails}
									setIsFamilyDetailsShow={setIsFamilyDetailsShow}
									setIsMembershipStatusShow={setIsMembershipStatusShow}
									className={className}
								/>
							))}
							{viewAlumniArrayMapper.map(({ title, infoMapper, className }, index) => (
								<ViewAlumniCard
									title={title}
									infoMapper={infoMapper}
									key={index}
									isArr={true}
									className={className}
								/>
							))}
						</div>
					)}
				</div>
			</div>
			<ScaletechFooter className='alumni-details-footer-wrapper' />
		</div>
	);
};

export default AlumniDetails;
