import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RightArrowIcon } from 'shared/components/icons/icons';
import { notify } from 'shared/components/notification/notification';
import { Loader } from 'shared/components/spinner/spinner';
import { API_CONFIG } from 'shared/constants/api';
import { SETTING_DETAILS } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';
import { UserDetailsContext } from 'store/context/userDetailStore';
import LogoutPopup from '../components/logoutPopup';

const Setting: FC = () => {
	const navigate = useNavigate();

	const { profileData, profileLoading, setProfileData } = useContext(UserDetailsContext);
	const [isNotify, setNotify] = useState(profileData.generic_notification);

	useEffect(() => {
		setNotify(profileData.generic_notification);
	}, [profileData.generic_notification]);

	const [isLogoutPopup, setIsLogoutPopup] = useState(false);

	const handleToggle = useCallback(async () => {
		try {
			await HttpService.put(`${API_CONFIG.path.notificationSetting}`, {
				generic_notification: !isNotify
			});
			notify('Notification settings changed successfully.', 'success');
			setNotify(!isNotify);
			setProfileData((data) => ({ ...data, generic_notification: !isNotify }));
		} catch (error) {
			console.error(error);
		}
	}, [isNotify, setNotify]);

	return (
		<>
			{profileLoading && (
				<div className='pt--40'>
					<Loader />
				</div>
			)}
			{!profileLoading &&
				SETTING_DETAILS.map(({ title, href, isToggle }, index) => (
					<div
						key={index}
						className={`settings-wrapper ${!href && !isToggle ? 'cursor--pointer' : ''}`}
						onClick={() => {
							!href && !isToggle && setIsLogoutPopup(true);
							href && navigate(href);
						}}
					>
						<div
							className={`flex justify-content--between align-items--center width--full ${
								!isToggle ? 'cursor--pointer' : ''
							}`}
						>
							<p>{title}</p>
							{href && <RightArrowIcon />}
						</div>
						{isToggle && (
							<div>
								<input
									className='toggle-field'
									type='checkbox'
									id='switch'
									checked={isNotify}
									onChange={handleToggle}
								/>
								<label className='toggle-label' htmlFor='switch' />
							</div>
						)}
					</div>
				))}
			{isLogoutPopup && <LogoutPopup onClose={() => setIsLogoutPopup(false)} />}
		</>
	);
};

export default Setting;
