import { ConfettiIcon, OtherUpdateIcon, RelocationIcon, SadIcon } from 'shared/components/icons/icons';

const TYPE_IMGS = {
	'Sad News': SadIcon,
	'Good News': ConfettiIcon,
	Relocation: RelocationIcon,
	Other: OtherUpdateIcon
};

const TYPE_TITLES_COLORS = {
	'Sad News': '#c3000033',
	'Good News': '#79c3004d',
	Relocation: '#00a3d733',
	Other: '#e9eff3'
};

export { TYPE_IMGS, TYPE_TITLES_COLORS };
