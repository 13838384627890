export interface IAuthState {
	isLogin: boolean;
	userData: IUserData;
	isSignUpRoute: boolean;
}

export interface ILoginFormValues {
	roll_number?: number | string;
	contact_number: string;
	country_code: string;
}

export interface ILoginParams extends ILoginFormValues {
	role: string;
}

export interface ILoginWithTokenParams extends ILoginParams {
	device_token: string;
	device_type: string;
	subscription_obj: any;
	device_id: string;
}

export enum ROLES {
	ALUMNI = 'Alumni',
	TEACHER = 'Teacher',
	STAFF = 'Staff'
}

export interface IUserData {
	token: string;
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	roll_number: number;
	birthdate: string;
	blood_group: string;
	contact_number: string;
	country_code: string;
	admission_year: number;
	passing_year: number;
	yet_to_passout: boolean;
	house: string[];
	life_time_member: boolean;
	membership_date: string | null;
	created_at: number;
	address: Address;
	profession: Profession;
	family: Family[];
	education: Education[];
	step: string;
	profile_photo: string;
	role: string;
	year_of_joining: string | null;
	year_of_leaving: string | null;
}

export interface Address {
	id: string;
	current_house_name: string | null;
	current_street_name: string | null;
	current_landmark: string | null;
	current_zipcode: string;
	current_country: string;
	current_state: string;
	current_city: string;
	current_latitude: string;
	current_longitude: string;
	permanent_house_name: string | null;
	permanent_street_name: string | null;
	permanent_landmark: string | null;
	permanent_zipcode: number | null;
	permanent_country: string | null;
	permanent_state: string | null;
	permanent_city: string | null;
	permanent_latitude: number | null;
	permanent_longitude: number | null;
	address: string;
	location: string;
}

export interface Profession {
	id: string;
	work_type: string;
	organization: string;
	designation: string;
	location: string;
}

export interface Family {
	id: string;
	name: string;
	birthdate: string;
	occupation: string;
	relation: string;
	blood_group: string;
}

export interface Education {
	id: string;
	field: string;
	institute_name: string;
	institute_passout_year: number;
}
