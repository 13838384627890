import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';
import moment from 'moment';
import { Range } from 'react-date-range';

import { IAction, IState } from 'shared/interface/state';
import HttpService from 'shared/services/http.service';
import { IPresignUrlResponse } from 'features/profile/interface/profile';
import { IDropdownOptions, IPreSignedData } from 'shared/interface';
import { logFirebaseEvent } from 'shared/firebase/firebase';
import AuthService from 'shared/services/auth.service';

/**
 * create action creator
 * @param ACTION - type of action
 * @param data - data
 */
export const createAction = (ACTION: string, data: any = null): IAction => ({
	type: ACTION,
	payload: data
});

/**
 * create loading selector
 * @param actions - actions to dispatch
 */
export const createLoadingSelector = (actions: string[]) => (state: IState) =>
	// returns true only when all actions is not loading
	_(actions).some((action: string) => _.get(state, `loading.api.${action}`));

/**
 * dispatch action after given time (to handle some events like close modal after success api call)
 * @param dispatch - dispatch object
 * @param action - action type
 * @param time - time after which action is to be dispatched (default - 100ms)
 */
export const dispatchActionAfterTime = (
	dispatch: ThunkDispatch<unknown, unknown, IAction>,
	action: string,
	time = 100
) => {
	setTimeout(() => {
		dispatch(createAction(action));
	}, time);
};

export const formatDate = (date: string | number | Date | undefined, format = 'MM-DD-YYYY') => {
	if (!date) {
		return '';
	}
	return moment(date).format(format);
};

export const debounce = (func: any, wait = 1000) => {
	// eslint-disable-next-line no-undef
	let h: NodeJS.Timeout;
	return (...args: any) => {
		clearTimeout(h);
		h = setTimeout(() => func(...args), wait);
	};
};

export const prependZero = (number: number) => {
	if (number <= 9) return '00000' + number;
	else if (number <= 99) return '0000' + number;
	else if (number <= 999) return '000' + number;
	else if (number <= 9999) return '00' + number;
	else if (number <= 99999) return '0' + number;
	else return number;
};

export const downloadFile = async (url: string, fileType: string) => {
	try {
		const pdfData = await HttpService.get(url, {}, { responseType: 'blob', fullResponse: true });
		const file = new Blob([pdfData], { type: fileType });
		const data = window.URL.createObjectURL(file);
		const link = document.createElement('a');
		link.href = data;
		link.target = '_blank';
		link.click();
	} catch (err) {
		console.error(err);
	}
};

export const isValidURL = (str: string) => {
	const pattern = new RegExp(
		'^(https?:\\/\\/)?' +
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
			'((\\d{1,3}\\.){3}\\d{1,3}))' +
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
			'(\\?[;&a-z\\d%_.~+=-]*)?' +
			'(\\#[-a-z\\d_]*)?$',
		'i'
	);
	return !!pattern.test(str);
};

export const rawMarkup = (answer: any) => {
	const rawMarkups = answer;
	return { __html: rawMarkups };
};

export const convertDropdownValueToArray = (
	selectedValue: string | string[]
): Array<{ label: string; value: string }> => {
	const values = typeof selectedValue === 'string' ? selectedValue.split(',') : selectedValue;
	const data = [];
	if (values) {
		for (const value of values) {
			const temp = {
				label: value,
				value: value
			};
			data.push(temp);
		}
	}
	return data;
};

export const getDropdownValues = (dropdown: IDropdownOptions[]) => {
	return dropdown.map((d: IDropdownOptions) => d.value);
};

export const getDropdownOptions = (values: string[]) => {
	return values.map((value) => ({ label: value, value: value }));
};

export const convertDropdownValueToString = (values: IDropdownOptions[]): string[] => {
	return values.map((item) => item.value);
};

export const formatDateRange = (dateRange: Range) => {
	let startDate;
	let endDate;
	if (dateRange.startDate) {
		startDate = moment(dateRange.startDate).format();
	}
	if (dateRange.endDate) {
		endDate = moment(dateRange.endDate).format();
	}

	return { startDate: startDate, endDate: endDate };
};

export const generateRandomString = (length: number) => {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let randomString = '';
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		randomString += characters.charAt(randomIndex);
	}
	return randomString;
};

export const getAdvanceDate = () => {
	const date = new Date().toISOString();
	const getNextDate = moment(date).add(1, 'day').format('MM-DD-YYYY');
	return getNextDate;
};

export const convertToObjects = (countryCodesArray: string[]) => {
	return countryCodesArray.map((countryEntry: string, index) => {
		const [countryCode, _] = countryEntry.split(' ');
		return { label: countryCode, value: countryCode, key: index };
	});
};

export const getMobileOperatingSystem = () => {
	const userAgent = navigator.userAgent || window.opera;

	// android device detection
	if (/android/i.test(userAgent)) {
		return 'android';
	}

	// iOS device detection
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return 'ios';
	}

	return 'web';
};

export const handleRollNumber = (e: {
	key: string;
	target: { selectionStart: number | null; value: string | any[] };
	preventDefault: () => void;
}) => {
	if (
		([' ', '+', '-', '.'].includes(e.key) && (e.target.selectionStart === 0 || e.target.selectionStart === null)) ||
		(e.key === '0' && e.target.value.length === 0)
	) {
		e.preventDefault();
	}
};

export const isMobile = (): boolean =>
	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

//for upload file into s3 bucket
export const uploadImageToS3PreSigned = (presignedPostData: IPresignUrlResponse, file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();

		xhr.open('PUT', presignedPostData.upload_url, true);
		xhr.setRequestHeader('Content-Type', 'image/*');
		xhr.send(file);
		xhr.onload = function () {
			if (this.status === 200) {
				resolve(presignedPostData.key);
			} else {
				reject(this.responseText);
			}
		};
	});
};

export const isIOSDevice = (): boolean =>
	/iPhone|iPad|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/i.test(navigator.userAgent);

export const removeIfEmpty = (payload: any, formDataKey: string, payloadKey: string) => {
	if (Object.prototype.hasOwnProperty.call(payload, payloadKey)) {
		_.isEmpty(formDataKey) && delete payload[payloadKey];
	}
};

export const scrollToTop = () => {
	window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

//for upload file into s3 bucket
export const uploadFileToS3PreSigned = (presignedPostData: IPreSignedData, file: File): Promise<string> => {
	return new Promise(async (resolve, reject) => {
		const headers: any = {
			'Content-Type': file.type || '*/*'
		};

		const requestOptions = {
			method: 'PUT',
			headers: headers,
			body: file
		};

		try {
			const response = await fetch(presignedPostData.upload_url, requestOptions);

			if (response.ok) {
				resolve(presignedPostData.key);
			} else {
				reject(new Error(`Failed to upload file to S3. Status: ${response.status}`));
			}
		} catch (error) {
			reject(error);
		}
	});
};

//for capitalize Words
export const capitalizeWords = (sentence: string) => {
	return sentence.replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
};

export const logEvent = (name: string, data: object) => {
	if (window.location.origin !== 'https://obssa-app.scaletech.xyz') {
		return;
	}

	const authData = AuthService.getAuthData();
	logFirebaseEvent(name, {
		timestamp: Date.now(),
		device_os: getMobileOperatingSystem(),
		rollNumber: authData?.roll_number || '-',
		mobileNo: authData?.contact_number || '-',
		...data
	});
};
