import { FC, useEffect } from 'react';

interface IOptionCard {
	optionList: string[];
	selectedOptions: string | string[];
	name: string;
	isMultiple: boolean;
	setFieldValue: (name: string, option: string | string[]) => void;
	tabWidth?: string;
	handleOptionSelection?: (option: string) => void;
}

const OptionCard: FC<IOptionCard> = ({
	optionList,
	selectedOptions,
	name,
	handleOptionSelection,
	setFieldValue,
	isMultiple,
	tabWidth
}) => {
	useEffect(() => {
		isMultiple && setFieldValue(name, selectedOptions);
	}, [setFieldValue, name, selectedOptions]);

	return (
		<div className='tab-option-container flex flex--wrap'>
			{optionList.map((option, index: number) => {
				const isSelected = Array.isArray(selectedOptions)
					? selectedOptions.includes(option)
					: selectedOptions === option;
				return (
					<div
						key={index}
						className={`tab-option ${tabWidth || 'small-tab'} ${
							isSelected ? 'tab-option--selected' : ''
						} cursor--pointer`}
						onClick={() => {
							if (isMultiple && handleOptionSelection) {
								handleOptionSelection(option);
								setFieldValue(name, selectedOptions);
							} else {
								setFieldValue(name, option);
							}
						}}
					>
						<p className='tab-title'>{option}</p>
					</div>
				);
			})}
		</div>
	);
};

export default OptionCard;
