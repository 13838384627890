import React, { PropsWithChildren, createContext, useEffect, useState, useMemo } from 'react';

import { API_CONFIG } from 'shared/constants/api';
import HttpService from 'shared/services/http.service';
import { IAlumniItem } from 'features/nearByMap/interface/nearByMap';
import { useGetUserLocation } from 'shared/hooks/useGetUserLocation';
import {
	IBatchMemory,
	IBirthdayListProps,
	IFeaturedMemory,
	IOtherNotificationList
} from 'features/dashboard/interface/dashboard';

const UserDashboardDetailsStore: React.FC<PropsWithChildren> = ({ children }) => {
	const [birthdayLoading, setBirthdayLoading] = useState(true);
	const [birthdayList, setBirthdayList] = useState<IBirthdayListProps[]>([]);
	const [otherNotificationList, setOtherNotificationList] = useState<IOtherNotificationList[]>([]);

	const [batchMemoryLoading, setBatchMemoryLoading] = useState(true);
	const [batchMemoryList, setBatchMemoryList] = useState<IBatchMemory[]>([]);

	const [featuredMemoryLoading, setFeaturedMemoryLoading] = useState(true);
	const [featuredMemoryList, setFeaturedMemoryList] = useState<IFeaturedMemory[]>([]);

	const [nearByListLoading, setNearByListLoading] = useState(false);
	const [nearByList, setNearByList] = useState([]);
	const [totalAlumni, setTotalAlumni] = useState(0);

	const userLocation = useGetUserLocation();

	const fetchNearByAlumni = async (showLoader = true, location: { lat: number; lng: number }) => {
		if (showLoader) {
			setNearByListLoading(true);
		}
		try {
			const alumniList = await HttpService.get(`${API_CONFIG.path.nearByAlumni}`, {
				radius: 5,
				lat: location.lat.toString(),
				lng: location.lng.toString(),
				record_per_page: 30
			});

			if (alumniList && alumniList.data) {
				const list = alumniList.data.map((i: IAlumniItem) => ({
					key: i.id,
					name: `${i.first_name} ${i.last_name}`,
					image: i.thumbnail_photo || i.profile_photo
				}));
				setNearByList(list);
				setTotalAlumni(alumniList.total);
			}
			setNearByListLoading(false);
		} catch (error) {
			console.error(error);
			setNearByListLoading(false);
		}
	};

	const fetchAlumniUpdates = async (showLoader = true) => {
		if (showLoader) {
			setBirthdayLoading(true);
		}
		try {
			const response = await HttpService.get(API_CONFIG.path.birthdayList);
			const { birthdayList, otherNotificationList } = response;
			setOtherNotificationList(otherNotificationList);
			setBirthdayList(birthdayList);
			setBirthdayLoading(false);
		} catch (error) {
			console.error(error);
			setBirthdayLoading(false);
		}
	};

	const fetchBatchMemory = async (showLoader = true) => {
		if (showLoader) {
			setBatchMemoryLoading(true);
		}
		try {
			const batchMemoryList = await HttpService.get(`${API_CONFIG.path.batchMemory}`);

			if (batchMemoryList && batchMemoryList.data) {
				setBatchMemoryList(batchMemoryList.data);
			}
			setBatchMemoryLoading(false);
		} catch (error) {
			console.error(error);
			setBatchMemoryLoading(false);
		}
	};

	const fetchFeaturedMemory = async () => {
		setFeaturedMemoryLoading(true);
		try {
			const featuredMemory = await HttpService.get(`${API_CONFIG.path.featuredMemory}`);

			if (featuredMemory && featuredMemory.data) {
				setFeaturedMemoryList(featuredMemory.data);
			}
			setFeaturedMemoryLoading(false);
		} catch (error) {
			console.error(error);
			setFeaturedMemoryLoading(false);
		}
	};

	useEffect(() => {
		fetchAlumniUpdates();
		fetchFeaturedMemory();
		fetchBatchMemory();
	}, []);

	useEffect(() => {
		if (userLocation) {
			fetchNearByAlumni(undefined, userLocation);
		}
	}, [userLocation]);

	const contextData = useMemo(
		() => ({
			batchMemoryLoading,
			batchMemoryList,
			fetchBatchMemory,
			featuredMemoryLoading,
			featuredMemoryList,
			fetchFeaturedMemory,
			birthdayLoading,
			birthdayList,
			otherNotificationList,
			fetchAlumniUpdates,
			nearByListLoading,
			nearByList,
			fetchNearByAlumni,
			totalAlumni
		}),
		[
			batchMemoryLoading,
			batchMemoryList,
			fetchBatchMemory,
			featuredMemoryLoading,
			featuredMemoryList,
			fetchFeaturedMemory,
			birthdayLoading,
			birthdayList,
			otherNotificationList,
			fetchAlumniUpdates,
			nearByListLoading,
			nearByList,
			fetchNearByAlumni,
			totalAlumni
		]
	);

	return <DashboardDetailsContext.Provider value={contextData}>{children}</DashboardDetailsContext.Provider>;
};

interface ContextType {
	batchMemoryLoading: boolean;
	batchMemoryList: IBatchMemory[];
	fetchBatchMemory: (showLoader: boolean) => void;
	featuredMemoryLoading: boolean;
	featuredMemoryList: IFeaturedMemory[];
	fetchFeaturedMemory: (showLoader: boolean) => void;
	birthdayLoading: boolean;
	birthdayList: IBirthdayListProps[];
	otherNotificationList: IOtherNotificationList[];
	fetchAlumniUpdates: (showLoader: boolean) => void;
	nearByListLoading: boolean;
	nearByList: { key: string; name: string; image: string }[];
	fetchNearByAlumni: (showLoader: boolean, location: { lat: number; lng: number }) => void;
	totalAlumni: number;
}

export const DashboardDetailsContext = createContext({} as ContextType);
export default UserDashboardDetailsStore;
