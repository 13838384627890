import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ScaletechFooter from 'shared/components/footer/scaletechFooter';

import OBSSALogo from 'assets/images/logo.png';

const Splash: FC = () => {
	const navigate = useNavigate();
	useEffect(() => {
		setTimeout(() => {
			navigate('/login');
		}, 3000);
	}, []);

	return (
		<div className='splash-screen-wrapper'>
			<div className='flex--column justify-align--center'>
				<p className='font--bold pt--20 obssa-title font-size--22'>
					Old Boys of Balachadi Sainik School Association
				</p>
				<img className='obssa-logo' src={OBSSALogo} alt='OBSSA-logo' />
				<h1 className='pb--75 obssa-sub-title'>OBSSA</h1>
			</div>
			<ScaletechFooter className='splash-footer-container' />
			<div />
		</div>
	);
};

export default Splash;
