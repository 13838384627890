import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BackIcon, BellIcon, SettingIcon } from 'shared/components/icons/icons';
import { HIDE_NOTIFICATION_OPT, MAIN_TABS, PATHNAME, PROFILE_PAGE } from 'shared/constants/constants';

import logo from 'assets/images/logo.png';

const Header: FC = () => {
	const { pathname } = useLocation();

	const navigate = useNavigate();

	const updatedPathName = PATHNAME[pathname as keyof typeof PATHNAME];
	const isMainTabs = MAIN_TABS.includes(pathname);
	const isHomeTab = pathname === '/home';
	const isHideNotification = useMemo(() => !HIDE_NOTIFICATION_OPT.includes(pathname), [pathname]);

	return (
		<header className='header-wrapper'>
			<nav className='navbar flex align-items--center justify-content--between height--full width--full'>
				{(isMainTabs || isHomeTab) && (
					<div className='flex align-items--center height--full'>
						{isMainTabs && (
							<div
								className='logo'
								onClick={() => {
									if (isHomeTab) {
										return;
									}
									navigate('/', { replace: true });
								}}
							>
								<img src={logo} alt='logo' className='width--full ' />
							</div>
						)}
						{/* {isHomeTab && <p className='font-family--lato font--bold font-size--xxl'>OBSSA</p>} */}
					</div>
				)}
				{!isMainTabs && (
					<div
						className='header-icon-wrapper'
						onClick={() => (pathname === '/all-memories' ? navigate('/home') : navigate(-1))}
					>
						<BackIcon />
					</div>
				)}
				<p className='font--bold font-size--xxl'>{updatedPathName}</p>
				<div className={`flex ${!isHideNotification ? 'width--40px' : ''}`}>
					{PROFILE_PAGE === updatedPathName && (
						<div className='header-icon-wrapper mr--10' onClick={() => navigate('/settings')}>
							<SettingIcon />
						</div>
					)}
					{isHideNotification && (
						<div className='header-icon-wrapper' onClick={() => navigate(`${pathname}?notification=true`)}>
							<BellIcon />
						</div>
					)}
				</div>
			</nav>
		</header>
	);
};

export default Header;
