import { FC } from 'react';
interface IProfileHeader {
	title: string;
	details: string;
}

const ProfileHeader: FC<IProfileHeader> = ({ title, details }) => {
	return (
		<div className='details-wrapper profile-header-container flex justify-content--between'>
			<div className='step-form-header-wrapper'>
				<p className='font--bold'>{title}</p>
				<p className='font-size--xs font--regular mt--4 opacity--70'>{details}</p>
			</div>
		</div>
	);
};

export default ProfileHeader;
