import { FC, useRef, useEffect } from 'react';
import { ErrorMessage, FieldArray, Form, Formik, FormikValues } from 'formik';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import isEmpty from 'lodash/isEmpty';

import { notify } from 'shared/components/notification/notification';
import { educationInfoValidationSchema } from 'shared/constants/validation-schema';
import { CustomCalendarIcon, DeleteIcon, PlusIcon } from 'shared/components/icons/icons';
import CustomButton from 'shared/components/customButton/customButton';
import Input from 'shared/form/input';

import { IClassObj, IEducationDetails } from '../interface/profile';
import { EDUCATION_INFO_DETAILS, TODAY } from '../constants/profile';

import 'react-datepicker/dist/react-datepicker.css';

interface IEducationDetailsProps {
	isCurrentStepChange: boolean;
	isSaveDetails: boolean;
	educationDetails: IEducationDetails[];
	handleSaveClick: (personalDetails: IProfessionValues) => void;
	setUpdatedAlumniDetails: (personalDetails: IProfessionValues) => void;
	handleResetSaveClick: () => void;
}

interface IProfessionValues {
	education: IEducationDetails[];
}

const EducationDetails: FC<IEducationDetailsProps> = ({
	isCurrentStepChange,
	educationDetails,
	isSaveDetails,
	handleSaveClick,
	setUpdatedAlumniDetails,
	handleResetSaveClick
}) => {
	const formRef: any = useRef();

	const isEmptyEducationDetails = () => {
		const otherEducationDetails = formRef.current?.values?.education || [];

		// Check if any alternate contact number has empty values
		const isEmptyStringPresent = otherEducationDetails.some((educationDetails: IClassObj) =>
			Object.values(educationDetails).some((value) => value === '')
		);

		return isEmptyStringPresent;
	};

	const handleSaveEducationDetails = (isSaveDetail: boolean) => {
		if (formRef.current) {
			const errorObj = formRef.current.errors;
			if (isEmpty(errorObj)) {
				isSaveDetail
					? handleSaveClick({ education: formRef.current.values.education })
					: setUpdatedAlumniDetails({ education: formRef?.current?.values.education });
			} else {
				notify(Object.values(errorObj?.education.filter(Boolean)[0])[0] as string, 'error');
				handleResetSaveClick();
			}
		}
	};

	useEffect(() => {
		isSaveDetails && handleSaveEducationDetails(true);
	}, [isSaveDetails]);

	useEffect(() => {
		isCurrentStepChange && handleSaveEducationDetails(false);
	}, [isCurrentStepChange]);

	return (
		<div className='edit-profile-container'>
			<Formik
				innerRef={formRef}
				initialValues={{
					education: educationDetails.map(({ institute_name, field, institute_passout_year }) => ({
						institute_name,
						field,
						institute_passout_year
					}))
				}}
				onSubmit={() => {}}
				validationSchema={educationInfoValidationSchema}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ errors, setFieldValue, values, touched }: FormikValues) => (
					<Form className='form'>
						<div className='profile-container'>
							<FieldArray name='education'>
								{({ push, remove }) => (
									<div>
										{values.education.length > 0 &&
											values.education.map((instituteInfo: string, index: number) => (
												<div key={index} className='institute-container'>
													<div className='flex justify-content--between align-content--center pb--20'>
														<p className='font-size--lg font--semi-bold'>
															Education History {index + 1}
														</p>
														{index > 0 && (
															<div
																className='delete-icon-wrapper cursor--pointer'
																onClick={() => remove(index)}
															>
																<DeleteIcon />
															</div>
														)}
													</div>
													{Object.entries(instituteInfo).map(
														([info, _], fieldIndex: number) => {
															const { label, name, placeHolder, isRequired, type, note } =
																EDUCATION_INFO_DETAILS[info];
															const fieldError =
																errors.education &&
																errors.education[index] &&
																errors.education[index][name];
															const fieldTouched =
																touched.education &&
																touched.education[index] &&
																touched.education[index][name];
															return (
																<div
																	key={fieldIndex}
																	className='details-input-wrapper mb--15'
																>
																	<label className='font-size--sm opacity--50'>
																		<p>
																			{label}
																			{isRequired ? (
																				<span className='text--red'> *</span>
																			) : (
																				<span className='font-size--sm font--regular opacity--30 text--secondary'>
																					(optional)
																				</span>
																			)}
																		</p>
																	</label>
																	<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
																		{note}
																	</span>
																	<div className='mt--12'>
																		{name === 'institute_passout_year' ? (
																			<ReactDatePicker
																				icon={CustomCalendarIcon}
																				name={`education[${index}].${name}`}
																				showIcon
																				calendarIconClassname='calendar-icon cursor--pointer'
																				className='form-input calendar-input'
																				placeholderText='YYYY'
																				selected={
																					values.education[index][name]
																						? new Date(
																								values.education[index][
																									name
																								],
																								0,
																								1
																						  )
																						: null
																				}
																				maxDate={TODAY}
																				showYearPicker
																				dateFormat='yyyy'
																				onChange={(date: Date) =>
																					setFieldValue(
																						`education[${index}].${name}`,
																						moment(date).year()
																					)
																				}
																				disabledKeyboardNavigation
																				shouldCloseOnSelect
																			/>
																		) : (
																			<Input
																				name={`education[${index}].${name}`}
																				type={type}
																				data-testid={name}
																				placeholder={placeHolder}
																				classes={`${
																					name === 'institute_passout_year'
																						? 'calendar-input'
																						: ''
																				} ${
																					fieldError && fieldTouched
																						? 'form-input--error'
																						: ''
																				}`}
																				value={values.education[index][name]}
																				onChange={({ target: { value } }) =>
																					setFieldValue(
																						`education[${index}].${name}`,
																						value.indexOf(' ') === 0 ||
																							value.trim().length <= 0
																							? ''
																							: value
																					)
																				}
																			/>
																		)}
																	</div>
																	<ErrorMessage
																		name={`education[${index}].${name}`}
																		component='p'
																		className='error'
																	/>
																</div>
															);
														}
													)}
												</div>
											))}
										<div className='justify-align--center mb--30 width--full'>
											<CustomButton
												disabled={isEmptyEducationDetails()}
												buttonType='button'
												buttonTitle='Add New Education'
												btnClassName='secondary-button add-education-button'
												ButtonIcon={PlusIcon}
												onButtonClick={() =>
													push({
														institute_name: '',
														field: '',
														institute_passout_year: ''
													})
												}
											/>
										</div>
									</div>
								)}
							</FieldArray>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default EducationDetails;
