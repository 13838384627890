export const countryCodes: string[] = [
	'+93 Afghanistan',
	'+355 Albania',
	'+213 Algeria',
	'+1684 American Samoa',
	'+376 Andorra',
	'+244 Angola',
	'+1264 Anguilla',
	'+672 Antarctica',
	'+1268 Antigua & Barbuda',
	'+54 Argentina',
	'+374 Armenia',
	'+297 Aruba',
	'+61 Australia',
	'+43 Austria',
	'+994 Azerbaijan',
	'+1242 Bahamas',
	'+973 Bahrain',
	'+880 Bangladesh',
	'+1246 Barbados',
	'+375 Belarus',
	'+32 Belgium',
	'+501 Belize',
	'+229 Benin',
	'+1441 Bermuda',
	'+975 Bhutan',
	'+591 Bolivia',
	'+387 Bosnia & Herzegovina',
	'+267 Botswana',
	'+55 Bouvet Island',
	'+55 Brazil',
	'+1284 British Virgin Islands',
	'+673 Brunei',
	'+359 Bulgaria',
	'+226 Burkina Faso',
	'+257 Burundi',
	'+855 Cambodia',
	'+237 Cameroon',
	'+1 Canada',
	'+238 Cape Verde',
	'+599 Caribbean Netherlands',
	'+1345 Cayman Islands',
	'+236 Central African Republic',
	'+235 Chad',
	'+246 Chagos Archipelago',
	'+56 Chile',
	'+86 China mainland',
	'+61 Christmas Island',
	'+61 Cocos (Keeling) Islands',
	'+57 Colombia',
	'+269 Comoros',
	'+242 Congo - Brazzaville',
	'+243 Kinshasa',
	'+682 Cook Islands',
	'+506 Costa Rica',
	'+385 Croatia',
	'+53 Cuba',
	'+599 Curaçao',
	'+357 Cyprus',
	'+420 Czechia',
	'+225 Côte d’Ivoire',
	'+45 Denmark',
	'+253 Djibouti',
	'+1767 Dominica',
	'+1 Dominican Republic',
	'+593 Ecuador',
	'+20 Egypt',
	'+503 El Salvador',
	'+240 Equatorial Guinea',
	'+291 Eritrea',
	'+372 Estonia',
	'+268 Eswatini',
	'+251 Ethiopia',
	'+500 Falkland Islands',
	'+298 Faroe Islands',
	'+679 Fiji',
	'+358 Finland',
	'+33 France',
	'+594 French Guiana',
	'+689 French Polynesia',
	'+262 French Southern Territories',
	'+49 Germany',
	'+241 Gabon',
	'+220 Gambia',
	'+995 Georgia',
	'+233 Ghana',
	'+350 Gibraltar',
	'+30 Greece',
	'+299 Greenland',
	'+1473 Grenada',
	'+590 Guadeloupe',
	'+1671 Guam',
	'+502 Guatemala',
	'+44 Guernsey',
	'+224 Guinea',
	'+245 Guinea-Bissau',
	'+592 Guyana',
	'+509 Haiti',
	'+334 Heard & McDonald Islands',
	'+504 Honduras',
	'+852 Hong Kong',
	'+36 Hungary',
	'+354 Iceland',
	'+91 India',
	'+62 Indonesia',
	'+98 Iran',
	'+964 Iraq',
	'+353 Ireland',
	'+44 Isle of Man',
	'+972 Israel',
	'+39 Italy',
	'+1876 Jamaica',
	'+81 Japan',
	'+44 Jersey',
	'+962 Jordan',
	'+7 Kazakhstan',
	'+254 Kenya',
	'+686 Kiribati',
	'+383 Kosovo',
	'+965 Kuwait',
	'+996 Kyrgyzstan',
	'+856 Laos',
	'+371 Latvia',
	'+961 Lebanon',
	'+266 Lesotho',
	'+231 Liberia',
	'+218 Libya',
	'+423 Liechtenstein',
	'+370 Lithuania',
	'+352 Luxembourg',
	'+853 Macao',
	'+261 Madagascar',
	'+265 Malawi',
	'+60 Malaysia',
	'+960 Maldives',
	'+223 Mali',
	'+356 Malta',
	'+692 Marshall Islands',
	'+596 Martinique',
	'+222 Mauritania',
	'+230 Mauritius',
	'+262 Mayotte',
	'+52 Mexico',
	'+691 Micronesia',
	'+373 Moldova',
	'+377 Monaco',
	'+976 Mongolia',
	'+382 Montenegro',
	'+1664 Montserrat',
	'+212 Morocco',
	'+258 Mozambique',
	'+95 Myanmar (Burma)',
	'+264 Namibia',
	'+674 Nauru',
	'+977 Nepal',
	'+31 Netherlands',
	'+687 New Caledonia',
	'+64 New Zealand',
	'+505 Nicaragua',
	'+227 Niger',
	'+234 Nigeria',
	'+683 Niue',
	'+672 Norfolk Island',
	'+850 North Korea',
	'+389 North Macedonia',
	'+1670 Northern Mariana Islands',
	'+47 Norway',
	'+968 Oman',
	'+92 Pakistan',
	'+680 Palau',
	'+970 Palestinian Territories',
	'+507 Panama',
	'+675 Papua New Guinea',
	'+595 Paraguay',
	'+51 Peru',
	'+63 Philippines',
	'+872 Pitcairn Islands',
	'+48 Poland',
	'+351 Portugal',
	'+1 Puerto Rico',
	'+974 Qatar',
	'+40 Romania',
	'+7 Russia',
	'+250 Rwanda',
	'+262 Réunion',
	'+685 Samoa',
	'+378 San Marino',
	'+966 Saudi Arabia',
	'+221 Senegal',
	'+381 Serbia',
	'+248 Seychelles',
	'+232 Sierra Leone',
	'+65 Singapore',
	'+1721 Sint Maarten',
	'+421 Slovakia',
	'+386 Slovenia',
	'+500 So. Georgia & So. Sandwich Isl.',
	'+677 Solomon Islands',
	'+252 Somalia',
	'+27 South Africa',
	'+82 South Korea',
	'+211 South Sudan',
	'+34 Spain',
	'+94 Sri Lanka',
	'+590 St. Barthélemy',
	'+290 St. Helena',
	'+1869 St. Kitts & Nevis',
	'+1758 St. Lucia',
	'+590 St. Martin',
	'+508 St. Pierre & Miquelon',
	'+1784 St. Vincent & Grenadines',
	'+249 Sudan',
	'+597 Suriname',
	'+47 Svalbard & Jan Mayen',
	'+46 Sweden',
	'+41 Switzerland',
	'+963 Syria',
	'+239 São Tomé & Príncipe',
	'+886 Taiwan',
	'+992 Tajikistan',
	'+255 Tanzania',
	'+66 Thailand',
	'+670 Timor-Leste',
	'+228 Togo',
	'+690 Tokelau',
	'+676 Tonga',
	'+1868 Trinidad & Tobago',
	'+216 Tunisia',
	'+993 Turkmenistan',
	'+1649 Turks & Caicos Islands',
	'+688 Tuvalu',
	'+90 Türkiye',
	'+1 U.S. Outlying Islands',
	'+1340 U.S. Virgin Islands',
	'+256 Uganda',
	'+380 Ukraine',
	'+971 United Arab Emirates',
	'+44 United Kingdom',
	'+1 United States',
	'+598 Uruguay',
	'+998 Uzbekistan',
	'+678 Vanuatu',
	'+379 Vatican City',
	'+58 Venezuela',
	'+84 Vietnam',
	'+681 Wallis & Futuna',
	'+212 Western Sahara',
	'+967 Yemen',
	'+260 Zambia',
	'+263 Zimbabwe',
	'+358 Åland Islands'
];
