import { FC, useCallback, useEffect, useState } from 'react';

import { MiniCloseIcon, SearchIcon } from 'shared/components/icons/icons';
import { debounce } from 'shared/util/utility';

interface IProps {
	onSearch: (text: string) => void;
}

const SearchField: FC<IProps> = ({ onSearch }) => {
	const [searchName, setSearchName] = useState('');

	const debounceSearch = useCallback(
		debounce((text: string) => onSearch(text), 800),
		[]
	);

	useEffect(() => {
		if (searchName) {
			debounceSearch(searchName);
		} else {
			onSearch('');
		}
	}, [searchName]);

	return (
		<div className='search-tab flex'>
			<div className='search-field-wrapper'>
				<input
					value={searchName}
					placeholder='Search by Name, Roll Number, Location'
					className='search-field'
					onChange={(event) => setSearchName(event.target.value)}
				/>
				{searchName && (
					<div
						className='close-icon-wrapper'
						onClick={() => {
							setSearchName('');
						}}
					>
						<MiniCloseIcon className='close-icon' />
					</div>
				)}
			</div>
			<div className='search-icon-wrapper cursor--pointer'>
				<SearchIcon className='search-icon' />
			</div>
		</div>
	);
};

export default SearchField;
