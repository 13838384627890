import { FC, useContext, useEffect } from 'react';

import ScaletechFooter from 'shared/components/footer/scaletechFooter';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { logEvent } from 'shared/util/utility';

import NearByAlumni from '../component/nearByAlumni';
import BatchMemory from '../component/batchMemory';
import FeaturedMemory from '../component/featuredMemory';
import AlumniUpdates from '../component/alumniUpdates';
// import Constitution from '../component/constitution';
import { UserDetailsContext } from 'store/context/userDetailStore';

const Dashboard: FC<{ fcmToken: string }> = ({ fcmToken }) => {
	const { profileData } = useContext(UserDetailsContext);

	const sendDeviceToken = async (token: string) => {
		console.log('Dashboard: sendDeviceToken', fcmToken, window.subscription);
		try {
			await HttpService.put(API_CONFIG.path.addDeviceToken, {
				device_token: token,
				subscription_obj: window.subscription
			});
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (fcmToken) {
			sendDeviceToken(fcmToken);
		}
	}, [fcmToken]);

	useEffect(() => {
		if (profileData) {
			logEvent('HOME_SCREEN_LANDING', {
				rollNumber: profileData.roll_number || '-',
				mobileNo: profileData.contact_number || '-'
			});
		}
	}, [profileData]);

	return (
		<div className='dashboard-wrapper'>
			{/* <Constitution /> */}
			<NearByAlumni />
			<AlumniUpdates />
			<FeaturedMemory />
			<BatchMemory />
			<ScaletechFooter className='home-scaletech-footer-container' />
		</div>
	);
};

export default Dashboard;
