import { useCallback, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ACTIVE_FOOTER_TAB, FOOTER_TABS } from 'shared/constants/constants';

const Footer: FC = () => {
	const { pathname } = useLocation();

	const isActiveTab = useCallback(
		(title: string) => ACTIVE_FOOTER_TAB[title as keyof typeof ACTIVE_FOOTER_TAB].includes(pathname),
		[pathname]
	);

	return (
		<div className='footer-container'>
			{FOOTER_TABS.map(({ icon: Icon, title, redirect }) => {
				return (
					<Link key={title} to={redirect} className='footer-tab-wrapper'>
						<Icon className={isActiveTab(title) ? 'footer-active-icon' : ''} />
						<span className={`footer-tab-title ${isActiveTab(title) ? 'footer-tab-title--active' : ''}`}>
							{title}
						</span>
					</Link>
				);
			})}
		</div>
	);
};

export default Footer;
