import { FC, useState } from 'react';

import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import CustomButton from 'shared/components/customButton/customButton';
import { NextIcon } from 'shared/components/icons/icons';
import { notify } from 'shared/components/notification/notification';

const SuggestionCorner: FC = () => {
	const [loading, setLoading] = useState(false);
	const [suggestionCorner, setSuggestionCorner] = useState('');

	const fetchNotification = async () => {
		setLoading(true);
		try {
			await HttpService.post(API_CONFIG.path.postSuggestion, {
				message: suggestionCorner.trim()
			});
			setLoading(false);
			setSuggestionCorner('');
			notify('Your suggestion has been successfully sent!', 'success');
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	return (
		<div className='suggestion-wrapper'>
			<p className='suggestion-title'>Suggestion Message</p>
			<textarea
				className='suggestion-box'
				value={suggestionCorner}
				placeholder='Enter message'
				onChange={({ target }) => setSuggestionCorner(target.value)}
			/>
			<CustomButton
				btnClassName='primary-button suggestion-button'
				buttonTitle='Send'
				disabled={!suggestionCorner.trim()}
				isSubmitLoading={loading}
				ButtonIcon={() => <NextIcon />}
				onButtonClick={fetchNotification}
			/>
		</div>
	);
};

export default SuggestionCorner;
