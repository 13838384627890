import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { ErrorMessage, FieldArray, Form, Formik, FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { CustomCalendarIcon, CapturePhoto, PlusIcon, DeleteIcon } from 'shared/components/icons/icons';
import { ALLOW_IMAGE_TYPES, MAX_FILE_SIZE, TODAY } from 'shared/constants/constants';
import { alumniForm, teacherCurrentTeacherForm, teacherForm } from 'shared/constants/validation-schema';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import Input from 'shared/form/input';
import { IPreSignedData } from 'shared/interface';
import { removeIfEmpty, uploadFileToS3PreSigned } from 'shared/util/utility';
import ReactSignUpSelect from 'shared/form/reactSignUpSelect';
import { CountyCodeStyle } from 'shared/form/reactSelectSignUpStyles';
import { notify } from 'shared/components/notification/notification';
import { IClassObj } from 'shared/components/customButton/customButton';
import { ROLES } from 'features/auth/interface/auth';

import OptionCard from './OptionCard';
import StepButtonComponent from './StepButtonComponent';
import {
	AdmissionDetails,
	BloodGroup,
	ContactDetails,
	HouseList,
	JoiningDetails,
	PersonalInfoDetails,
	Profession
} from '../constants/profile';
import { countryCodes } from '../constants/countryCode';
import { IPersonalInfoData } from '../interface/profile';

import 'react-datepicker/dist/react-datepicker.css';
import CheckBox from 'shared/form/checkBox';

interface IPersonalInfo {
	handleNextClick: () => void;
	personalInfoData: IPersonalInfoData;
	isEdit: boolean;
	resetStepperBtnTrigger: () => void;
	isStepperBtnTrigger: boolean;
	role: string;
}

interface IPersonalInfoParams {
	birthdate: string;
	profile_photo?: string;
	admission_year?: number;
	passing_year?: number;
	house?: string[];
	year_of_joining?: number;
	year_of_leaving?: number;
	category?: string;
}

const PersonalInfo: FC<IPersonalInfo> = ({
	handleNextClick,
	personalInfoData,
	isEdit,
	isStepperBtnTrigger,
	role,
	resetStepperBtnTrigger
}) => {
	const navigate = useNavigate();

	const {
		profile_key,
		profile_photo,
		first_name,
		last_name,
		birthdate,
		country_code,
		contact_number,
		email,
		blood_group,
		admission_year,
		passing_year,
		house,
		alternate_contact,
		year_of_joining,
		year_of_leaving,
		category
	} = personalInfoData;
	const isTeacher = useMemo(() => ROLES.TEACHER === role, [role]);

	const [imageUploadError, setImageUploadError] = useState<string>('');
	const [profileImageURL, setProfileImageURL] = useState<string>('');
	const [profileImgFile, setProfileImgFile] = useState<File>();

	const [selectedHouses, setSelectedHouses] = useState<string[]>([]);
	const [loading, setLoading] = useState({
		nextLoading: false,
		submitLoading: false
	});

	const [currentlyWorkingFlag, setCurrentlyWorkingFlag] = useState(year_of_leaving === null);

	const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
		const image: any = e.target.files;
		setImageUploadError('');

		if (image[0]) {
			if (!ALLOW_IMAGE_TYPES.includes(image[0].type) || image[0].size > MAX_FILE_SIZE) {
				setImageUploadError(
					'File size exceeds the maximum allowed size of 10MB & following file types: .jpeg, .jpg, .png'
				);
				return;
			} else {
				setProfileImageURL(URL.createObjectURL(image[0]));
				setProfileImgFile(image[0]);
			}
		}
	};

	const getAvatarPreSignedUrl = async (file: File) => {
		try {
			const params = {
				key: file.name
			};

			const presignedData: IPreSignedData = await HttpService.post(API_CONFIG.path.avatarPreSignedUrl, params);
			const profileUrl: string = await uploadFileToS3PreSigned(presignedData, file);
			setProfileImageURL(profileUrl);
			return profileUrl;
		} catch (error) {
			resetStepperBtnTrigger();
		}
	};

	const handleHouseClick = (house: string) => {
		if (selectedHouses.includes(house)) {
			setSelectedHouses((prevSelected) => prevSelected.filter((item) => item !== house));
		} else {
			setSelectedHouses((prevSelected) => [...prevSelected, house]);
		}
	};

	const handleSubmitPersonalInfo = async (values: FormikValues, isNext: boolean) => {
		try {
			setLoading({ nextLoading: isNext, submitLoading: !isNext });

			let profileImage: string = profileImageURL;
			//initial API call for image
			if (profileImgFile) {
				profileImage = ((await getAvatarPreSignedUrl(profileImgFile)) as string) || '';
			}
			const paramsValues = { ...values };

			delete paramsValues.contact_number;
			delete paramsValues.country_code;
			let params: IPersonalInfoParams = {
				...paramsValues,
				birthdate: moment(paramsValues.birthdate).format('')
			};

			if (profileImage || (profile_key as string)) {
				params = {
					...params,
					profile_photo: profileImage.includes('https://') ? (profile_key as string) : profileImage
				};
			}

			removeIfEmpty(params, paramsValues.alternate_contact, 'alternate_contact');
			removeIfEmpty(params, paramsValues.blood_group, 'blood_group');
			removeIfEmpty(params, paramsValues.email, 'email');

			if (isTeacher) {
				delete paramsValues.admission_year;
				delete paramsValues.passing_year;
				delete paramsValues.house;
			} else {
				delete paramsValues.year_of_joining;
				delete paramsValues.year_of_leaving;
				delete paramsValues.category;
			}

			await HttpService.post(API_CONFIG.path.addAlumni, params);

			//update image
			if (first_name && profile_photo !== profileImage) {
				await HttpService.post(API_CONFIG.path.updateProfilePic, { profile_photo: profileImage });
			}

			if (isEdit && isNext) {
				navigate('/profile/details');
			} else {
				handleNextClick();
			}
			setLoading({ nextLoading: false, submitLoading: false });
		} catch (err) {
			setLoading({ nextLoading: false, submitLoading: false });
			resetStepperBtnTrigger();
			console.error(err);
		}
	};

	let boundArrayHelpers;

	const bindArrayHelpers = (arrayHelpers: any) => {
		boundArrayHelpers = arrayHelpers;
	};

	const formRef: any = useRef();

	const isEmptyContactNumberPresent = () => {
		const extraContactNumbers = formRef.current?.values?.alternate_contact || [];

		// Check if any alternate contact number has empty values
		const isEmptyStringPresent = extraContactNumbers.some((contactVal: IClassObj) =>
			Object.values(contactVal).some((value) => value === '')
		);

		return isEmptyStringPresent;
	};

	useEffect(() => {
		if (formRef.current && isStepperBtnTrigger) {
			const errorObj = formRef.current.errors;
			const formValues = formRef.current.values;
			if (isEmpty(errorObj)) {
				handleSubmitPersonalInfo(formValues, false);
			} else {
				notify(Object.values(errorObj)[0] as string, 'error');
				resetStepperBtnTrigger();
			}
		}
	}, [isStepperBtnTrigger]);

	useEffect(() => {
		setProfileImageURL(profile_photo);
		setSelectedHouses(house || []);
	}, [profile_photo, house]);

	const roleWiseData = useMemo(
		() => (isTeacher ? { year_of_joining, year_of_leaving, category } : { admission_year, passing_year, house }),
		[isTeacher]
	);

	return (
		<div className='details-wrapper'>
			<Formik
				innerRef={formRef}
				initialValues={{
					first_name,
					last_name,
					birthdate,
					country_code,
					contact_number,
					email,
					blood_group,
					admission_year,
					passing_year,
					house,
					alternate_contact: alternate_contact || [],
					category: category ?? '',
					...roleWiseData
				}}
				onSubmit={() => {}}
				validationSchema={
					isTeacher ? (currentlyWorkingFlag ? teacherCurrentTeacherForm : teacherForm) : alumniForm
				}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ touched, errors, setFieldValue, values }: FormikValues) => (
					<Form className='form'>
						<div className='profile-container'>
							<label
								htmlFor='file-input'
								className='font-size--sm font--semi-bold cursor--pointer width--full'
							>
								Your Photo{' '}
								<span className='font-size--xxs font--regular opacity--30 text--secondary'>
									(optional)
								</span>
								<div className='mt--12 mb--15'>
									<div className='image-input'>
										<input
											id='file-input'
											type='file'
											name='profile_photo'
											className='hide'
											accept='image/*'
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												handleUploadImage(e);
												setFieldValue('profile_photo', e.target.files);
											}}
										/>
										<div className='capture-photo'>
											<div className='flex align-items--center'>
												{profileImageURL ? (
													<div className='profile-image'>
														<img
															src={profileImageURL}
															alt='profile image'
															className='width--full height--full border-radius--md object-fit--cover object-position-top'
														/>
													</div>
												) : (
													<div className='profile-image file-upload'>
														<CapturePhoto />
													</div>
												)}
												<p className='font-size--xs font--regular text--secondary ml--20'>
													Add or capture your photo here
												</p>
											</div>
										</div>
									</div>
									<p className='error'>{imageUploadError}</p>
								</div>
							</label>
						</div>

						<div className='profile-container'>
							{PersonalInfoDetails.map(
								({ label, name, placeHolder, isRequired, type, note }, index: number) => (
									<div key={index} className='details-input-wrapper mb--15'>
										<label className='font-size--sm font--semi-bold'>
											<p>
												{label}
												{isRequired ? (
													<span className='text--red'> *</span>
												) : (
													<span className='font-size--sm font--regular opacity--30 text--secondary'>
														(optional)
													</span>
												)}
											</p>
										</label>
										<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
											{note}
										</span>
										<div className='flex position--relative'>
											{name !== 'birthdate' && (
												<Input
													name={name}
													type={type}
													data-testid={name}
													placeholder={placeHolder}
													value={values[name]}
													classes={`${name === 'birthdate' ? 'calendar-input' : ''} ${
														touched[name] && errors[name] ? 'form-input--error' : ''
													}`}
													onChange={({ target: { value } }) =>
														setFieldValue(
															name,
															value.indexOf(' ') === 0 || value.trim().length <= 0
																? ''
																: value
														)
													}
												/>
											)}
										</div>
										{name === 'birthdate' && (
											<DatePicker
												icon={CustomCalendarIcon}
												showIcon
												calendarIconClassname='calendar-icon cursor--pointer'
												className='form-input calendar-input'
												placeholderText='dd-mm-yyyy'
												selected={values.birthdate}
												dateFormat='dd-MM-yyyy'
												showYearDropdown
												showMonthDropdown
												scrollableYearDropdown
												yearDropdownItemNumber={100}
												maxDate={moment().subtract(1, 'days').toDate()}
												disabledKeyboardNavigation
												onChange={(date: Date) => setFieldValue(name, date)}
												shouldCloseOnSelect
												onFocus={(e) => e.target.blur()}
											/>
										)}
										<ErrorMessage name={name} component='p' className='error' />
									</div>
								)
							)}
						</div>

						<div className='profile-container'>
							{ContactDetails.map(
								({ label, name, placeHolder, isRequired, type, note }, index: number) => (
									<div key={index} className='details-input-wrapper mb--15'>
										<label className='font-size--sm font--semi-bold'>
											<p>
												{label}
												{isRequired ? (
													<span className='text--red'> *</span>
												) : (
													<span className='font-size--sm font--regular opacity--30 text--secondary'>
														(optional)
													</span>
												)}
											</p>
											<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
												{note}
											</span>
											{name === 'contact_number' && (
												<>
													<div className='contact-input-container flex mt--12'>
														<div className='contact-input-wrapper'>
															<div className='flex width--full mb--12'>
																<ReactSignUpSelect
																	defaultValue={
																		values.country_code
																			? {
																					label: values.country_code,
																					value: values.country_code
																			  }
																			: null
																	}
																	isDisabled
																	placeholder=''
																	options={countryCodes}
																	styles={CountyCodeStyle}
																/>
																<Input
																	value={values.contact_number}
																	name='contact_number'
																	type={type}
																	placeholder={placeHolder}
																	classes={`number-input ${
																		touched[name] && errors[name]
																			? 'form-input--error'
																			: ''
																	}`}
																	readOnly={true}
																/>
															</div>
														</div>
														<div
															className={`contact-input plus-icon ml--10 cursor--pointer ${
																isEmptyContactNumberPresent()
																	? 'disabled pointer-events--none'
																	: ''
															}`}
															onClick={() => {
																boundArrayHelpers.push({
																	contact_number: '',
																	country_code: ''
																});
															}}
														>
															<PlusIcon />
														</div>
													</div>
													<FieldArray name='alternate_contact'>
														{(arrayHelpers) => {
															bindArrayHelpers(arrayHelpers);
															return (
																<div
																	className={`contact-input-container flex mt--12 ${
																		values.alternate_contact?.length > 0
																			? ''
																			: 'hide'
																	}`}
																>
																	<div className='contact-input-wrapper'>
																		{values.alternate_contact.map(
																			(_: any, fieldIndex: number) => {
																				return (
																					<div
																						key={fieldIndex}
																						className='flex width--full mb--12'
																					>
																						<ReactSignUpSelect
																							placeholder='+91'
																							options={countryCodes}
																							styles={CountyCodeStyle}
																							value={
																								values
																									.alternate_contact[
																									fieldIndex
																								].country_code
																									? {
																											label: values
																												.alternate_contact[
																												fieldIndex
																											]
																												.country_code,
																											value: values
																												.alternate_contact[
																												fieldIndex
																											]
																												.country_code
																									  }
																									: null
																							}
																							onChange={(
																								selectedValue: any
																							) => {
																								setFieldValue(
																									`alternate_contact[${fieldIndex}].country_code`,
																									selectedValue.value
																								);
																							}}
																						/>
																						<Input
																							name={`alternate_contact[${fieldIndex}].contact_number`}
																							type={type}
																							placeholder={placeHolder}
																							value={
																								values
																									.alternate_contact[
																									fieldIndex
																								].contact_number
																							}
																							classes={`number-input mr--15 ${
																								touched[name] &&
																								errors[name]
																									? 'form-input--error'
																									: ''
																							}`}
																							onChange={({
																								target: { value }
																							}) =>
																								setFieldValue(
																									`alternate_contact[${fieldIndex}].contact_number`,
																									value
																								)
																							}
																						/>
																						<div className='delete-info-wrapper delete-contact-number'>
																							<div
																								className='delete-icon-wrapper'
																								onClick={() =>
																									arrayHelpers.remove(
																										fieldIndex
																									)
																								}
																							>
																								<DeleteIcon />
																							</div>
																						</div>
																					</div>
																				);
																			}
																		)}
																	</div>
																</div>
															);
														}}
													</FieldArray>
												</>
											)}
											{name === 'email' && (
												<Input
													name={name}
													type={type}
													data-testid={name}
													placeholder={placeHolder}
													value={values[name]}
													classes={`mt--12 ${
														touched[name] && errors[name] ? 'form-input--error' : ''
													}`}
													onChange={({ target: { value } }) => setFieldValue(name, value)}
												/>
											)}
											{name === 'blood_group' && (
												<OptionCard
													optionList={BloodGroup}
													selectedOptions={values.blood_group}
													name={name}
													setFieldValue={setFieldValue}
													isMultiple={false}
												/>
											)}
										</label>
										<ErrorMessage name={name} component='p' className='error' />
									</div>
								)
							)}
						</div>

						<div className='profile-container'>
							{(isTeacher ? JoiningDetails : AdmissionDetails).map(
								({ label, name, isRequired, note }, index: number) => {
									return (
										<div key={index} className='details-input-wrapper mb--15'>
											<label className='font-size--sm font--semi-bold'>
												<p>
													{label}
													{isRequired ? (
														<span className='text--red'> *</span>
													) : (
														<span className='font-size--sm font--regular opacity--30 text--secondary'>
															(optional)
														</span>
													)}
												</p>
											</label>
											<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
												{note}
											</span>

											{name === 'house' ? (
												<OptionCard
													optionList={HouseList}
													selectedOptions={selectedHouses}
													handleOptionSelection={handleHouseClick}
													name={name}
													setFieldValue={setFieldValue}
													tabWidth='big-tab'
													isMultiple={true}
												/>
											) : name === 'category' ? (
												<OptionCard
													optionList={Profession}
													selectedOptions={values.category}
													name={name}
													setFieldValue={setFieldValue}
													isMultiple={false}
													tabWidth='width--85px'
												/>
											) : (
												<div className='mt--5'>
													<DatePicker
														icon={CustomCalendarIcon}
														name={name}
														showIcon
														readOnly={currentlyWorkingFlag && name === 'year_of_leaving'}
														calendarIconClassname='calendar-icon cursor--pointer'
														className={`form-input calendar-input ${
															currentlyWorkingFlag && name === 'year_of_leaving'
																? 'disabled'
																: ''
														}`}
														placeholderText='YYYY'
														selected={values[name] ? new Date(values[name], 0, 1) : null}
														maxDate={
															name === 'admission_year'
																? TODAY
																: new Date(moment(TODAY).add(7, 'year') as any)
														}
														minDate={
															name === 'admission_year'
																? new Date('01-01-1900')
																: new Date(`01-01-${values.admission_year}`)
														}
														showYearPicker
														shouldCloseOnSelect
														dateFormat='yyyy'
														onChange={(date: Date) => {
															const passingYear = moment(date).add(7, 'year').year();

															name === 'admission_year' &&
																setFieldValue('passing_year', passingYear);

															setFieldValue(name, moment(date).year());
														}}
														disabledKeyboardNavigation
													/>
												</div>
											)}
											{name === 'year_of_leaving' && (
												<CheckBox
													title='Currently Working'
													checked={currentlyWorkingFlag}
													onChange={() => {
														setCurrentlyWorkingFlag(!currentlyWorkingFlag);
														setFieldValue('year_of_leaving', null);
													}}
												/>
											)}

											<ErrorMessage name={name} component='p' className='error' />
										</div>
									);
								}
							)}
						</div>
						<div className='submit-button-container'>
							<StepButtonComponent
								disabled={
									!isEmpty(errors) ||
									(!isTeacher && selectedHouses.length === 0) ||
									(isTeacher && !currentlyWorkingFlag && !values.year_of_leaving)
								}
								nextLoading={loading.nextLoading}
								submitLoading={loading.submitLoading}
								isPrevButton={false}
								buttonTitle='Go to Address section'
								onPrevClick={() => {}}
								nextButtonClick={(isNext: boolean) => handleSubmitPersonalInfo(values, isNext)}
								isSave={isEdit}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default PersonalInfo;
