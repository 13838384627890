import { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';

import SearchImg from 'assets/images/search.png';

import { LocationIcon, MobileIcon, WPIcon } from 'shared/components/icons/icons';
import { Loader } from 'shared/components/spinner/spinner';
import { API_CONFIG } from 'shared/constants/api';
import HttpService from 'shared/services/http.service';
import { capitalizeWords } from 'shared/util/utility';
import ProfilePic from 'features/profile/components/profilePic';
import { IAlumniItem } from 'features/nearByMap/interface/nearByMap';

import SearchField from '../components/searchField';
import { SearchAlumniItem } from '../interface/search';

const Search: FC = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [matchList, setMatchList] = useState([] as SearchAlumniItem[]);
	const [filter, setFilter] = useState({ currentPage: 1, searchText: '', totalAlumni: 0 });

	const fetchAlumni = async (searchTxt = '', page = 1) => {
		setLoading(true);
		try {
			const alumniList = await HttpService.get(`${API_CONFIG.path.alumni}`, {
				search: searchTxt.trim(),
				current_page: page
			});

			if (alumniList && alumniList.data) {
				const list = alumniList.data.map(
					({
						first_name,
						last_name,
						roll_number,
						current_city,
						profession,
						id,
						profile_photo,
						country_code,
						contact_number,
						thumbnail_photo
					}: IAlumniItem) => ({
						name: `${first_name} ${last_name} (${roll_number})`,
						profession:
							profession.designation || profession.organization
								? `${profession.designation}, ${profession.organization}`
								: '',
						city: `${current_city || ''}`,
						profile: profile_photo,
						contact: `${country_code} ${contact_number}`,
						id,
						thumbnail_photo
					})
				);
				setMatchList((prevList) => [...prevList, ...list]);
				setFilter((f) => ({ ...f, totalAlumni: alumniList.total }));
			}
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const onSearch = (text: string) => {
		setFilter((f) => ({ ...f, currentPage: 1, searchText: text }));
		setMatchList([]);
		fetchAlumni(text, 1);
	};

	const fetchMoreData = () => {
		setFilter((f) => {
			fetchAlumni(filter.searchText, filter.currentPage + 1);
			return { ...f, currentPage: f.currentPage + 1 };
		});
	};

	return (
		<>
			<div className='search-tab-wrapper'>
				<SearchField onSearch={onSearch} />

				<div className='search-result' id='scrollableDiv'>
					{loading && <Loader />}
					{!loading && matchList.length === 0 && (
						<div className='search-img-wrapper'>
							<img src={SearchImg} alt='search-img' />
							<p className='search-friend-text'>
								{matchList.length === 0 ? 'Couldn’t find. Try again' : 'Search for your friends here'}
							</p>
						</div>
					)}
					{matchList.length > 0 && (
						<InfiniteScroll
							dataLength={matchList.length}
							next={fetchMoreData}
							hasMore={filter.totalAlumni > matchList.length}
							loader={<Loader />}
							endMessage=''
							scrollThreshold={0.9}
							scrollableTarget='scrollableDiv'
						>
							{matchList.map((alumni) => (
								<div
									className='friends-list-wrapper cursor--pointer'
									key={alumni.id}
									onClick={() => navigate(`/search?userId=${alumni.id}`)}
								>
									<div className='flex align-items--center'>
										<div className='cursor--pointer' onClick={(e) => e.stopPropagation()}>
											<ProfilePic
												isEdit={false}
												url={alumni.profile}
												thumbnailUrl={alumni.thumbnail_photo}
											/>
										</div>
										<div className='friend-details-wrapper'>
											<p className='friend-name'>{capitalizeWords(alumni.name)}</p>
											<p className='occupation'>{alumni.profession}</p>
											{alumni.city && (
												<div className='flex align-items--center location-wrapper'>
													<LocationIcon />
													<p className='city ml--5'>{alumni.city}</p>
												</div>
											)}
										</div>
									</div>
									<div>
										<a
											className='mobile-icon-wrapper'
											href={`tel:${alumni.contact}`}
											onClick={(e) => e.stopPropagation()}
										>
											<MobileIcon className='mobile-icon' />
										</a>
										<ReactWhatsapp
											number={`${alumni.contact}`}
											element='webview'
											className='mobile-icon-wrapper mt--10'
											onClick={(e) => e.stopPropagation()}
										>
											<WPIcon className='wp-icon' />
										</ReactWhatsapp>
									</div>
								</div>
							))}
						</InfiniteScroll>
					)}
				</div>
			</div>
		</>
	);
};

export default Search;
