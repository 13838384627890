import { ReactNode } from 'react';

export interface IProfileData {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	roll_number: number;
	birthdate: string;
	blood_group: string;
	contact_number: string;
	country_code: string;
	alternate_contact: any;
	admission_year: number;
	passing_year: number;
	house: string[];
	life_time_member: boolean;
	membership_date: any;
	created_at: number;
	address: Address;
	profession: Profession;
	family: Family[];
	education: Education[];
	profile_photo: string;
	generic_notification: boolean;
	step: string;
	profile_key: string;
	role: string;
	year_of_joining?: number | null;
	year_of_leaving?: number | null;
}

export interface Address {
	id: string;
	current_house_name: any;
	current_street_name: any;
	current_landmark: any;
	current_zipcode: string;
	current_country: string;
	current_state: string;
	current_city: string;
	current_latitude: string;
	current_longitude: string;
	permanent_house_name: any;
	permanent_street_name: any;
	permanent_landmark: any;
	permanent_zipcode: any;
	permanent_country: any;
	permanent_state: any;
	permanent_city: any;
	permanent_latitude: any;
	permanent_longitude: any;
	address: string;
	location: string;
}

export interface Profession {
	id: string;
	work_type: string;
	organization: string;
	designation: string;
	location: string;
}

export interface Education {
	id: string;
	field: string;
	institute_name: string;
	institute_passout_year: number;
}

export interface Family {
	birthdate: string;
	blood_group: string;
	id: string;
	name: string;
	occupation: string;
	relation: string;
}
export interface ITeacherPersonalDetails {
	'Year of Joining': number | null;
	'Year of Leaving'?: number | null;
}
export interface IProfile {
	name: string;
	profilePhoto: string;
	professionInfo: string;
	city: string;
	batch: string;
	isLifeMember: boolean;
	rollNumber: number;
	[tabArr.personal]: {
		Address: string | null;
		contact: string;
		mail: string;
		'Date Of Birth': string;
		'Blood Group': string;
		degree: string | null;
		'Institute Name': string | null;
		house?: string | null | undefined;
		'Admission Year'?: number;
		'Pass-out Year'?: number;
		'Year of Joining'?: number | null;
		'Year of Leaving'?: number | null;
	}[];
	[tabArr.professional]: {
		'Work Type': string;
		'Name Of Organization': string;
		designation: string;
		location: string;
	}[];
	[tabArr.family]: {
		'Date Of Birth': string;
		'Blood Group': string;
		name: string;
		occupation: string;
		relation: string;
	}[];
}

export enum tabArr {
	personal = 'personal',
	professional = 'professional',
	family = 'family'
}

export interface IPersonalDetails {
	profile_photo: string;
	first_name: string;
	last_name: string;
	birthdate: Date | null;
	alternate_contact: IAlternateContact[];
	contact_number: string;
	country_code: string;
	email: string;
	blood_group: string;
	// alumni
	admission_year?: Date | null;
	passing_year?: Date | null;
	house?: string[];
	//teacher
	year_of_joining?: Date | null;
	year_of_leaving?: Date | null;
	category?: string;
	profile_key?: string;
}

export interface IAlternateContact {
	contact_number: string;
	country_code: string;
}

export interface IAddressDetails {
	address: string;
	current_house_name: string;
	current_city: string;
	current_state: string;
	current_country: string;
	current_latitude: string;
	current_longitude: string;
	current_zipcode: string;
	location: string;
}

export interface IProfessionDetails {
	designation: string;
	location: string | null;
	organization: string;
	work_type: string;
}

export interface IEducationDetails {
	field: string;
	institute_name: string;
	institute_passout_year: number;
	id?: string;
}

export interface IFamilyDetails {
	birthdate: string | null;
	blood_group: string;
	name: string;
	occupation: string;
	relation: string;
	id?: string;
}

export interface ISetMapProps {
	open: boolean;
	name: string;
}

export interface IMapProps {
	searchableAddress: string;
	setSearchableAddress: (address: string) => void;
	isMapOpen: ISetMapProps;
	setIsMapOpen: (isMapOpen: ISetMapProps) => void;
	setFieldValue: (name: string, value: number | string) => void;
}

export interface IGoogleMapProps {
	onLocationChange: (lat: number, lng: number) => void;
	initialLat: number;
	initialLng: number;
	googleMapURL?: string;
	loadingElement?: ReactNode;
	containerElement?: ReactNode;
	mapElement?: ReactNode;
}

export interface ILatLng {
	lat: number;
	lng: number;
}

export interface IPlace {
	geometry: {
		location: {
			lat: number;
			lng: number;
		};
	};
	name: string;
}

export interface IClassObj {
	[key: string]: string | number;
}

export interface IPresignUrlResponse {
	key: string;
	upload_url: string;
}
