import { FC } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import { useDispatch } from 'react-redux';

import CustomButton from 'shared/components/customButton/customButton';
import { NextArrow, ScaletechIcon } from 'shared/components/icons/icons';
import SignUpHeader from 'shared/components/header/components/signUpHeader';
import { SCALETECH_REDIRECTION_LINK } from 'shared/constants/constants';
import { createAction } from 'shared/util/utility';
import * as actionTypes from 'store/actionTypes';

import congratulation from 'assets/animations/congratulation.json';
import celebration from 'assets/images/celebration.png';

const SuccessfulCompletion: FC = () => {
	const dispatch = useDispatch();

	return (
		<div className='successful-completion-wrapper'>
			<SignUpHeader headerClassName='success-header' />
			<div className='content-wrapper'>
				<div className='flex--column justify-align--center width--full'>
					<div className='confetti-wrapper z-index--1'>
						<Lottie animationData={congratulation} loop={2} />
					</div>
					<img src={celebration} alt='celebration' />
					<p className='completion-title'>Thank you!</p>
					<p className='completion--sub-title'>Stay tuned for an even better alumni journey!</p>
					<p className='completion-msg'>
						Your registration is successfully completed! Our team is dedicated to crafting an improved
						version that will elevate the OBSSA Alumni experience. Your participation has played a crucial
						role, and we're excited about the enhancements ahead.
					</p>
					<CustomButton
						btnClassName='primary-button justify-align--center view-profile-btn z-index--2'
						buttonTitle={'Go to Home'}
						onButtonClick={() => dispatch(createAction(actionTypes.UPDATE_USER_ROUTE))}
						ButtonIcon={() => <NextArrow className='ml--10' />}
					/>
					<div className='justify-align--center footer-bottom-wrapper'>
						<p className='sponsor-msg'>
							Made with <span className='heart-icon'>❤️</span> by
						</p>
						<p className='sponsor-msg position--relative flex align-items--center ml--4 z-index--2'>
							<Link to={SCALETECH_REDIRECTION_LINK} target='_blank' className='sponsor-msg--link'>
								Scaletech
								<ScaletechIcon className='scaletech-logo' />
							</Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SuccessfulCompletion;
