// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { FIREBASE_CONFIG } from 'shared/constants';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseApp = initializeApp(FIREBASE_CONFIG);

export const firebaseAuth = getAuth(firebaseApp);

export const analytics = getAnalytics(firebaseApp);
export const logFirebaseEvent = (name: string, data: object) => logEvent(analytics, name, data);

export default firebaseApp;
