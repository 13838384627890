import { FC, useEffect, useState } from 'react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { AccordionIcon, CloseIcon } from 'shared/components/icons/icons';
import { FamilyDetails, MembershipStatus, PersonalInfo, StepperDetails } from '../constants/alumniDetails';

interface IProfileDetails {
	profilePic: string;
	fullName: string;
}

interface IViewAlumniProps {
	infoMapper: any;
	title: string;
	isArr: boolean;
	className: string;
	setIsFamilyDetailsShow?: (isShow: boolean) => void;
	setIsMembershipStatusShow?: (isShow: boolean) => void;
	profileDetails?: IProfileDetails;
}

const ViewAlumniCard: FC<IViewAlumniProps> = (props) => {
	const { infoMapper, title, isArr, profileDetails, setIsFamilyDetailsShow, className, setIsMembershipStatusShow } =
		props;
	const [showInfo, setShowInfo] = useState(false);
	const [showFullProfile, setShowFullProfile] = useState(false);

	const SimpleIcon = StepperDetails[title].Icon;
	const SpecialIcon = StepperDetails[title].ActiveIcon;

	useEffect(() => {
		if (showFullProfile) {
			document.body.style.overflow = 'hidden';
		}

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [showFullProfile]);

	return (
		<div className={`flex width--full ${className}`}>
			<div className={`vertical-stepper-wrapper ${showInfo ? 'vertical-stepper-fill-wrapper' : ''}`}>
				{showInfo ? <SpecialIcon /> : <SimpleIcon />}
			</div>
			<div className={`accordion-content-wrapper  ${showInfo ? 'open-card' : ''}`}>
				<div
					className='details--title flex justify-content--between align-items--center cursor--pointer'
					onClick={() => {
						setShowInfo(!showInfo);
						title === FamilyDetails && setIsFamilyDetailsShow && setIsFamilyDetailsShow(!showInfo);
						title === MembershipStatus && setIsMembershipStatusShow && setIsMembershipStatusShow(!showInfo);
					}}
				>
					<p
						className={`no-margin font-size--sm ${
							!showInfo ? 'opacity--50 font--regular' : 'opacity--100 font--bold'
						}`}
					>
						{title}
					</p>
					<div className='icon-wrapper'>
						<AccordionIcon className={showInfo ? 'down-icon' : 'up-icon'} />
					</div>
				</div>

				{!isArr ? (
					<>
						{profileDetails && title === PersonalInfo && (
							<div
								className={`mt--10 ${
									!showInfo
										? 'hide'
										: 'inner-content transparent--top-border flex align-items--center'
								}`}
							>
								<div
									className={`profile-pic-wrapper ${
										showFullProfile ? 'profile-pic-full-wrapper' : ''
									}`}
								>
									<img
										src={profileDetails.profilePic}
										alt='user-profile'
										className={`width--full height--full border-radius--half cursor--pointer object-fit--contain ${
											showFullProfile ? 'profile-full-img' : ''
										}`}
										onClick={() => setShowFullProfile(!showFullProfile)}
									/>
									{showFullProfile && <CloseIcon className='close--icon' />}
								</div>
								<p className='font-size--sm font--bold text--capitalize'>{profileDetails.fullName}</p>
							</div>
						)}
						<div
							className={`${!showInfo ? 'hide' : 'inner-content mt--15 mb--15 flex flex--wrap'} divider`}
						>
							{Object.entries(infoMapper).map(([label, value]: any, index) => (
								<div key={index} className='single-details width--full flex'>
									<p style={{ minWidth: 110 }} className='font-size--xs font--regular opacity--80'>
										{label}:
									</p>
									{!isArray(value) ? (
										<p
											className={`font-size--xs font--regular pl--10 ${
												label === 'Mail' ? '' : 'text--capitalize'
											} ${label === 'Mail' || label === 'Phone' ? 'text--blue' : 'opacity--50'}`}
										>
											{value || '-'}
										</p>
									) : (
										<div className='flex flex--wrap'>
											{value.map((house, index) => (
												<div key={index} className='house-tab'>
													<p className='text--capitalize font-size--sm font--regular opacity--50'>
														{house}
													</p>
												</div>
											))}
										</div>
									)}
								</div>
							))}
						</div>
					</>
				) : (
					<div className={`flex flex--column ${showInfo ? 'pb--20' : ''}`}>
						{infoMapper.map((data: any, index: number) => (
							<div
								key={index}
								className={`${
									!showInfo ? 'hide' : 'inner-content no--padding flex flex--wrap'
								} width--full divider mt--15`}
							>
								{Object.entries(data).map(([label, value]: any, innerIndex) => (
									<div key={innerIndex} className='single-details width--full flex'>
										<p
											style={{ minWidth: 110 }}
											className='text--capitalize font-size--xs font--regular opacity--80'
										>
											{label}:
										</p>
										<p className='font-size--xs font--regular pl--10 text--capitalize opacity--50 break-word--word'>
											{value || '-'}
										</p>
									</div>
								))}
							</div>
						))}
						{isEmpty(infoMapper) && (
							<p
								className={`${
									!showInfo ? 'hide' : ''
								} font-size--sm font--regular text--capitalize opacity--50`}
							>
								Add {title} to show here
							</p>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ViewAlumniCard;
