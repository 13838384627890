import { useEffect, useState } from 'react';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

import firebaseApp from 'shared/firebase/firebase';
import { FB_VAPID_KEY, PUSH_NOTIFICATION_SERVER_KEY } from 'shared/constants';
import { isIOSDevice } from 'shared/util/utility';

export const usePushNotification = () => {
	const isNotificationGranted = sessionStorage['notification'];
	const [fcmToken, setFcmToken] = useState('');

	const [registrationObj, setRegistrationObj] = useState<ServiceWorkerRegistration | null>(null);
	const [isGranted, setIsGranted] = useState(isNotificationGranted || false);

	const registerSW = async () => {
		// let serviceWorker;
		console.log('registerSW');
		try {
			// Don't recall this function and let it execute, after some time it will work
			const registration = await navigator.serviceWorker.register(`./service-worker.js?v=0.0.4`); // Registering the service worker.\
			if (isIOSDevice()) {
				if (registration.active?.state === 'activated') {
					setRegistrationObj(registration);
					return;
				}

				registration.addEventListener('updatefound', () => {
					console.log('registration:updatefound', registration);
					const newWorker = registration.installing;
					console.log('New service worker found:', newWorker);
					newWorker?.addEventListener('statechange', () => {
						console.log('Service Worker state changed:', newWorker.state);
						if (newWorker.state === 'activated') {
							console.log('Service Worker activated');
							setRegistrationObj(registration);
						}
					});
				});
			} else {
				console.log('registration:', registration);
				let serviceWorker;
				if (registration.installing) {
					serviceWorker = registration.installing;
					console.log('SW installing');
				} else if (registration.waiting) {
					serviceWorker = registration.waiting;
					console.log('SW installed');
				} else if (registration.active) {
					serviceWorker = registration.active;
					console.log('SW active');
				}
				if (serviceWorker) {
					if (serviceWorker.state == 'activated') {
						console.log('SW activated');
						setRegistrationObj(registration);
					}
				}
			}
		} catch (error) {
			console.error('Error in SW registration:', error);
		}
	};

	const requestUserPermission = async () => {
		if (!registrationObj) {
			return;
		}
		try {
			const permission = await Notification.requestPermission(); // Requesting permission for push notifications.
			console.log(' requestPermission', permission);
			if ('Notification' in window) {
				if (permission === 'granted') {
					subscribePushNotification();
				} else if (permission !== 'denied') {
					// Request permission if it's not denied or granted
					Notification.requestPermission().then(function (permission) {
						if (permission === 'granted') {
							subscribePushNotification();
						}
					});
				} else {
					console.log('Notifications are blocked.');
				}
			} else {
				console.log('Notifications are not supported.');
			}
		} catch (error) {
			console.error('Error subscribing:', error);
		}
	};

	const subscribePushNotification = async () => {
		console.log('isGranted--registrationObj:', isGranted, registrationObj);
		if (isGranted || !registrationObj) {
			return;
		}
		try {
			setIsGranted(true);
			sessionStorage.setItem('notification', 'true');
			setRegistrationObj({} as ServiceWorkerRegistration);
			const subscription = await registrationObj.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: PUSH_NOTIFICATION_SERVER_KEY
			});
			console.log('subscription');

			window.subscription = subscription; // Storing the subscription in the window object.
			console.log('in fcmToken');
			const supported = await isSupported();
			console.log('supported:', supported);

			if (!supported) {
				return;
			}

			const firebaseMessaging = getMessaging(firebaseApp);

			// Get the FCM token using Firebase Messaging
			const fcmToken = await getToken(firebaseMessaging, {
				serviceWorkerRegistration: registrationObj, // Pass the service worker registration object
				vapidKey: FB_VAPID_KEY // Pass the VAPID key for authentication
			});
			console.log('fcmToken', fcmToken);

			setFcmToken(fcmToken);
		} catch (error) {
			console.error('Error subscription:', error);
		}
	};

	useEffect(() => {
		console.log('in root', 'serviceWorker' in navigator);
		try {
			if ('serviceWorker' in navigator) {
				if (document.readyState === 'complete') {
					registerSW();
					console.log('readySate');
				} else {
					window.addEventListener('load', registerSW);
					console.log('event here');
				}
				console.log('in serviceWorker condition');
			}
		} catch (error) {
			console.error('Error serviceWorker in navigator:', error);
		}

		console.log('navigator.serviceWorker.ready:', document.readyState);

		return () => window.removeEventListener('load', registerSW);
	}, []);

	return { fcmToken, requestUserPermission };
};
