import { FC, useRef, useEffect, useState, useMemo } from 'react';
import { ErrorMessage, FieldArray, Form, Formik, FormikValues } from 'formik';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import DatePicker from 'react-datepicker';

import { notify } from 'shared/components/notification/notification';
import { CustomCalendarIcon, DeleteIcon, PlusIcon } from 'shared/components/icons/icons';
import { alumniForm, teacherCurrentTeacherForm } from 'shared/constants/validation-schema';
import Input from 'shared/form/input';
import AuthService from 'shared/services/auth.service';
import CheckBox from 'shared/form/checkBox';
import ReactSignUpSelect from 'shared/form/reactSignUpSelect';
import { countryCodes } from 'features/auth/constants/countryCodeConstants';
import { CountyCodeStyle } from 'shared/form/reactSelectSignUpStyles';
import { removeIfEmpty } from 'shared/util/utility';
import { IUserData, ROLES } from 'features/auth/interface/auth';

import OptionCard from './optionCard';
import {
	ADMISSION_INFO_DETAILS,
	BLOOD_GROUP,
	CONTACT_INFO_DETAILS,
	HOUSE_LIST,
	JOINING_INFO_DETAILS,
	PERSONAL_INFO_DETAILS,
	PROFESSION,
	TODAY
} from '../constants/profile';
import { IClassObj, IPersonalDetails } from '../interface/profile';

import 'react-datepicker/dist/react-datepicker.css';

interface IPersonalDetailsProps {
	isCurrentStepChange: boolean;
	isSaveDetails: boolean;
	personalDetails: IPersonalDetails;
	handleSaveClick: (personalDetails: IPersonalDetails) => void;
	setUpdatedAlumniDetails: (personalDetails: IPersonalDetails) => void;
	handleResetSaveClick: () => void;
}

const PersonalDetails: FC<IPersonalDetailsProps> = ({
	isCurrentStepChange,
	personalDetails,
	isSaveDetails,
	handleSaveClick,
	setUpdatedAlumniDetails,
	handleResetSaveClick
}) => {
	const userData = AuthService.getAuthData() as IUserData;
	const isTeacher = useMemo(() => ROLES.TEACHER === userData.role, [userData]);

	const {
		first_name,
		last_name,
		birthdate,
		country_code,
		contact_number,
		email,
		blood_group,
		admission_year,
		passing_year,
		house,
		alternate_contact,
		year_of_joining,
		year_of_leaving,
		category
	} = personalDetails;

	const [currentlyWorkingFlag, setCurrentlyWorkingFlag] = useState(year_of_leaving === null);

	const formRef: any = useRef();
	const [selectedHouses, setSelectedHouses] = useState<string[]>(
		(typeof personalDetails.house === 'string' ? [personalDetails.house] : personalDetails.house) as string[]
	);

	const handleSavePersonalDetails = (isSaveDetail: boolean) => {
		const paramsValues = { ...formRef.current?.values };

		delete paramsValues.contact_number;
		delete paramsValues.country_code;

		removeIfEmpty(paramsValues, paramsValues.alternate_contact, 'alternate_contact');
		removeIfEmpty(paramsValues, paramsValues.blood_group, 'blood_group');
		removeIfEmpty(paramsValues, paramsValues.email, 'email');

		if (isTeacher) {
			delete paramsValues.house;
			delete paramsValues.admission_year;
			delete paramsValues.passing_year;
		} else {
			delete paramsValues.year_of_joining;
			delete paramsValues.year_of_leaving;
			delete paramsValues.category;
		}

		if (formRef.current) {
			const errorObj = formRef.current.errors;
			if (isEmpty(errorObj)) {
				isSaveDetail
					? handleSaveClick({ ...paramsValues })
					: setUpdatedAlumniDetails({
							...paramsValues
					  });
			} else {
				notify(Object.values(errorObj)[0] as string, 'error');
				handleResetSaveClick();
			}
		}
	};

	useEffect(() => {
		isSaveDetails && handleSavePersonalDetails(true);
	}, [isSaveDetails]);

	useEffect(() => {
		isCurrentStepChange && handleSavePersonalDetails(false);
	}, [isCurrentStepChange]);

	const handleHouseClick = (house: string) => {
		if (selectedHouses.includes(house)) {
			setSelectedHouses((prevSelected) => prevSelected.filter((item) => item !== house));
		} else {
			setSelectedHouses((prevSelected) => [...prevSelected, house]);
		}
	};

	const roleWiseData = useMemo(
		() => (isTeacher ? { year_of_joining, year_of_leaving, category } : { admission_year, passing_year, house }),
		[isTeacher]
	);

	const isEmptyContactNumberPresent = () => {
		const extraContactNumbers = formRef.current?.values?.alternate_contact || [];

		// Check if any alternate contact number has empty values
		const isEmptyStringPresent = extraContactNumbers.some((contactVal: IClassObj) =>
			Object.values(contactVal).some((value) => value === '')
		);

		return isEmptyStringPresent;
	};

	let boundArrayHelpers;

	const bindArrayHelpers = (arrayHelpers: any) => {
		boundArrayHelpers = arrayHelpers;
	};

	return (
		<div className='edit-profile-container'>
			<Formik
				innerRef={formRef}
				initialValues={{
					first_name,
					last_name,
					birthdate,
					country_code,
					contact_number,
					email,
					blood_group,
					admission_year,
					passing_year,
					house,
					alternate_contact: alternate_contact || [],
					...roleWiseData
				}}
				onSubmit={() => {}}
				validationSchema={isTeacher ? teacherCurrentTeacherForm : alumniForm}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ errors, setFieldValue, values, touched }: FormikValues) => {
					return (
						<Form className='form'>
							<div className='profile-container'>
								{PERSONAL_INFO_DETAILS.map(
									({ label, name, placeHolder, isRequired, type, note }, index: number) => (
										<div key={index} className='details-input-wrapper mb--15'>
											<label className='font-size--sm font--semi-bold'>
												<p>
													{label}
													{isRequired ? (
														<span className='text--red'> *</span>
													) : (
														<span className='font-size--sm font--regular opacity--30 text--secondary'>
															(optional)
														</span>
													)}
												</p>
											</label>
											<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
												{note}
											</span>
											<div className='flex position--relative'>
												{name !== 'birthdate' && (
													<Input
														name={name}
														type={type}
														data-testid={name}
														placeholder={placeHolder}
														value={values[name]}
														classes={`${name === 'birthdate' ? 'calendar-input' : ''} ${
															touched[name] && errors[name] ? 'form-input--error' : ''
														}`}
														onChange={({ target: { value } }) =>
															setFieldValue(
																name,
																value.indexOf(' ') === 0 || value.trim().length <= 0
																	? ''
																	: value
															)
														}
													/>
												)}
											</div>
											{name === 'birthdate' && (
												<DatePicker
													icon={CustomCalendarIcon}
													showIcon
													calendarIconClassname='calendar-icon cursor--pointer'
													className='form-input calendar-input'
													placeholderText='dd-mm-yyyy'
													selected={values.birthdate}
													dateFormat='dd-MM-yyyy'
													showYearDropdown
													showMonthDropdown
													scrollableYearDropdown
													yearDropdownItemNumber={100}
													maxDate={moment().subtract(1, 'days').toDate()}
													disabledKeyboardNavigation
													onChange={(date: Date) => setFieldValue(name, date)}
													shouldCloseOnSelect
													onFocus={(e) => e.target.blur()}
												/>
											)}
											<ErrorMessage name={name} component='p' className='error' />
										</div>
									)
								)}
								{CONTACT_INFO_DETAILS.map(
									({ label, name, placeHolder, isRequired, type, note }, index: number) => (
										<div key={index} className='details-input-wrapper mb--15'>
											<label className='font-size--sm font--semi-bold'>
												<p>
													{label}
													{isRequired ? (
														<span className='text--red'> *</span>
													) : (
														<span className='font-size--sm font--regular opacity--30 text--secondary'>
															(optional)
														</span>
													)}
												</p>
												<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
													{note}
												</span>
												{name === 'contact_number' && (
													<>
														<div className='contact-input-container flex mt--12'>
															<div className='contact-input-wrapper'>
																<div className='flex width--full mb--12'>
																	<ReactSignUpSelect
																		defaultValue={
																			values.country_code
																				? {
																						label: values.country_code,
																						value: values.country_code
																				  }
																				: null
																		}
																		isDisabled
																		placeholder=''
																		options={countryCodes}
																		styles={CountyCodeStyle}
																	/>
																	<Input
																		value={values.contact_number}
																		name='contact_number'
																		type={type}
																		placeholder={placeHolder}
																		classes={`number-input ${
																			touched[name] && errors[name]
																				? 'form-input--error'
																				: ''
																		}`}
																		readOnly={true}
																	/>
																</div>
															</div>
															<div
																className={`contact-input plus-icon ml--10 cursor--pointer ${
																	isEmptyContactNumberPresent()
																		? 'disabled pointer-events--none'
																		: ''
																}`}
																onClick={() => {
																	boundArrayHelpers.push({
																		contact_number: '',
																		country_code: ''
																	});
																}}
															>
																<PlusIcon />
															</div>
														</div>
														<FieldArray name='alternate_contact'>
															{(arrayHelpers) => {
																bindArrayHelpers(arrayHelpers);
																return (
																	<div
																		className={`contact-input-container flex mt--12 ${
																			values.alternate_contact?.length > 0
																				? ''
																				: 'hide'
																		}`}
																	>
																		<div className='contact-input-wrapper'>
																			{values.alternate_contact.map(
																				(_: any, fieldIndex: number) => {
																					return (
																						<div
																							key={fieldIndex}
																							className='flex width--full mb--12'
																						>
																							<ReactSignUpSelect
																								placeholder='+91'
																								options={countryCodes}
																								styles={CountyCodeStyle}
																								value={
																									values
																										.alternate_contact[
																										fieldIndex
																									].country_code
																										? {
																												label: values
																													.alternate_contact[
																													fieldIndex
																												]
																													.country_code,
																												value: values
																													.alternate_contact[
																													fieldIndex
																												]
																													.country_code
																										  }
																										: null
																								}
																								onChange={(
																									selectedValue: any
																								) => {
																									setFieldValue(
																										`alternate_contact[${fieldIndex}].country_code`,
																										selectedValue.value
																									);
																								}}
																							/>
																							<Input
																								name={`alternate_contact[${fieldIndex}].contact_number`}
																								type={type}
																								placeholder={
																									placeHolder
																								}
																								value={
																									values
																										.alternate_contact[
																										fieldIndex
																									].contact_number
																								}
																								classes={`number-input ${
																									touched[name] &&
																									errors[name]
																										? 'form-input--error'
																										: ''
																								}`}
																								onChange={({
																									target: { value }
																								}) =>
																									setFieldValue(
																										`alternate_contact[${fieldIndex}].contact_number`,
																										value
																									)
																								}
																							/>
																							<div className='delete-info-wrapper delete-contact-number'>
																								<div
																									className='delete-icon-wrapper'
																									onClick={() =>
																										arrayHelpers.remove(
																											fieldIndex
																										)
																									}
																								>
																									<DeleteIcon />
																								</div>
																							</div>
																						</div>
																					);
																				}
																			)}
																		</div>
																	</div>
																);
															}}
														</FieldArray>
													</>
												)}
												{name === 'email' && (
													<Input
														name={name}
														type={type}
														data-testid={name}
														placeholder={placeHolder}
														value={values[name]}
														classes={`mt--12 ${
															touched[name] && errors[name] ? 'form-input--error' : ''
														}`}
														onChange={({ target: { value } }) => setFieldValue(name, value)}
													/>
												)}
												{name === 'blood_group' && (
													<OptionCard
														optionList={BLOOD_GROUP}
														selectedOptions={values.blood_group}
														name={name}
														setFieldValue={setFieldValue}
														isMultiple={false}
													/>
												)}
											</label>
											<ErrorMessage name={name} component='p' className='error' />
										</div>
									)
								)}
								{(isTeacher ? JOINING_INFO_DETAILS : ADMISSION_INFO_DETAILS).map(
									({ label, name, isRequired, note }, index: number) => {
										return (
											<div key={index} className='details-input-wrapper mb--15'>
												<label className='font-size--sm font--semi-bold'>
													<p>
														{label}
														{isRequired ? (
															<span className='text--red'> *</span>
														) : (
															<span className='font-size--sm font--regular opacity--30 text--secondary'>
																(optional)
															</span>
														)}
													</p>
												</label>
												<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
													{note}
												</span>

												{name === 'house' ? (
													<OptionCard
														optionList={HOUSE_LIST}
														selectedOptions={selectedHouses}
														handleOptionSelection={handleHouseClick}
														name={name}
														setFieldValue={setFieldValue}
														tabWidth='big-tab'
														isMultiple={true}
													/>
												) : name === 'category' ? (
													<OptionCard
														optionList={PROFESSION}
														selectedOptions={values.category}
														name={name}
														setFieldValue={setFieldValue}
														isMultiple={false}
														tabWidth='width--85px'
													/>
												) : (
													<div className='mt--5'>
														<DatePicker
															icon={CustomCalendarIcon}
															name={name}
															showIcon
															readOnly={
																currentlyWorkingFlag && name === 'year_of_leaving'
															}
															calendarIconClassname='calendar-icon cursor--pointer'
															className={`form-input calendar-input ${
																currentlyWorkingFlag && name === 'year_of_leaving'
																	? 'disabled'
																	: ''
															}`}
															placeholderText='YYYY'
															selected={
																values[name] ? new Date(values[name], 0, 1) : null
															}
															maxDate={
																name === 'admission_year'
																	? TODAY
																	: new Date(moment(TODAY).add(7, 'year') as any)
															}
															minDate={
																name === 'admission_year'
																	? new Date('01-01-1900')
																	: new Date(`01-01-${values.admission_year}`)
															}
															showYearPicker
															shouldCloseOnSelect
															dateFormat='yyyy'
															onChange={(date: Date) => {
																const passingYear = moment(date).add(7, 'year').year();

																name === 'admission_year' &&
																	setFieldValue('passing_year', passingYear);

																setFieldValue(name, moment(date).year());
															}}
															disabledKeyboardNavigation
														/>
													</div>
												)}
												{name === 'year_of_leaving' && (
													<CheckBox
														title='Currently Working'
														checked={currentlyWorkingFlag}
														onChange={() => {
															setCurrentlyWorkingFlag(!currentlyWorkingFlag);
															setFieldValue('year_of_leaving', null);
														}}
													/>
												)}

												<ErrorMessage name={name} component='p' className='error' />
											</div>
										);
									}
								)}
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default PersonalDetails;
