import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, FieldArray, Form, Formik, FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import isUndefined from 'lodash/isUndefined';

import CustomButton, { IClassObj } from 'shared/components/customButton/customButton';
import { CustomCalendarIcon, DeleteIcon, PlusIcon } from 'shared/components/icons/icons';
import { educationInfoValidationSchema } from 'shared/constants/validation-schema';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import Input from 'shared/form/input';
import { TODAY } from 'shared/constants/constants';
import { notify } from 'shared/components/notification/notification';

import StepButtonComponent from './StepButtonComponent';
import { EducationInfoDetails } from '../constants/profile';
import { IEducationInfoData } from '../interface/profile';

import 'react-datepicker/dist/react-datepicker.css';

interface IEducationInfo {
	onPrevClick: () => void;
	handleNextClick: () => void;
	educationInfoData: IEducationInfoData[];
	isEdit: boolean;
	resetStepperBtnTrigger: () => void;
	isStepperBtnTrigger: boolean;
}

const EducationInfo: FC<IEducationInfo> = ({
	onPrevClick,
	handleNextClick,
	educationInfoData,
	isEdit,
	isStepperBtnTrigger,
	resetStepperBtnTrigger
}) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState({
		nextLoading: false,
		submitLoading: false
	});

	const handleSubmitAddressInfo = async (values: FormikValues, isNext: boolean) => {
		try {
			setLoading({ nextLoading: isNext, submitLoading: !isNext });
			await HttpService.post(API_CONFIG.path.addEducation, values);
			setLoading({ nextLoading: false, submitLoading: false });
			if (isEdit && isNext) {
				navigate('/profile/details');
			} else {
				handleNextClick();
			}
		} catch (err) {
			setLoading({ nextLoading: false, submitLoading: false });
			resetStepperBtnTrigger();
			console.error(err);
		}
	};

	const formRef: any = useRef();

	const isEmptyEducationDetails = () => {
		const otherEducationDetails = formRef.current?.values?.education || [];

		// Check if any alternate contact number has empty values
		const isEmptyStringPresent = otherEducationDetails.some((educationDetails: IClassObj) =>
			Object.values(educationDetails).some((value) => value === '')
		);

		return isEmptyStringPresent;
	};

	useEffect(() => {
		if (formRef.current && isStepperBtnTrigger) {
			const errorObj = formRef.current.errors.education;
			const formValues = formRef.current.values;

			if (isUndefined(errorObj)) {
				handleSubmitAddressInfo(formValues, false);
			} else {
				resetStepperBtnTrigger();
				notify(Object.values(errorObj.filter(Boolean)[0])[0] as string, 'error');
			}
		}
	}, [isStepperBtnTrigger]);

	return (
		<div className='details-wrapper'>
			<Formik
				innerRef={formRef}
				initialValues={{ education: educationInfoData }}
				onSubmit={() => {}}
				validationSchema={educationInfoValidationSchema}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ values, setFieldValue, touched, errors }: FormikValues) => (
					<Form className='form'>
						<FieldArray name='education'>
							{({ push, remove }) => (
								<div className='profile-container flex--column'>
									{values.education.length > 0 &&
										values.education.map((instituteInfo: string, index: number) => (
											<div key={index} className='institute-container'>
												<div className='flex justify-content--between align-content--center pb--10'>
													<p className='font-size--sm font--semi-bold opacity--50'>
														Education History {index + 1}
													</p>
													{index > 0 && (
														<div
															className='delete-icon-wrapper cursor--pointer'
															onClick={() => remove(index)}
														>
															<DeleteIcon />
														</div>
													)}
												</div>
												<div key={index} className='flex'>
													<div className='flex justify-content--between width--full education-field-wrapper'>
														{Object.entries(instituteInfo).map(
															([info, _], fieldIndex: number) => {
																const {
																	label,
																	name,
																	placeHolder,
																	isRequired,
																	type,
																	note
																} = EducationInfoDetails[info];
																const fieldError =
																	errors.education &&
																	errors.education[index] &&
																	errors.education[index][name];
																const fieldTouched =
																	touched.education &&
																	touched.education[index] &&
																	touched.education[index][name];
																return (
																	<div
																		key={fieldIndex}
																		className='details-input-wrapper mb--15'
																	>
																		<label className='font-size--sm font--semi-bold'>
																			<p>
																				{label}
																				{isRequired ? (
																					<span className='text--red'>
																						{' '}
																						*
																					</span>
																				) : (
																					<span className='font-size--sm font--regular opacity--30 text--secondary'>
																						(optional)
																					</span>
																				)}
																			</p>
																		</label>
																		<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
																			{note}
																		</span>

																		<div>
																			{name === 'institute_passout_year' ? (
																				<DatePicker
																					icon={CustomCalendarIcon}
																					name={`education[${index}].${name}`}
																					showIcon
																					calendarIconClassname='calendar-icon cursor--pointer'
																					className='form-input calendar-input'
																					placeholderText='YYYY'
																					selected={
																						values.education[index][name]
																							? new Date(
																									values.education[
																										index
																									][name],
																									0,
																									1
																							  )
																							: null
																					}
																					maxDate={TODAY}
																					showYearPicker
																					dateFormat='yyyy'
																					onChange={(date: Date) =>
																						setFieldValue(
																							`education[${index}].${name}`,
																							moment(date).year()
																						)
																					}
																					disabledKeyboardNavigation
																					shouldCloseOnSelect
																				/>
																			) : (
																				<Input
																					name={`education[${index}].${name}`}
																					type={type}
																					data-testid={name}
																					placeholder={placeHolder}
																					classes={`${
																						name ===
																						'institute_passout_year'
																							? 'calendar-input'
																							: ''
																					} ${
																						fieldError && fieldTouched
																							? 'form-input--error'
																							: ''
																					}`}
																					value={
																						values.education[index][name]
																					}
																					onChange={({ target: { value } }) =>
																						setFieldValue(
																							`education[${index}].${name}`,
																							value.indexOf(' ') === 0 ||
																								value.trim().length <= 0
																								? ''
																								: value
																						)
																					}
																				/>
																			)}
																		</div>
																		<ErrorMessage
																			name={`education[${index}].${name}`}
																			component='p'
																			className='error'
																		/>
																	</div>
																);
															}
														)}
													</div>
												</div>
											</div>
										))}
									<div className='justify-align--center mb--30 width--full'>
										<CustomButton
											disabled={isEmptyEducationDetails()}
											buttonType='button'
											buttonTitle='Add New Education'
											btnClassName='secondary-button add-education-button'
											ButtonIcon={PlusIcon}
											onButtonClick={() =>
												push({ field: '', institute_name: '', institute_passout_year: '' })
											}
										/>
									</div>
								</div>
							)}
						</FieldArray>

						<StepButtonComponent
							disabled={!isEmpty(errors)}
							nextLoading={loading.nextLoading}
							submitLoading={loading.submitLoading}
							isPrevButton={true}
							buttonTitle='Go to Profession section'
							onPrevClick={onPrevClick}
							nextButtonClick={(isNext: boolean) => handleSubmitAddressInfo(values, isNext)}
							isSave={isEdit}
						/>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default EducationInfo;
