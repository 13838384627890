import { FC } from 'react';
import { WrappedMap } from './googleMap';
import { GOOGLE_MAP_API_KEY } from 'shared/constants';
import { IGoogleMapProps, IMapProps } from '../interface/profile';

const GoogleMapModal: FC<IGoogleMapProps & IMapProps> = ({
	onLocationChange,
	initialLat,
	initialLng,
	isMapOpen,
	setFieldValue,
	setIsMapOpen,
	searchableAddress,
	setSearchableAddress
}) => {
	return (
		<div className='google-map-wrapper'>
			<WrappedMap
				googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: `100%`, display: 'flex', flexDirection: 'column-reverse' }} />}
				mapElement={<div style={{ height: `100%`, width: '100%' }} />}
				onLocationChange={onLocationChange}
				initialLat={initialLat}
				initialLng={initialLng}
				isMapOpen={isMapOpen}
				setFieldValue={setFieldValue}
				setIsMapOpen={setIsMapOpen}
				searchableAddress={searchableAddress}
				setSearchableAddress={setSearchableAddress}
			/>
		</div>
	);
};

export { GoogleMapModal };
