import { FC, useEffect } from 'react';
import {
	EducationFillIcon,
	EducationIcon,
	FamilyFillIcon,
	FamilyIcon,
	LocationFillIcon,
	LocationSignUpIcon,
	MemberShipFillIcon,
	MemberShipIcon,
	PersonalFillIcon,
	PersonalIcon,
	ProfessionFillIcon,
	ProfessionIcon
} from 'shared/components/icons/icons';

interface IProfileStepper {
	goSteps: number;
	filledSteps: number;
	onStepperClick: (step: number) => void;
}

const ProfileStepper: FC<IProfileStepper> = ({ goSteps, filledSteps, onStepperClick }) => {
	const StepperDetails = [
		{ svgClass: '', label: 'Personal', Icon: PersonalIcon, ActiveIcon: PersonalFillIcon },
		{ svgClass: '', label: 'Address', Icon: LocationSignUpIcon, ActiveIcon: LocationFillIcon },
		{ svgClass: '', label: 'Education', Icon: EducationIcon, ActiveIcon: EducationFillIcon },
		{ svgClass: 'profession-icon', label: 'Profession', Icon: ProfessionIcon, ActiveIcon: ProfessionFillIcon },
		{ svgClass: '', label: 'Family', Icon: FamilyIcon, ActiveIcon: FamilyFillIcon },
		{ svgClass: '', label: 'Membership', Icon: MemberShipIcon, ActiveIcon: MemberShipFillIcon }
	];

	const scrollToLeft = (divIndex: number) => {
		const scroller = document.querySelector('.profile-scroller');
		const scrollTo = document.getElementById(`${divIndex}`);
		if (scrollTo) {
			const leftPosition = scrollTo.offsetLeft;

			// Smooth scroll animation
			scroller?.scrollTo({
				left: leftPosition,
				behavior: 'smooth'
			});
		}
	};

	useEffect(() => {
		scrollToLeft(goSteps);
	}, [goSteps]);

	return (
		<div className='stepper-main-wrapper'>
			<div className='profile-scroller'>
				<div className='container flex justify-content--center stepper-inner-wrapper'>
					{StepperDetails.map(({ svgClass, label, Icon, ActiveIcon }, index) => (
						<div key={index} className='horizontal-stepper-wrapper' id={`${index}`}>
							<div
								className={`horizontal-stepper-box ${svgClass} ${
									goSteps === index ? 'horizontal-stepper-fill-box' : ''
								} ${filledSteps < index ? 'disabled-stepper' : ''}`}
								onClick={() => filledSteps >= index && onStepperClick(index)}
							>
								{filledSteps < index ? <Icon /> : <ActiveIcon />}
								{index + 1 !== StepperDetails.length && (
									<div className={`stepper-line ${goSteps > index ? 'stepper-line--active' : ''}`} />
								)}
							</div>
							<div className={`stepper-hr ${goSteps === index ? 'stepper-hr--active' : ''}`} />
							<p className={`stepper-title ${goSteps === index ? 'stepper-title--active' : ''}`}>
								{label}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProfileStepper;
