import { FC } from 'react';
import Lottie from 'lottie-react';

import ST_LOGO from 'assets/animations/ST_Logo.json';
import './spinner.scss';

//Spinner - render spinner
const style = {
	height: 200
};

const Loader: FC<{ className?: string }> = () => {
	return (
		<div className='loader-wrapper'>
			<span className='new-loader' />
		</div>
	);
};

//Spinner - render spinner
const Spinner: FC = () => {
	return (
		<div className='st-loader-wrapper'>
			<Lottie animationData={ST_LOGO} style={style} />
		</div>
	);
};

export { Loader };
export default Spinner;
