import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';

import ReactSignUpSelect from 'shared/form/reactSignUpSelect';
import { professionInfoValidationSchema } from 'shared/constants/validation-schema';
import Input from 'shared/form/input';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { notify } from 'shared/components/notification/notification';

import StepButtonComponent from './StepButtonComponent';
import { WorkType, professionInfoDetails } from '../constants/profile';
import { IProfessionInfoData } from '../interface/profile';

interface IProfessionInfo {
	onPrevClick: () => void;
	handleNextClick: () => void;
	professionInfoData: IProfessionInfoData;
	isEdit: boolean;
	resetStepperBtnTrigger: () => void;
	isStepperBtnTrigger: boolean;
}

const ProfessionInfo: FC<IProfessionInfo> = ({
	onPrevClick,
	handleNextClick,
	professionInfoData,
	isEdit,
	isStepperBtnTrigger,
	resetStepperBtnTrigger
}) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState({
		nextLoading: false,
		submitLoading: false
	});

	const handleSubmitProfessionInfo = async (values: FormikValues, isNext: boolean) => {
		try {
			setLoading({ nextLoading: isNext, submitLoading: !isNext });
			const params = { ...values, location: values.location ?? '' };

			await HttpService.post(API_CONFIG.path.addProfessionInfo, params);
			setLoading({ nextLoading: false, submitLoading: false });
			if (isEdit && isNext) {
				navigate('/profile/details');
			} else {
				handleNextClick();
			}
		} catch (err) {
			setLoading({ nextLoading: false, submitLoading: false });
			resetStepperBtnTrigger();
			console.error(err);
		}
	};

	const formRef: any = useRef();

	useEffect(() => {
		if (formRef.current && isStepperBtnTrigger) {
			const errorObj = formRef.current.errors;
			const formValues = formRef.current.values;
			if (isEmpty(errorObj)) {
				handleSubmitProfessionInfo(formValues, false);
			} else {
				notify(Object.values(errorObj)[0] as string, 'error');
				resetStepperBtnTrigger();
			}
		}
	}, [isStepperBtnTrigger]);

	return (
		<div className='details-wrapper'>
			<Formik
				innerRef={formRef}
				initialValues={professionInfoData}
				onSubmit={() => {}}
				validationSchema={professionInfoValidationSchema}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ touched, errors, setFieldValue, values }: FormikValues) => (
					<Form className='form'>
						<div className='profile-container'>
							{professionInfoDetails.map(
								({ label, name, placeHolder, isRequired, type, note }, index: number) => {
									return (
										<div key={index} className='details-input-wrapper mb--15'>
											<label className='font-size--sm font--semi-bold'>
												<p>
													{label}
													{isRequired ? (
														<span className='text--red'> *</span>
													) : (
														<span className='font-size--sm font--regular opacity--30 text--secondary'>
															(optional)
														</span>
													)}
												</p>
												<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
													{note}
												</span>
												{name === 'work_type' ? (
													<div className='mt--12'>
														<ReactSignUpSelect
															isSearchable={false}
															placeholder={placeHolder}
															options={WorkType}
															defaultValue={
																values[name]
																	? {
																			label: values[name],
																			value: values[name]
																	  }
																	: null
															}
															onChange={(value: any) => setFieldValue(name, value.value)}
														/>
													</div>
												) : (
													<Input
														name={name}
														type={type}
														data-testid={name}
														placeholder={placeHolder}
														classes={`mt--12 ${
															touched[name] && errors[name] ? 'form-input--error' : ''
														}`}
														value={values[name]}
														onChange={({ target: { value } }) =>
															setFieldValue(
																name,
																value.indexOf(' ') === 0 || value.trim().length <= 0
																	? ''
																	: value
															)
														}
														autoComplete='off'
													/>
												)}
											</label>
											<ErrorMessage name={name} component='p' className='error' />
										</div>
									);
								}
							)}
						</div>

						<StepButtonComponent
							disabled={!isEmpty(errors)}
							nextLoading={loading.nextLoading}
							submitLoading={loading.submitLoading}
							isPrevButton={true}
							buttonTitle='2 steps to complete'
							onPrevClick={onPrevClick}
							nextButtonClick={(isNext: boolean) => handleSubmitProfessionInfo(values, isNext)}
							isSave={isEdit}
						/>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ProfessionInfo;
