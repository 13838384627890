import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { API_CONFIG } from 'shared/constants/api';
import { addressInfoValidationSchema } from 'shared/constants/validation-schema';
import Input from 'shared/form/input';
import ReactSignUpSelect from 'shared/form/reactSignUpSelect';
import HttpService from 'shared/services/http.service';
import { notify } from 'shared/components/notification/notification';

import { GoogleMapModal } from './GoogleMapModal';
import StepButtonComponent from './StepButtonComponent';
import { AddressInfoDetails } from '../constants/profile';
import { IAddressInfoData, ICountryRes, IOptions, ISetMapProps, IStateInfoRes } from '../interface/profile';
interface IAddressInfo {
	onPrevClick: () => void;
	handleNextClick: () => void;
	addressInfoData: IAddressInfoData;
	isEdit: boolean;
	resetStepperBtnTrigger: () => void;
	isStepperBtnTrigger: boolean;
}

const AddressInfo: FC<IAddressInfo> = ({
	onPrevClick,
	handleNextClick,
	addressInfoData,
	isEdit,
	isStepperBtnTrigger,
	resetStepperBtnTrigger
}) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState({
		nextLoading: false,
		submitLoading: false
	});
	const [countryDetails, setCountryDetails] = useState<IOptions[]>([]);
	const [stateList, setStateList] = useState<IOptions[]>([]);
	const [selectedCountry, setSelectedCountry] = useState(addressInfoData.current_country || '');
	const [isMapOpen, setIsMapOpen] = useState<ISetMapProps>({ open: false, name: '' });
	const [location, setLocation] = useState({
		lat: Number(addressInfoData.current_latitude) || 23.0225,
		lng: Number(addressInfoData.current_longitude) || 72.5714
	}); // Default to Ahmedabad
	const [searchableLocation, setSearchableLocation] = useState(addressInfoData.location || '');

	const handleLocationChange = (lat: number, lng: number) => {
		setLocation({ lat, lng });
	};

	const getCountryDetails = async () => {
		try {
			await HttpService.get(API_CONFIG.path.country).then((response) => {
				const optionArr: IOptions[] = [];
				response.forEach((country: ICountryRes) => {
					optionArr.push({ label: country.name, value: country.id });
				});
				setCountryDetails(optionArr);
			});
		} catch (err) {
			console.error(err);
			resetStepperBtnTrigger();
		}
	};

	// Fetch and set state list based on selected country
	const getStateList = async (countryName: string) => {
		try {
			await HttpService.get(`${API_CONFIG.path.stateList}/${countryName}`).then((response) => {
				const optionArr: IOptions[] = [];
				response.forEach((state: IStateInfoRes) => {
					optionArr.push({ label: state.name, value: state.id });
				});
				setStateList(optionArr);
			});
		} catch (err) {
			console.error(err);
			resetStepperBtnTrigger();
		}
	};

	const handleSubmitAddressInfo = async (values: FormikValues, isNext: boolean) => {
		try {
			setLoading({ nextLoading: isNext, submitLoading: !isNext });
			const params = {
				...values,
				current_latitude: String(location.lat),
				current_longitude: String(location.lng),
				location: searchableLocation
			};
			await HttpService.post(API_CONFIG.path.addAddress, params);
			setLoading({ nextLoading: false, submitLoading: false });
			if (isEdit && isNext) {
				navigate('/profile/details');
			} else {
				handleNextClick();
			}
		} catch (err) {
			setLoading({ nextLoading: false, submitLoading: false });
			resetStepperBtnTrigger();
			console.error(err);
		}
	};

	const formRef: any = useRef();

	useEffect(() => {
		if (formRef.current && isStepperBtnTrigger) {
			const errorObj = formRef.current.errors;
			const formValues = formRef.current.values;
			if (isEmpty(errorObj)) {
				handleSubmitAddressInfo(formValues, false);
			} else {
				notify(Object.values(errorObj)[0] as string, 'error');
				resetStepperBtnTrigger();
			}
		}
	}, [isStepperBtnTrigger]);

	useEffect(() => {
		getCountryDetails();
	}, []);

	useEffect(() => {
		selectedCountry && getStateList(selectedCountry);
	}, [selectedCountry]);

	return (
		<div className='details-wrapper'>
			<Formik
				innerRef={formRef}
				initialValues={addressInfoData}
				onSubmit={() => {}}
				validationSchema={addressInfoValidationSchema}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ touched, errors, setFieldValue, values }: FormikValues) => (
					<Form className='form'>
						<div className='profile-container'>
							{AddressInfoDetails.map(
								({ label, name, placeHolder, isRequired, type, note }, index: number) => {
									return (
										<div key={index} className='details-input-wrapper mb--15'>
											<label className='font-size--sm font--semi-bold'>
												<p>
													{label}
													{isRequired ? (
														<span className='text--red'> *</span>
													) : (
														<span className='font-size--sm font--regular opacity--30 text--secondary'>
															(optional)
														</span>
													)}
												</p>
												<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
													{note}
												</span>
												{name === 'address' ? (
													<textarea
														name={name}
														data-testid={name}
														placeholder={placeHolder}
														className={`form-input textarea mt--12 ${
															touched[name] && errors[name] ? 'form-input--error' : ''
														}`}
														value={values[name]}
														onChange={(e) => setFieldValue(name, e.target.value)}
													/>
												) : name === 'current_state' || name === 'current_country' ? (
													<div className='mt--12'>
														<ReactSignUpSelect
															placeholder={placeHolder}
															options={
																name === 'current_country' ? countryDetails : stateList
															}
															defaultValue={
																values[name] && {
																	label: values[name],
																	value: values[name]
																}
															}
															onChange={(value: any) => {
																const selectedLabel = value ? value.label : ''; // Check if value is truthy, otherwise set an empty string
																setFieldValue(name, selectedLabel);
																name === 'current_country' &&
																	setSelectedCountry(selectedLabel);
															}}
														/>
													</div>
												) : (
													<Input
														name={name}
														type={type}
														data-testid={name}
														placeholder={placeHolder}
														classes={`mt--12 ${
															touched[name] && errors[name] ? 'form-input--error' : ''
														}`}
														value={values[name]}
														onChange={({ target: { value } }) =>
															setFieldValue(
																name,
																value.indexOf(' ') === 0 || value.trim().length <= 0
																	? ''
																	: value
															)
														}
													/>
												)}
											</label>
											<ErrorMessage name={name} component='p' className='error' />
										</div>
									);
								}
							)}
							<div className='details-input-wrapper mb--15'>
								<label className='font-size--sm font--semi-bold'>
									<p>Location</p>
									<GoogleMapModal
										isMapOpen={isMapOpen}
										setIsMapOpen={setIsMapOpen}
										setFieldValue={setFieldValue}
										onLocationChange={handleLocationChange}
										initialLat={location.lat}
										initialLng={location.lng}
										searchableAddress={searchableLocation}
										setSearchableAddress={(searchableAddress) =>
											setSearchableLocation(searchableAddress)
										}
									/>
								</label>
								<ErrorMessage name='location' component='p' className='error' />
							</div>
						</div>

						<StepButtonComponent
							disabled={!isEmpty(errors)}
							nextLoading={loading.nextLoading}
							submitLoading={loading.submitLoading}
							isPrevButton={true}
							buttonTitle='Go to Education section'
							onPrevClick={onPrevClick}
							nextButtonClick={(isNext: boolean) => handleSubmitAddressInfo(values, isNext)}
							isSave={isEdit}
						/>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default AddressInfo;
