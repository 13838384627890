import Profile from 'features/profile/container/profile';
import Notification from 'features/setting/components/notification';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from 'shared/components/footer/footer';
import ScaletechFooter from 'shared/components/footer/scaletechFooter';
import Header from 'shared/components/header/components/header';
import { HIDE_FOOTER, SETTING_TABS } from 'shared/constants/constants';
import UserDetailsStore from 'store/context/userDetailStore';
import UserDashboardDetailsStore from 'store/context/userDashboardStore';

const Layout: FC<PropsWithChildren> = ({ children }) => {
	const { pathname } = useLocation();
	const isShowScaletechFooter = SETTING_TABS.includes(pathname);

	const isHideFooter = useMemo(() => !HIDE_FOOTER.includes(pathname), [pathname]);

	return (
		<UserDetailsStore>
			<UserDashboardDetailsStore>
				<div>
					<Header />
					<div
						className='main-content-wrapper'
						style={{ maxHeight: `${isHideFooter ? 'calc(100vh - 75px)' : '100%'}` }}
					>
						{children}
					</div>
					{}
					{isHideFooter && <>{isShowScaletechFooter ? <ScaletechFooter /> : <Footer />}</>}
				</div>
				<Profile />
				<Notification />
			</UserDashboardDetailsStore>
		</UserDetailsStore>
	);
};

export default Layout;
