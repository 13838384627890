import { FC, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import GoogleMap from 'shared/components/map/map';
import { API_CONFIG } from 'shared/constants/api';
import HttpService from 'shared/services/http.service';
import { useGetUserLocation } from 'shared/hooks/useGetUserLocation';

import AlumniMarkers from '../component/alumniMarker';
import { IAlumniItem, IAlumniList } from '../interface/nearByMap';

const recordPerPage = 30;
const defaultRadius = 5;

const NearByMap: FC = () => {
	const [alumniList, setAlumniList] = useState<IAlumniList[]>([]);
	const [infoWindow, setInfoWindow] = useState({} as IAlumniList);
	const userLocation = useGetUserLocation();

	const fetchNearByAlumni = async (radius: number, center: { lat: number; lng: number }) => {
		try {
			const alumniList = await HttpService.get(`${API_CONFIG.path.nearByAlumni}`, {
				radius,
				record_per_page: recordPerPage,
				lat: center.lat.toString(),
				lng: center.lng.toString()
			});

			if (alumniList && alumniList.data) {
				const list = alumniList.data.map((i: IAlumniItem) => ({
					key: i.id,
					name: `${i.first_name} ${i.last_name}`,
					lat: Number(i.current_latitude),
					lng: Number(i.current_longitude),
					designation: i.profession.designation ?? '',
					organization: i.profession.organization ?? '',
					contact: `${i.country_code} ${i.contact_number}`,
					email: i.email ?? '-',
					profile_photo: (i.thumbnail_photo || i.profile_photo) ?? '-'
				}));

				// const countMap: any = {};

				// // subtract 0.001 from lng if item has same lag and lng
				// list.forEach((item: any) => {
				// 	const { lat, lng } = item;
				// 	const key = `${lat}-${lng}`;

				// 	if (countMap[key] === undefined) {
				// 		countMap[key] = 0;
				// 	} else {
				// 		countMap[key]++;
				// 		item.lng = parseFloat((lng - countMap[key] * 0.001).toFixed(6));
				// 	}
				// });

				setAlumniList((preList) => {
					const i = preList.length;
					const allDat = [...preList.slice(i - 30, i), ...list];
					const uniq = _.uniqBy(allDat, 'key');
					return uniq;
				});
				// setAlumniList(list);
			}
		} catch (error) {
			console.error(error);
		}
	};

	// const handleZoomChange = useCallback((zoom: number) => {
	// 	if (zoom > 11) {
	// 		return;
	// 	}
	// // Adjust radius based on zoom level
	// const newRadius = Math.round(30 * Math.pow(2, 11 - zoom));
	// fetchNearByAlumni(newRadius);
	// }, []);

	const onCenterChange = useCallback((center: { lat: number; lng: number }) => {
		fetchNearByAlumni(defaultRadius, center);
	}, []);

	useEffect(() => {
		if (userLocation) {
			fetchNearByAlumni(defaultRadius, userLocation);
		}
	}, [userLocation]);

	return (
		<GoogleMap
			infoWindow={infoWindow}
			markers={<AlumniMarkers points={alumniList} setInfoWindow={setInfoWindow} />}
			defaultLocation={userLocation}
			onCenterChange={onCenterChange}
			onOutsideClick={() => setInfoWindow({} as IAlumniList)}
		/>
	);
};

export default NearByMap;
