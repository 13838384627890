import { useNavigate } from 'react-router-dom';
import { FC, useContext, useEffect, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import ReactWhatsapp from 'react-whatsapp';

import { CakeIcon, MobileIcon, OtherUpdateIcon, WPIcon } from 'shared/components/icons/icons';
import AuthService from 'shared/services/auth.service';
import { Loader } from 'shared/components/spinner/spinner';
import { DashboardDetailsContext } from 'store/context/userDashboardStore';
import { capitalizeWords } from 'shared/util/utility';
import { IUserData, ROLES } from 'features/auth/interface/auth';
import { TYPE_IMGS, TYPE_TITLES_COLORS } from '../constants/dashbaord';

import 'swiper/css';
import 'swiper/css/pagination';

const AlumniUpdates: FC = () => {
	const navigate = useNavigate();
	const userData = AuthService.getAuthData() as IUserData;
	const isTeacher = useMemo(() => ROLES.TEACHER === userData.role, [userData]);

	const { birthdayLoading, birthdayList, otherNotificationList, fetchAlumniUpdates } =
		useContext(DashboardDetailsContext);

	useEffect(() => {
		fetchAlumniUpdates(false);
	}, []);

	return (
		<div className='updates-wrapper'>
			{birthdayLoading && isTeacher && (
				<div className='justify-align--center width--full pt--20 pb--20'>
					<Loader />
				</div>
			)}
			{!birthdayLoading && birthdayList.length > 0 && (
				<>
					<div className='flex align-items--center'>
						<p className='title'>Birthday Today({birthdayList.length})</p>
						<CakeIcon className='ml--10' />
					</div>
					<div className='swiper-wrapper'>
						<Swiper
							cssMode={true}
							spaceBetween={10}
							centeredSlides={true}
							className='alumni-update-swiper'
							direction={'horizontal'}
							mousewheel={true}
							freeMode={true}
							loop={true}
							modules={[Pagination]}
							pagination={{
								clickable: true
							}}
							speed={1000}
						>
							{birthdayList.map(
								({
									first_name,
									last_name,
									id,
									profile_photo,
									roll_number,
									country_code,
									contact_number
								}) => (
									<SwiperSlide key={id} className='position--relative birthday-alumni-wrapper'>
										<div
											className='flex align-items--center'
											onClick={() => navigate(`/home?userId=${id}`)}
										>
											<img className='alumni-img' src={profile_photo} alt='profile' />
											<div className='birthday-alumni-details flex justify-content--between align-items--center'>
												<div>
													<p className='alumni-name truncate--text'>
														{capitalizeWords(`${first_name} ${last_name}`)}
													</p>
													<p className='alumni-number'>{roll_number}</p>
												</div>
												<div className='flex align-items--center'>
													<ReactWhatsapp
														number={`${country_code}${contact_number}`}
														element='webview'
														className='contact-wrapper mr--20'
														message={`Happy Birthday ${first_name} ${last_name}`}
														onClick={(e) => e.stopPropagation()}
													>
														<WPIcon className='wp-icon' />
													</ReactWhatsapp>
													<a
														className='contact-wrapper'
														href={`tel:${country_code}${contact_number}`}
														onClick={(e) => e.stopPropagation()}
													>
														<MobileIcon className='mobile-icon' />
													</a>
												</div>
											</div>
										</div>
									</SwiperSlide>
								)
							)}
						</Swiper>
					</div>
				</>
			)}
			{!birthdayLoading && otherNotificationList.length > 0 && (
				<div className='other-update'>
					<p className='title'>Other Updates</p>
					<div className='swiper-wrapper'>
						<Swiper
							cssMode={true}
							centeredSlides
							spaceBetween={10}
							className='alumni-update-swiper'
							direction={'horizontal'}
							mousewheel={true}
							freeMode={true}
							loop={true}
							modules={[Pagination]}
							pagination={{
								clickable: true
							}}
							speed={1000}
						>
							{otherNotificationList.map(({ type, message }, index: number) => {
								const Icon = TYPE_IMGS[type as keyof typeof TYPE_IMGS] || OtherUpdateIcon;
								return (
									<SwiperSlide key={index} className='position--relative birthday-alumni-wrapper'>
										<div className='flex align-items--start'>
											<div className='update-img-wrapper'>
												<Icon />
											</div>
											<div className='birthday-alumni-details flex flex--column justify-content--between pt--5 pb--5'>
												<p
													className='news-type'
													style={{
														background: `${
															TYPE_TITLES_COLORS[
																type as keyof typeof TYPE_TITLES_COLORS
															] || '#e9eff3'
														}`
													}}
												>
													{type}
												</p>
												<p className='alumni-number'>{message}</p>
											</div>
										</div>
									</SwiperSlide>
								);
							})}
						</Swiper>
					</div>
				</div>
			)}
			{!birthdayLoading && otherNotificationList.length === 0 && (
				<div className='other-update'>
					<p className='title'>Other Updates</p>
					<div className='swiper-wrapper'>
						<div className='alumni-update-swiper'>
							<div className='birthday-alumni-wrapper'>
								<div className='flex align-items--start'>
									<div className='update-img-wrapper'>
										<OtherUpdateIcon />
									</div>
									<div className='birthday-alumni-details pt--5 pb--5'>
										{/* <p className={`news-type news-type--Other`}>{TYPE_TITLES['Other']}</p> */}
										<p className='alumni-number'>
											Relax today is a calming day. More news coming soon.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AlumniUpdates;
