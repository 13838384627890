import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ErrorHandler from 'hoc/withErrorHandler/errorHandler';
import ErrorBoundary from 'shared/components/errorBoundary/errorBoundary';
import { usePushNotification } from 'shared/hooks/usePushNotification';

import App from './app';
import store from './store';

import 'assets/styles/app.scss';

// Extending the global Window interface to include the subscription property.
declare global {
	interface Window {
		subscription: PushSubscription;
	}
}

const Root: React.FC = () => {
	const { fcmToken, requestUserPermission } = usePushNotification();
	return (
		<Provider store={store}>
			<ErrorHandler />
			<ErrorBoundary>
				<BrowserRouter>
					<div onClick={requestUserPermission}>
						<App fcmToken={fcmToken} />
					</div>
				</BrowserRouter>
			</ErrorBoundary>
		</Provider>
	);
};

export default Root;
