import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

export const baseAPiVersion = 'api/v1';

export const API_CONFIG = {
	baseUrl: process.env['REACT_APP_BASE_URL'],
	path: {
		login: 'admin/web/login',
		loginToken: 'admin/web/add-login-token',
		nearByAlumni: 'alumni/near-by',
		featuredMemory: 'batch-gallery/featured-memory',
		batchMemory: 'batch-gallery/batch-memory',
		alumni: 'alumni/search',
		profile: 'alumni',
		notification: 'notification',
		notificationSetting: 'notification/notification-setting',
		logout: 'alumni/logout',
		postSuggestion: 'alumni/contact-us',
		profilePresignUrl: 'alumni/presign-url',
		AddUserProfilePhoto: 'alumni/add-user-profile-photo',
		birthdayList: 'alumni/birthday-list',
		addDeviceToken: 'auth/add-device-token',
		// constitutionText: 'cms-pages',
		constitution: 'constitution',
		//SignUp
		country: 'admin/alumni/country',
		stateList: 'admin/alumni/state',
		addAddress: 'admin/web/address',
		addEducation: 'admin/web/education',
		addProfessionInfo: 'admin/web/profession',
		addFamilyInfo: 'admin/web/family',
		membershipInfo: 'admin/web/membership',
		updateProfilePic: 'alumni/add-user-profile-photo',
		addAlumni: 'admin/web',
		avatarPreSignedUrl: 'alumni/presign-url'
	}
};

export const getUrl = (url: string, params: any = {}): string => {
	Object.keys(params).forEach((key) => (params[key] == null || params[key] === '') && delete params[key]);
	let urlString = `${url}`;
	if (params && !isEmpty(params)) {
		urlString += `?${queryString.stringify(params)}`;
	}
	return urlString;
};
