import { FC } from 'react';
import { Loader } from '../spinner/spinner';
import { IIconProps } from '../icons/icons';

interface IButtonProps {
	buttonTitle: string;
	btnClassName: string;
	isSubmitLoading?: boolean;
	disabled?: boolean;
	ButtonIcon?: FC<IIconProps> | string;
	onButtonClick?: () => void;
	buttonType?: 'button' | 'submit' | 'reset' | undefined;
}

export interface IClassObj {
	[key: string]: string | number;
}

const loadingClass: IClassObj = {
	'primary-button': 'text--white',
	'secondary-button': 'text--black'
};

const CustomButton = ({
	buttonTitle,
	btnClassName,
	isSubmitLoading,
	disabled,
	ButtonIcon,
	onButtonClick,
	buttonType
}: IButtonProps) => {
	return (
		<button
			className={`${btnClassName || ''}`}
			type={buttonType || 'submit'}
			data-testid='submit'
			onClick={onButtonClick}
			disabled={disabled || false}
		>
			{isSubmitLoading ? (
				<Loader className={(loadingClass[btnClassName] as string) || 'text--white'} />
			) : (
				<>
					{buttonTitle}
					{ButtonIcon ? <ButtonIcon className='ml--5 mr--5' /> : ''}
				</>
			)}
		</button>
	);
};

export default CustomButton;
