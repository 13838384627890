import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import CustomButton from 'shared/components/customButton/customButton';
import { LogoutIcon } from 'shared/components/icons/icons';
import { API_CONFIG } from 'shared/constants/api';
import AuthService from 'shared/services/auth.service';
import HttpService from 'shared/services/http.service';
import { createAction } from 'shared/util/utility';
import * as actionTypes from 'store/actionTypes';

const LogoutPopup: FC<{ onClose: () => void }> = ({ onClose }) => {
	const dispatch = useDispatch();

	const logout = useCallback(async () => {
		try {
			await HttpService.get(`${API_CONFIG.path.logout}`);
			AuthService.removeAuthData();
			dispatch(createAction(actionTypes.LOGOUT));
		} catch (error) {
			console.error(error);
		}
	}, []);

	return (
		<div
			className='modal-wrapper'
			onClick={(e) => {
				e.preventDefault();
				onClose();
			}}
		>
			<div className='logout-wrapper'>
				<LogoutIcon className='mb--10' />
				<p className='font--semi-bold mb--30 font-size--xxl'>Account Logout</p>
				<p className='logout-sub--title'>Are you sure you want to logout of your account?</p>
				<div className='flex align-items--center justify-content--between ml--20 mr--20'>
					<CustomButton btnClassName='primary-button mr--10' buttonTitle='Cancel' onButtonClick={onClose} />
					<CustomButton
						btnClassName='secondary-button logout-button ml--10'
						buttonTitle='Logout'
						onButtonClick={logout}
					/>
				</div>
			</div>
		</div>
	);
};

export default LogoutPopup;
