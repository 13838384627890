import React from 'react';
import Select from 'react-select';
import { reactSelectSignUpStyles } from './reactSelectSignUpStyles';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

const ReactSignUpSelect: React.FC<StateManagerProps> = (props) => {
	const { styles } = props;

	return <Select {...props} styles={{ ...reactSelectSignUpStyles, ...(styles ?? reactSelectSignUpStyles) } as any} />;
};

export default ReactSignUpSelect;
