import * as Yup from 'yup';

const loginForm = {
	country_code: Yup.string().required('Please enter Country Code').strict(true),
	contact_number: Yup.string().required('Please enter Contact number').strict(true)
};
const studentLoginForm = Yup.object().shape({
	...loginForm,
	roll_number: Yup.string().required('Please enter Roll number').strict(true)
});
const teacherLoginForm = Yup.object().shape(loginForm);

const personalInfoValidationSchema = Yup.object().shape({
	first_name: Yup.string().required('Please enter first name.'),
	last_name: Yup.string().required('Please enter last name.'),
	birthdate: Yup.string().required('Please enter date of birth.'),
	contact_number: Yup.string().required('Please enter contact number.'),
	country_code: Yup.string().required('Please enter country code.'),
	admission_year: Yup.string()
		.required('Please enter admission year.')
		.typeError('Invalid date format. Please use yyyy format.'),
	passing_year: Yup.string()
		.required('Please enter passing year.')
		.typeError('Invalid date format. Please use yyyy format.'),
	house: Yup.array().required('Please select house.')
});

const personalInfoValidation = {
	first_name: Yup.string().required('Please enter first name.'),
	last_name: Yup.string().required('Please enter last name.'),
	birthdate: Yup.string().required('Please enter date of birth.'),
	contact_number: Yup.string().required('Please enter contact number.'),
	country_code: Yup.string().required('Please enter country code.')
};

const alumniForm = Yup.object().shape({
	...personalInfoValidation,
	admission_year: Yup.string()
		.required('Please enter admission year.')
		.typeError('Invalid date format. Please use yyyy format.'),
	passing_year: Yup.string()
		.required('Please enter passing year.')
		.typeError('Invalid date format. Please use yyyy format.'),
	house: Yup.array().required('Please select house.')
});

const teacherCurrentTeacherForm = Yup.object().shape({
	...personalInfoValidation,
	year_of_joining: Yup.string()
		.required('Please enter joining year.')
		.typeError('Invalid date format. Please use yyyy format.'),
	category: Yup.string().required('Please select category.')
});

const teacherForm = Yup.object().shape({
	...personalInfoValidation,
	year_of_joining: Yup.string()
		.required('Please enter joining year.')
		.typeError('Invalid date format. Please use yyyy format.'),
	category: Yup.string().required('Please select category.'),
	year_of_leaving: Yup.string()
		.required('Please enter leaving year.')
		.typeError('Invalid date format. Please use yyyy format.')
});

const addressInfoValidationSchema = Yup.object().shape({
	address: Yup.string().required('Please enter your address.'),
	current_zipcode: Yup.string().required('Please enter zip code.'),
	current_city: Yup.string().required('Please enter city.'),
	current_country: Yup.string().required('Please enter county.')
});

const professionInfoValidationSchema = Yup.object().shape({
	work_type: Yup.string().required('Please enter your work type.'),
	organization: Yup.string().required('Please enter your organization.'),
	designation: Yup.string().required('Please enter your designation.')
});

const educationInfoValidationSchema = Yup.object().shape({
	education: Yup.array().of(
		Yup.object().shape({
			institute_name: Yup.string().required('Please enter institute name.'),
			field: Yup.string().required('Please enter your degree.'),
			institute_passout_year: Yup.date()
				.required('Please enter your passing year.')
				.typeError('Invalid date format. Please use dd-mm-yyyy format.')
		})
	)
});

const familyInfoValidationSchema = Yup.object().shape({
	family: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required('Please enter your family member name.'),
			relation: Yup.string().required('Please enter your relationship with them.'),
			birthdate: Yup.date()
				.required('Please enter date or select it by clicking on the calendar.')
				.typeError('Invalid date format. Please use dd-mm-yyyy format.')
		})
	)
});

export {
	studentLoginForm,
	teacherLoginForm,
	personalInfoValidationSchema,
	addressInfoValidationSchema,
	professionInfoValidationSchema,
	educationInfoValidationSchema,
	familyInfoValidationSchema,
	alumniForm,
	teacherForm,
	teacherCurrentTeacherForm
};
