import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { CloseIcon, EditIcon } from 'shared/components/icons/icons';

const ProfilePic: FC<{ url: string; setIsUploadProfile?: () => void; isEdit: boolean; thumbnailUrl?: string }> = ({
	url,
	isEdit,
	thumbnailUrl,
	setIsUploadProfile
}) => {
	const [showFullProfile, setShowFullProfile] = useState(false);

	useEffect(() => {
		if (showFullProfile) {
			document.body.style.overflow = 'hidden';
		}

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [showFullProfile]);
	const container = document.getElementById('root') as HTMLElement;

	return (
		<>
			<div className='flex profile-pic-wrapper position--relative cursor--pointer'>
				<img
					src={thumbnailUrl || url}
					alt='profile'
					className='profile-image'
					onClick={() => setShowFullProfile(!showFullProfile)}
				/>
				{!showFullProfile && isEdit && (
					<div className='profile-edit-btn' onClick={setIsUploadProfile}>
						<EditIcon className='edit-icon' />
					</div>
				)}
			</div>
			{showFullProfile &&
				createPortal(
					<div
						className='profile-pic-full-wrapper cursor--pointer'
						onClick={() => setShowFullProfile(!showFullProfile)}
					>
						<img src={url} alt='profile' className='profile-image' />
						<CloseIcon className='close--icon' />
					</div>,
					container
				)}
		</>
	);
};

export default ProfilePic;
