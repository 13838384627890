import { FC } from 'react';
import AuthService from 'shared/services/auth.service';
import { IUserData } from 'features/auth/interface/auth';
import Logo from 'assets/images/logo.png';

interface IHeader {
	headerClassName?: string;
}

const SignUpHeader: FC<IHeader> = ({ headerClassName }) => {
	const userDetails = (AuthService.getAuthData() as IUserData) || {};

	return (
		<div className='auth-header-outer-wrapper flex justify-content--between align-items--center width--full'>
			<div className={`${headerClassName || ''} auth-header-wrapper`}>
				<div className='logo-container auth-header'>
					<img src={Logo} alt='/logo' className='logo mr--10' />
					<p className='title user-select--none'>OBSSA</p>
				</div>
			</div>
			{userDetails?.roll_number && (
				<div className='justify-align--center roll-number-wrapper'>
					<p className='title'>Roll No:</p>
					<p className='roll-no'>{userDetails?.roll_number}</p>
				</div>
			)}
		</div>
	);
};

export default SignUpHeader;
