import {
	EducationFillIcon,
	EducationIcon,
	FamilyFillIcon,
	FamilyIcon,
	LocationFillIcon,
	LocationSignUpIcon,
	MemberShipFillIcon,
	MemberShipIcon,
	PersonalFillIcon,
	PersonalIcon,
	ProfessionFillIcon,
	ProfessionIcon
} from 'shared/components/icons/icons';

const EducationLabelMapper = {
	field: 'Degree',
	institute_name: 'College',
	institute_passout_year: 'Passing Year'
};

const FamilyLabelMapper = {
	blood_group: 'Blood Group'
};

const PersonalInfo = 'Personal Information';
const AddressInfo = 'Address Information';
const EducationInfo = 'Education Information';
const ProfessionInfo = 'Profession Information';
const FamilyDetails = 'Family Details';
const MembershipStatus = 'Membership Status';

const StepperDetails: any = {
	[PersonalInfo]: { Icon: PersonalIcon, ActiveIcon: PersonalFillIcon },
	[AddressInfo]: { Icon: LocationSignUpIcon, ActiveIcon: LocationFillIcon },
	[EducationInfo]: { Icon: EducationIcon, ActiveIcon: EducationFillIcon },
	[ProfessionInfo]: { Icon: ProfessionIcon, ActiveIcon: ProfessionFillIcon },
	[FamilyDetails]: { Icon: FamilyIcon, ActiveIcon: FamilyFillIcon },
	[MembershipStatus]: { Icon: MemberShipIcon, ActiveIcon: MemberShipFillIcon }
};

export {
	EducationLabelMapper,
	FamilyLabelMapper,
	StepperDetails,
	PersonalInfo,
	AddressInfo,
	EducationInfo,
	ProfessionInfo,
	FamilyDetails,
	MembershipStatus
};
