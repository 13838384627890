import { CSSProperties } from 'react';

const REACT_SELECT_STYLE: any = {
	option: (base: CSSProperties) => ({
		...base,
		fontSize: '14px',
		padding: 5,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	}),
	menu: (base: CSSProperties) => ({
		...base,
		zIndex: 3,
		marginTop: 0,
		position: 'absolute',
		top: '49px',
		left: '0px',
		boxShadow: 'none'
	}),
	menuList: (base: CSSProperties) => ({
		...base,
		padding: 0,
		backgroundColor: '#ffffff',
		width: '100%',
		border: '1px solid #e7e7e7',
		borderRadius: '5px',
		maxWidth: '205px',
		maxHeight: '250px',
		boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.25) 0px 2px 10px 0px'
	}),
	clearIndicator: (base: CSSProperties) => ({
		...base,
		cursor: 'pointer'
	}),
	dropdownIndicator: (base: CSSProperties, state: any) => ({
		...base,
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		paddingLeft: '0',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none'
	}),
	indicatorSeparator: () => ({
		width: '0'
	}),
	singleValue: (base: CSSProperties, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		return { ...base, opacity: opacity, transition: transition };
	},
	control: (base: CSSProperties, state: any) => ({
		...base,
		display: 'flex',
		width: '100%',
		maxWidth: 'fit-content',
		border: '1px solid #D1DEE5',
		borderColor: state.selectProps.menuIsOpen ? '#0C356A' : '#D1DEE5',
		borderRadius: '5px',
		padding: '6px 4px',
		cursor: 'pointer',
		fontSize: '14px',
		outline: 'none'
	}),
	container: () => ({
		width: '100%',
		maxWidth: 'fit-content',
		marginRight: '10px',
		position: 'relative',
		fontSize: '14px',
		zIndex: 2
	}),
	placeholder: (base: CSSProperties) => ({
		...base,
		color: '#000',
		opacity: 0.5,
		fontSize: '14px'
	})
};

const LOGIN_INIT_VALUES = {
	roll_number: '',
	contact_number: '',
	country_code: '+91'
};

export { LOGIN_INIT_VALUES, REACT_SELECT_STYLE };
