import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SignUpHeader from 'shared/components/header/components/signUpHeader';
import { logEvent } from 'shared/util/utility';

interface ILayout {
	children: ReactNode;
}

const SignUpLayout: FC<ILayout> = ({ children }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		logEvent('SIGN_UP_PAGE', {});
	}, []);

	return (
		<div className='form-profile-wrapper'>
			{pathname !== '/successful-completion' && (
				<div className='profile-header'>
					<SignUpHeader headerClassName='profile-header-wrapper' />
				</div>
			)}
			<div
				className={`main-content-wrapper sign-up-content-wrapper ${
					pathname === '/successful-completion' ? 'successful-content-wrapper' : ''
				} ${pathname === '/profile/details' ? 'profile-details-content-wrapper' : ''}`}
			>
				{children}
			</div>
		</div>
	);
};

export default SignUpLayout;
