import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { ErrorMessage, FieldArray, Form, Formik, FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import CustomButton, { IClassObj } from 'shared/components/customButton/customButton';
import { familyInfoValidationSchema } from 'shared/constants/validation-schema';
import Input from 'shared/form/input';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import ReactSignUpSelect from 'shared/form/reactSignUpSelect';
import { CustomCalendarIcon, DeleteIcon, PlusIcon } from 'shared/components/icons/icons';
import { TODAY } from 'shared/constants/constants';
import { notify } from 'shared/components/notification/notification';
import { formatDate } from 'shared/util/utility';

import OptionCard from './OptionCard';
import StepButtonComponent from './StepButtonComponent';
import { BloodGroup, FamilyInfoDetails, RelationList } from '../constants/profile';
import { IFamilyInfoData } from '../interface/profile';
import 'react-datepicker/dist/react-datepicker.css';

interface IFamilyInfo {
	onPrevClick: () => void;
	handleNextClick: () => void;
	familyInfoData: IFamilyInfoData[];
	isEdit: boolean;
	resetStepperBtnTrigger: () => void;
	isStepperBtnTrigger: boolean;
}

const FamilyInfo: FC<IFamilyInfo> = ({
	onPrevClick,
	handleNextClick,
	familyInfoData,
	isEdit,
	isStepperBtnTrigger,
	resetStepperBtnTrigger
}) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState({
		nextLoading: false,
		submitLoading: false
	});

	const handleSubmitAddressInfo = async (values: FormikValues, isNext: boolean) => {
		try {
			setLoading({ nextLoading: isNext, submitLoading: !isNext });

			const updatedParams = values.family.map((item: any) => ({
				...item,
				birthdate: formatDate(item.birthdate, 'l')
			}));

			const params = { family: updatedParams };
			await HttpService.post(API_CONFIG.path.addFamilyInfo, params);
			setLoading({ nextLoading: false, submitLoading: false });
			if (isEdit && isNext) {
				navigate('/profile/details');
			} else {
				handleNextClick();
			}
		} catch (err) {
			setLoading({ nextLoading: false, submitLoading: false });
			resetStepperBtnTrigger();
			console.error(err);
		}
	};

	const formRef: any = useRef();

	const isEmptyFamilyDetails = () => {
		const otherFamilyMembers = formRef.current?.values?.family || [];

		// Check if any family member has empty fields other than 'blood_group and occupation'
		const isEmptyStringPresent = otherFamilyMembers.some((familyDetails: IClassObj) =>
			Object.keys(familyDetails).some(
				(key) => key !== 'blood_group' && key !== 'occupation' && familyDetails[key] === ''
			)
		);

		return isEmptyStringPresent;
	};

	useEffect(() => {
		if (formRef.current && isStepperBtnTrigger) {
			const errorObj = formRef.current.errors.family;
			const formValues = formRef.current.values;
			if (isUndefined(errorObj)) {
				handleSubmitAddressInfo(formValues, false);
			} else {
				resetStepperBtnTrigger();
				notify(Object.values(errorObj.filter(Boolean)[0])[0] as string, 'error');
			}
		}
	}, [isStepperBtnTrigger]);

	return (
		<div className='details-wrapper'>
			<Formik
				innerRef={formRef}
				initialValues={{ family: familyInfoData }}
				onSubmit={() => {}}
				validationSchema={familyInfoValidationSchema}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ values, setFieldValue, touched, errors }: FormikValues) => (
					<Form className='form'>
						<FieldArray name='family'>
							{({ push, remove }) => (
								<div className='profile-container'>
									{values.family.length > 0 &&
										values.family.map((instituteInfo: string, index: number) => (
											<div className={`${index > 0 ? 'family-details-wrapper' : ''}`} key={index}>
												<div className='institute-container'>
													<div className='flex justify-content--between align-content--center pb--10'>
														<p className='font-size--sm font--semi-bold opacity--50'>
															Family Member {index + 1}{' '}
															<span className='font-size--sm font--regular opacity--30 text--secondary'>
																(Optional)
															</span>
														</p>

														<div
															className='delete-icon-wrapper cursor--pointer'
															onClick={() => remove(index)}
														>
															<DeleteIcon />
														</div>
													</div>
													<div className='flex flex--wrap'>
														{Object.entries(instituteInfo).map(
															([info, _], fieldIndex: number) => {
																const {
																	label,
																	name,
																	placeHolder,
																	isRequired,
																	type,
																	note
																} = FamilyInfoDetails[info];
																const fieldError =
																	errors.family &&
																	errors.family[index] &&
																	errors.family[index][name];
																const fieldTouched =
																	touched.family &&
																	touched.family[index] &&
																	touched.family[index][name];

																const excludedNames = [
																	'relation',
																	'blood_group',
																	'birthdate'
																];
																return (
																	<div
																		key={fieldIndex}
																		className='details-input-wrapper mb--15'
																	>
																		<label className='font-size--sm font--semi-bold'>
																			<p>
																				{label}
																				{isRequired ? (
																					<span className='text--red'>
																						{' '}
																						*
																					</span>
																				) : (
																					<span className='font-size--sm font--regular opacity--30 text--secondary'>
																						(optional)
																					</span>
																				)}
																			</p>
																		</label>
																		<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
																			{note}
																		</span>
																		<div>
																			{!excludedNames.includes(name) && (
																				<Input
																					name={`family[${index}].${name}`}
																					type={type}
																					data-testid={name}
																					placeholder={placeHolder}
																					value={values.family[index][name]}
																					classes={`${
																						name === 'birthdate'
																							? 'calendar-input'
																							: ''
																					} ${
																						fieldError && fieldTouched
																							? 'form-input--error'
																							: ''
																					}`}
																					onChange={(e) =>
																						setFieldValue(
																							`family[${index}].${name}`,
																							e.target.value
																						)
																					}
																				/>
																			)}
																			{name === 'relation' && (
																				<ReactSignUpSelect
																					name={`family[${index}].${name}`}
																					placeholder={placeHolder}
																					options={RelationList}
																					onChange={(selectedValue: any) => {
																						setFieldValue(
																							`family[${index}].${name}`,
																							selectedValue.value
																						);
																					}}
																					defaultValue={
																						values.family[index][name]
																							? {
																									label: values
																										.family[index][
																										name
																									],
																									value: values
																										.family[index][
																										name
																									]
																							  }
																							: null
																					}
																				/>
																			)}
																			{name === 'blood_group' && (
																				<OptionCard
																					optionList={BloodGroup}
																					selectedOptions={
																						values.family[index].blood_group
																					}
																					name={`family[${index}].${name}`}
																					setFieldValue={setFieldValue}
																					isMultiple={false}
																				/>
																			)}
																			{name === 'birthdate' && (
																				<DatePicker
																					icon={CustomCalendarIcon}
																					showIcon
																					calendarIconClassname='calendar-icon cursor--pointer'
																					className='form-input calendar-input'
																					placeholderText='dd-mm-yyyy'
																					selected={
																						values.family[index].birthdate
																					}
																					dateFormat='dd-MM-yyyy'
																					showYearDropdown
																					showMonthDropdown
																					scrollableYearDropdown
																					yearDropdownItemNumber={100}
																					maxDate={TODAY}
																					onChange={(date: Date) =>
																						setFieldValue(
																							`family[${index}].${name}`,
																							date
																						)
																					}
																					disabledKeyboardNavigation
																					shouldCloseOnSelect
																					onFocus={(e) => e.target.blur()}
																				/>
																			)}
																		</div>
																		<ErrorMessage
																			name={`family[${index}].${name}`}
																			component='p'
																			className='error'
																		/>
																	</div>
																);
															}
														)}
													</div>
												</div>
											</div>
										))}
									<div className='justify-align--center mb--30 width--full'>
										<CustomButton
											disabled={isEmptyFamilyDetails()}
											buttonType='button'
											buttonTitle='Add a Family Member'
											btnClassName='secondary-button add-education-button'
											ButtonIcon={PlusIcon}
											onButtonClick={() =>
												push({
													name: '',
													relation: '',
													birthdate: '',
													blood_group: '',
													occupation: ''
												})
											}
										/>
									</div>
								</div>
							)}
						</FieldArray>

						<StepButtonComponent
							disabled={values.life_time_member && !isEmpty(errors)}
							nextLoading={loading.nextLoading}
							submitLoading={loading.submitLoading}
							isPrevButton={true}
							buttonTitle='Just 1 step to complete'
							onPrevClick={onPrevClick}
							nextButtonClick={(isNext: boolean) => handleSubmitAddressInfo(values, isNext)}
							isSave={isEdit}
						/>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default FamilyInfo;
