import { FC, useMemo } from 'react';

import { BackIcon } from 'shared/components/icons/icons';
import ProfileDetails from '../components/profileDetails';
import { useQuery } from 'shared/hooks/useQuery';
import { useNavigate } from 'react-router-dom';

const Profile: FC = () => {
	const params = useQuery();
	const navigate = useNavigate();
	const profileId = useMemo(() => params.get('userId'), [params]);
	const closeProfile = () => navigate(-1);

	if (!profileId || profileId === '') return <></>;

	return (
		<div className='profile-wrapper'>
			<div className='alumni-profile-wrapper'>
				<div className='back-icon-wrapper' onClick={closeProfile}>
					<BackIcon />
				</div>
				<p className='font--bold font-size--xxl ml--20'>Profile</p>
			</div>
			<ProfileDetails userId={profileId} className='personal-profile' />
		</div>
	);
};

export default Profile;
