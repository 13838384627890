import isEmpty from 'lodash/isEmpty';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import CustomButton from 'shared/components/customButton/customButton';
import { API_CONFIG } from 'shared/constants/api';
import { PROFILE_DETAILS } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';

import { Loader } from 'shared/components/spinner/spinner';
import { UserDetailsContext } from 'store/context/userDetailStore';
import {
	IAddressDetails,
	IEducationDetails,
	IFamilyDetails,
	IPersonalDetails,
	IProfessionDetails
} from '../interface/profile';
import AddressDetails from './addressDetails';
import EducationDetails from './educationDetails';
import FamilyDetails from './familyDetails';
import PersonalDetails from './personalDetails';
import ProfessionalDetails from './professionalDetails';

const EditProfile: FC = () => {
	const navigate = useNavigate();
	const { profileData, profileLoading, setProfileData } = useContext(UserDetailsContext);

	const [nextStep, setNextStep] = useState(0);
	const [currentStep, setCurrentStep] = useState(0);
	const [alumniDetails, setAlumniDetails] = useState({});
	const [personalDetails, setPersonalDetails] = useState({} as IPersonalDetails);
	const [addressDetails, setAddressDetails] = useState({} as IAddressDetails);
	const [professionDetails, setProfessionDetails] = useState({} as IProfessionDetails);
	const [educationDetails, setEducationDetails] = useState([] as IEducationDetails[]);
	const [familyDetails, setFamilyDetails] = useState([] as IFamilyDetails[]);
	const [isSaveDetails, setIsSaveDetails] = useState(false);

	const scrollToLeft = (divIndex: number) => {
		const scroller = document.querySelector('.scroller');
		const scrollTo = document.getElementById(`${divIndex}`);
		if (scrollTo) {
			const leftPosition = scrollTo.offsetLeft;

			// Smooth scroll animation
			scroller?.scrollTo({
				left: leftPosition,
				behavior: 'smooth'
			});
		}
	};

	useEffect(() => {
		if (!profileLoading) {
			const {
				first_name,
				last_name,
				profile_photo,
				contact_number,
				email,
				blood_group,
				house,
				admission_year,
				passing_year,
				birthdate,
				address,
				profession,
				education,
				family,
				alternate_contact,
				country_code,
				year_of_joining,
				year_of_leaving,
				category
			} = profileData as any;

			setPersonalDetails({
				first_name,
				last_name,
				profile_photo,
				contact_number,
				email,
				blood_group,
				admission_year,
				passing_year,
				birthdate: new Date(birthdate) as Date,
				house: house ?? null,
				alternate_contact,
				country_code,
				year_of_joining,
				year_of_leaving,
				category
			});
			setAddressDetails(address);
			setProfessionDetails(profession);
			setEducationDetails(education);
			setFamilyDetails(family);
			setAlumniDetails(alumniDetails);
		}
	}, [profileLoading, profileData]);

	const handleSaveClick = async (updatedDetails: any) => {
		try {
			setIsSaveDetails(true);
			let params = { ...updatedDetails };
			if (updatedDetails.birthdate) {
				params = { ...params, birthdate: moment(updatedDetails.birthdate).format('') };
			}
			const data = await HttpService.put(API_CONFIG.path.profile, params);
			setIsSaveDetails(false);
			setProfileData(data);
			navigate(-1);
		} catch (error) {
			console.error(error);
			setIsSaveDetails(false);
		}
	};

	useEffect(() => {
		scrollToLeft(nextStep);
	}, [nextStep]);

	const getStepForm = () => {
		const commonProps = {
			isSaveDetails,
			isCurrentStepChange: nextStep !== currentStep,
			handleSaveClick: handleSaveClick,
			setUpdatedAlumniDetails: (updatedDetails: any) => {
				const finalDetails = { ...profileData, ...updatedDetails };
				console.log(
					'finalDetails.birthdate:',
					finalDetails.birthdate,
					moment(finalDetails.birthdate).format('')
				);
				setProfileData({
					...finalDetails,
					birthdate: moment(finalDetails.birthdate).format('')
				});
				setCurrentStep(nextStep);
			},
			handleResetSaveClick: () => {
				setIsSaveDetails(false);
				setNextStep(currentStep);
			}
		};
		if (
			isEmpty(personalDetails) &&
			isEmpty(addressDetails) &&
			isEmpty(professionDetails) &&
			isEmpty(familyDetails) &&
			isEmpty(educationDetails)
		) {
			return <Loader />;
		}
		switch (currentStep) {
			case 0:
				return <PersonalDetails {...commonProps} personalDetails={personalDetails} />;
			case 1:
				return <AddressDetails {...commonProps} addressDetails={addressDetails} />;
			case 2:
				return <ProfessionalDetails {...commonProps} professionDetails={professionDetails} />;
			case 3:
				return <EducationDetails {...commonProps} educationDetails={educationDetails} />;
			case 4:
				return <FamilyDetails {...commonProps} familyDetails={familyDetails} />;
			default:
				return <></>;
		}
	};

	return (
		<div className='position--relative edit-profile-wrapper'>
			<CustomButton
				btnClassName='primary-button save-btn'
				buttonTitle='Save'
				onButtonClick={() => setIsSaveDetails(true)}
			/>
			<div className='stepper-main-wrapper'>
				<div className='scroller'>
					<div className='stepper-inner-wrapper ml--20'>
						{PROFILE_DETAILS.map((profileTitle, index) => (
							<div key={index} className='horizontal-stepper-wrapper' id={`${index}`}>
								<div
									className={`horizontal-stepper-box ${
										nextStep === index ? 'horizontal-stepper-fill-box' : ''
									}`}
									onClick={() => setNextStep(index)}
								>
									<p className={`stepper-title ${nextStep === index ? 'stepper-title--active' : ''}`}>
										{profileTitle}
									</p>
								</div>
								<div className={`stepper-hr ${nextStep === index ? 'stepper-hr--active' : ''}`} />
							</div>
						))}
					</div>
				</div>
			</div>
			{profileLoading && <Loader />}
			{!profileLoading && getStepForm()}
		</div>
	);
};

export default EditProfile;
