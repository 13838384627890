import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IBatchMemory } from 'features/dashboard/interface/dashboard';
import { BackIcon, BellIcon } from 'shared/components/icons/icons';
import { useQuery } from 'shared/hooks/useQuery';

import MemorySwiper from './memorySwiper';

interface IMemorySwiper {
	memoryDetails: IBatchMemory;
}

const MemoryDetails: FC<IMemorySwiper> = ({ memoryDetails }) => {
	const params = useQuery();
	const navigate = useNavigate();
	const fromHome = useMemo(() => params.get('fromHome'), [params]);

	const [isShowPhotoSwiper, setIsShowPhotoSwiper] = useState(false);
	const [initialSlide, setInitialSlide] = useState(0);

	return (
		<>
			<div className='header-wrapper no--shadow'>
				<div className='navbar flex align-items--center justify-content--between height--full width--full'>
					<div className='header-icon-wrapper' onClick={() => navigate(fromHome ? '/home' : '/all-memories')}>
						<BackIcon />
					</div>
					<p className='font--bold font-size--xxl'>{memoryDetails.title}</p>
					<div className='flex'>
						<div
							className='header-icon-wrapper'
							onClick={() => navigate('/all-memories?notification=true')}
						>
							<BellIcon />
						</div>
					</div>
				</div>
			</div>
			<div className='pl--15 pr--15'>
				{memoryDetails.photo.map((photoUrl, index) => (
					<img
						key={index}
						src={photoUrl}
						alt='memory'
						className='memory-img detail-memory-img'
						onClick={() => {
							setIsShowPhotoSwiper(true);
							setInitialSlide(index);
						}}
					/>
				))}
			</div>
			{isShowPhotoSwiper && (
				<MemorySwiper
					initialSlide={initialSlide}
					detailMemory={memoryDetails}
					handleCloseMemorySwiper={() => setIsShowPhotoSwiper(false)}
				/>
			)}
		</>
	);
};

export default MemoryDetails;
