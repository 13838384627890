import { FC, useLayoutEffect, useState } from 'react';
import CustomButton from 'shared/components/customButton/customButton';
import { NextArrow, PrevArrow } from 'shared/components/icons/icons';

interface IStepButtonComponent {
	disabled: boolean;
	isPrevButton: boolean;
	buttonTitle: string;
	nextLoading: boolean;
	submitLoading: boolean;
	onPrevClick: () => void;
	nextButtonClick: (isNext: boolean) => void;
	isSave?: boolean;
}

const StepButtonComponent: FC<IStepButtonComponent> = ({
	disabled,
	isPrevButton,
	buttonTitle,
	nextLoading,
	submitLoading,
	onPrevClick,
	nextButtonClick,
	isSave
}) => {
	const [sticky, SetSticky] = useState(0);

	useLayoutEffect(() => {
		function updateSticky() {
			SetSticky(window.scrollY);
		}
		window.addEventListener('scroll', updateSticky);
		updateSticky();
		return () => {
			window.removeEventListener('scroll', updateSticky);
		};
	}, []);

	return (
		<div className='flex justify-content--between mt--15 step-button-wrapper width--full'>
			{isPrevButton && (
				<CustomButton
					buttonTitle='Prev'
					btnClassName='secondary-button prev-button flex--row-reverse mr--20'
					ButtonIcon={PrevArrow}
					onButtonClick={onPrevClick}
				/>
			)}
			<CustomButton
				disabled={disabled}
				buttonTitle={buttonTitle}
				btnClassName='primary-button step-submit-button'
				ButtonIcon={NextArrow}
				isSubmitLoading={submitLoading}
				onButtonClick={() => nextButtonClick(false)}
			/>
			<CustomButton
				disabled={disabled}
				buttonTitle={isSave ? 'Save' : 'Next'}
				btnClassName={`primary-button next-button ${sticky > 200 ? 'sticky-btn' : ''}`}
				ButtonIcon={isSave ? '' : NextArrow}
				isSubmitLoading={nextLoading}
				onButtonClick={() => nextButtonClick(true)}
			/>
		</div>
	);
};

export default StepButtonComponent;
