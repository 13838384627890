import { FC, useRef, useEffect } from 'react';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { notify } from 'shared/components/notification/notification';
import { professionInfoValidationSchema } from 'shared/constants/validation-schema';
import Input from 'shared/form/input';

import { PROFESSIONAL_INFO_DETAILS, WORK_TYPE } from '../constants/profile';
import { IProfessionDetails } from '../interface/profile';

import 'react-datepicker/dist/react-datepicker.css';
import ReactSelect from 'shared/form/reactSelect';

interface IPersonalDetailsProps {
	isCurrentStepChange: boolean;
	isSaveDetails: boolean;
	professionDetails: IProfessionDetails;
	handleSaveClick: (personalDetails: IProfessionValues) => void;
	setUpdatedAlumniDetails: (personalDetails: IProfessionValues) => void;
	handleResetSaveClick: () => void;
}

interface IProfessionValues {
	profession: IProfessionDetails;
}

const ProfessionalDetails: FC<IPersonalDetailsProps> = ({
	isCurrentStepChange,
	professionDetails,
	isSaveDetails,
	handleSaveClick,
	setUpdatedAlumniDetails,
	handleResetSaveClick
}) => {
	const formRef: any = useRef();

	const handleSaveProfessionDetails = (isSaveDetail: boolean) => {
		if (formRef.current) {
			const errorObj = formRef.current.errors;
			if (isEmpty(errorObj)) {
				isSaveDetail
					? handleSaveClick({ profession: formRef.current.values })
					: setUpdatedAlumniDetails({ profession: formRef?.current?.values });
			} else {
				notify(Object.values(errorObj)[0] as string, 'error');
				handleResetSaveClick();
			}
		}
	};

	useEffect(() => {
		isSaveDetails && handleSaveProfessionDetails(true);
	}, [isSaveDetails]);

	useEffect(() => {
		isCurrentStepChange && handleSaveProfessionDetails(false);
	}, [isCurrentStepChange]);

	return (
		<div className='edit-profile-container'>
			<Formik
				innerRef={formRef}
				initialValues={{
					...professionDetails
				}}
				onSubmit={() => {}}
				validationSchema={professionInfoValidationSchema}
				validateOnChange
				validateOnBlur
				validateOnMount
			>
				{({ errors, setFieldValue, values, touched }: FormikValues) => (
					<Form className='form'>
						<div className='profile-container'>
							{PROFESSIONAL_INFO_DETAILS.map(
								({ label, name, placeHolder, isRequired, type, note }, index: number) => (
									<div key={index} className='details-input-wrapper mb--15'>
										<label className='font-size--sm opacity--50'>
											{label}
											{isRequired ? (
												<span className='text--red'> *</span>
											) : (
												<span className='font-size--sm font--regular opacity--30 text--secondary'>
													(optional)
												</span>
											)}
										</label>
										<span className='font-size--sm font--regular opacity--30 text--secondary mt--3'>
											{note}
										</span>
										{name === 'work_type' ? (
											<div className='mt--12'>
												<ReactSelect
													isSearchable={false}
													placeholder={placeHolder}
													options={WORK_TYPE}
													defaultValue={
														values[name]
															? {
																	label: values[name],
																	value: values[name]
															  }
															: null
													}
													onChange={(value: any) => setFieldValue(name, value.value)}
												/>
											</div>
										) : (
											<Input
												name={name}
												type={type}
												data-testid={name}
												placeholder={placeHolder}
												classes={`mt--12 ${
													touched[name] && errors[name] ? 'form-input--error' : ''
												}`}
												value={values[name]}
												onChange={({ target: { value } }) =>
													setFieldValue(
														name,
														value.indexOf(' ') === 0 || value.trim().length <= 0
															? ''
															: value
													)
												}
												autoComplete='off'
											/>
										)}
										<ErrorMessage name={name} component='p' className='error' />
									</div>
								)
							)}
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ProfessionalDetails;
